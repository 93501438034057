.Button__button___vS7Mv {
	min-width: 153px;
	display: inline-block;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	background-image: none;
	color: var(--button-color);
	background-color: var(--button-background-color);
	border-color: #ccc;
	text-transform: uppercase;
	padding: 14px 0;
	letter-spacing: 1.1px;
	border: none;
}

.Button__button___vS7Mv:active {
	opacity: 1;
	background-color: var(--button-click);
}

.Button__button___vS7Mv:hover,
.Button__signInButton___3bUH-:hover {
	opacity: 0.8;
}

.Button__button___vS7Mv:disabled {
	opacity: 1;
	cursor: auto;
	background-color: var(--ion-color-primary-tint);
}

.Button__signInButton___3bUH- {
	position: relative;
	width: 100%;
	border-radius: 4px;
	margin-bottom: 10px;
	cursor: pointer;
	padding: 0;
	color: var(--deepSquidInk);
	font-size: 14px;
	box-sizing: content-box;
}

#Button__googleSignInButton___1YiCu {
	background-color: #4285f4;
	font-family: Roboto;
	border: 1px solid #4285f4;
	color: var(--white);
}

#Button__googleSignInButton___1YiCu > .Button__signInButtonIcon___ihN75 {
	background-color: var(--white);
	border-radius: 4px 0 0 4px;
	height: 28px;
	width: 28px;
	padding: 12px;
}

#Button__auth0SignInButton___znnCj {
	background-color: #eb5424;
	font-family: Roboto;
	border: 1px solid #e14615;
	color: #fff;
}

#Button__auth0SignInButton___znnCj > .Button__signInButtonIcon___ihN75 {
	border-radius: 4px 0 0 4px;
	height: 28px;
	width: 28px;
	padding: 12px;
	fill: #fff;
}

#Button__facebookSignInButton___34Txh {
	background-color: #4267b2;
	border-color: #4267b2;
	font-family: 'Helvetica Neue';
	color: var(--white);
}

#Button__facebookSignInButton___34Txh > .Button__signInButtonIcon___ihN75 {
	height: 33px;
	width: 18px;
	padding: 10px 14px;
}

#Button__amazonSignInButton___2EMtl {
	background-color: var(--amazonOrange);
	border: none;
	color: var(--white);
	font-family: 'Amazon Ember';
}

#Button__amazonSignInButton___2EMtl > .Button__signInButtonIcon___ihN75 {
	padding: 10px;
	height: 32px;
	width: 32px;
}

#Button__oAuthSignInButton___3UGOl {
	background-color: var(--white);
	color: var(--deepSquidInk);
}

.Button__signInButtonIcon___ihN75 {
	position: absolute;
	left: 0;
	box-sizing: content-box;
}

.Button__signInButtonContent___xqTXJ {
	text-align: center;
	display: block;
	padding: 18px 0;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	line-height: initial;
}
