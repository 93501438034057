#root {
    height: 100%;
}

.login-container {
    position: relative;
    width: 100%;
    display: flex;
    .logo-container{
        background: linear-gradient(180deg, #0C3C64 0%, rgba(23, 90, 145, 0.86) 100%);
        box-shadow: 4px 10px 40px rgba(0, 0, 0, 0.25);
        width: 50%;
        height: 100vh;
        position: relative;
        .logo{
            position: absolute;
            top: 30%;
            width: 90%;
            // left: 7%;
            // overflow: hidden;
            .img-container-1{
                display: grid;
                place-items: center;
                .img-container{
                    width: 68%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        max-width: 100%;
                    }
                }
                .details{
                    font-family: 'Inter';
                    font-weight: 400;
                    font-size: 22px;
                    // line-height: 50px;
                    color: #FFFFFF;
                    width: 75%;
                    margin-top: 10px;
                    padding-left: 10%;
                }
            }
            // width: 75%;
            
        }
        .product-logo{
            position: absolute;
            bottom: 2%;
            left: 65%;
            .powered{
                font-family: 'Inter';
                font-weight: 400;
                font-size: 20px;
                line-height: 50px;
                color: #FFFFFF;
                margin-right: 10px;
                white-space: nowrap;
                margin-top: 10px;
            }
        }
    }
    .login-background{
        width: 50%;
        height: 100vh;
        background-image: url("../../Images/wms360bg.svg");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .pin-design{
        background: #F9A369;
        border: 10px solid #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        position: absolute;
        left: 44%;
        top: 16%;
        width: 60px;
        height: 55px;
        border-radius: 50%;
        z-index: 10;
    }
    .login-theme {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 13%;
        height: 650px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .login-input-container {
        // position: absolute;
        // right: 0;
        // max-height: 100%;
    }

    .background-top-left {
        .green-gray-box {
            height: 70px;
            display: flex;

            .green-box {
                width: 45px;
                background: #20CD7A;

            }

            .gray-box {
                width: 100%;
                background: #E6E6E6;
            }
        }

        .light-blue-gray-box {
            width: 10%;
            margin-top: 20px;
            height: 70px;
            display: flex;

            .light-blue-box {
                width: 55px;
                background: #1FBAF2;

            }

            .gray-box {
                width: 100%;
                background: #E6E6E6;
            }
        }

        .dark-blue-gray-box {
            margin-top: 20px;
            width: 20%;
            height: 70px;
            display: flex;

            .dark-blue-box {
                width: 55px;
                background: #032454;

            }

            .gray-box {
                width: 100%;
                background: #E6E6E6;
            }
        }
    }

    .background-bottom-right {

        .green-gray-box {
            height: 70px;
            display: flex;

            .green-box {
                width: 45px;
                background: #20CD7A;

            }

            .gray-box {
                width: 100%;
                background: #E6E6E6;
            }
        }

        .light-blue-gray-box {

            width: 10%;
            margin-top: 20px;
            height: 70px;
            display: flex;


            .light-blue-box {
                width: 55px;
                background: #1FBAF2;

            }

            .gray-box {
                width: 100%;
                background: #E6E6E6;
            }
        }

        .dark-blue-gray-box {
            margin-top: 20px;
            width: 20%;
            height: 70px;
            display: flex;

            .dark-blue-box {
                width: 55px;
                background: #032454;

            }

            .gray-box {
                width: 100%;
                background: #E6E6E6;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 100%;
        position: absolute;
        left: 45%;
        top: 16%;
        background: rgba(255, 255, 255, 0.49);
        backdrop-filter: blur(77px);
        border-radius: 20px;
        padding: 30px 30px 50px 30px;
        .lable {
            font-size: 16px !important;
            color: var(--app-black) !important;
            font-family: 'RedHat-Bold' !important;
            margin-bottom: 5px;
        }

        .ant-form-vertical .ant-form-item-label>label {
            font-size: 16px !important;
            text-align: left !important;
        }

        .input-field {
            width: 100% !important;
            border: 2px solid #d9d9d9 !important;
            height: 45px !important;
            border-radius: 8px !important;
            background: white;
            .ant-input {
                background: none !important;
            }
        }

        .error-field {
            width: 100% !important;
            border: 2px solid #FF9999 !important;
            height: 48px !important;
            border-radius: 8px !important;
            background: white;
            .ant-input {
                background: none !important;
            }
        }

        // #email.ant-input {
        //     background: none !important;
        // }
    }

    .loader {
        position: absolute;
        margin-top: 25%;
        left: 60%;
        font-size: 26px;
        color: var(--app-004986);
    }

    .loading {
        opacity: 0.5;
    }

    .login-title {
        font-size: 24px;
        font-family: 'RedHat-semibold';
        color: #163E60;
        margin: 20px 5px 15px 5px;
    }

    .select-field{
        height: 45px;
        border-radius: 8px;
    }
    .select-height {
        height: 45px !important;
        display: flex;
        align-items: center;
    }



    .login-box-container {
        width: 375px;
        height: 40%;
        .login-header-part {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .login-error {
            color: red;
            font-family: Redhat-medium;
            font-size: 18px;
            text-align: center;
            margin: 10px 0;
        }

        // .ant-form-vertical .ant-form-item-label > label{
        //     color:  !important;
        // }
        .login-logo-container {
            .login-title {
                font-size: 24px;
                font-family: 'RedHat-semibold';
                color: #163E60;
                margin: 0 5px 0 15px;
            }
            .select-field{
                height: 45px;
                border-radius: 8px;
            }
        }

        .forget-password-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 16px;
            font-weight: 500;
            color: var(--app-004986);
            margin: 15px 0 15px 0;
            // text-decoration: underline;
        }

        .app-buttons-container .solid-linear-green {
            padding: 23px !important;
            font-size: 20px !important;
            color: var(--app-FFFFFF) !important;
            margin-left: 0px !important;
        }

        .button-container {
            margin-top: 25px;

            .google-button, .google-button:hover {
                cursor: pointer;
                background: #FFFFFF;
                border: 2px solid var(--app-004986);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                .btn-verbage {
                    display: flex;
                    align-items: center;

                    .verbage-text {
                        font-size: 16px;
                        font-family: "RedHat-Bold";
                        color: var(--app-004986);
                    }

                    .icon {
                        margin:5px 0 0 10px;
                    }
                }
            }
        }

        .blue-box {
            background: rgba(0, 129, 157, 0.15);
            border-radius: 5px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            svg {
                height: 14px !important;
                width: 12px !important;
            }
        }

    }
}