.table-screen-container {
    background-color: var(--app-FFFFFF);
    padding: 15px;
    border-radius: 6px;

    .ant-table-header {
        margin-top: -10px !important;
    }

    .ant-table-cell-scrollbar:not([rowspan]) {
        box-shadow: none;
    }

    .ant-table-measure-row {
        visibility: collapse;
    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        background-color: var(--app-D9E4ED);
        border-color: var(--app-004986);
        font-family: 'RedHat-Regular';
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;

        svg {
            // margin-top: 3px;
            color: var(--app-004986);
        }

        .anticon {
            color: var(--app-FFFFFF) !important;
            font-size: 20px !important;
        }
    }

    .gray-btn,
    .gray-btn:hover,
    .gray-btn:focus {
        font-size: 14px;
        color: var(--app-DFDFDF);
        background: var(--app-C0C0C0);
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 30px;
    }

    .green-btn,
    .green-btn:hover,
    .green-btn:focus {
        font-size: 14px;
        color: var(--app-FFFFFF);
        background-color: var(--app-004986);
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 30px;
    }

    .dropdown {
        .anticon {
            color: var(--app-004986) !important;
            font-size: 27px !important;
        }

    }

    .orderid {
        font-family: redhat-semibold;
        font-size: 16px;
        color: var(--app-004986);
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tat {
        // background: #BBE6D1;
        color: #0A3E25;
        border-radius: 10px;
        padding: 0px 10px;
        text-align: center;
        width: fit-content;
    }

    .Assignee-content {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-family: 'RedHat-Bold' !important;
        background: var(--app-004986);
        color: #FEAE3F !important;
        font-size: 14px !important;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .gateinid-text {
        font-family: 'RedHat-SemiBold' !important;
        color: var(--app-004986) !important;
        font-size: 14px !important;

        a {
            color: var(--app-004986) !important;

        }
    }

    .gatein-id {
        font-family: 'RedHat-SemiBold' !important;
        color: var(--app-004986) !important;
        font-size: 14px !important;
        // width: 97px;
        // text-overflow: ellipsis;
        // overflow: hidden;
        cursor: pointer;

        a {
            color: var(--app-004986) !important;

        }

        .overflow-tagline {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
        }
    }

    .gatein-text {
        font-family: 'RedHat-SemiBold' !important;
        color: var(--app-004986) !important;
        font-size: 14px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;

        a {
            color: var(--app-004986) !important;

        }

        .overflow-tagline {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
        }
    }

    .client-text {
        font-family: 'RedHat-SemiBold' !important;
        color: #3F3F3F !important;
        font-size: 14px !important;
        width: 85px;
        text-overflow: ellipsis;
        overflow: hidden;

        .overflow-tagline {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
        }
    }

    .text {
        font-family: 'RedHat-SemiBold' !important;
        color: #3F3F3F !important;
        font-size: 14px !important;
        text-overflow: ellipsis;
        overflow: hidden;

        .overflow-tagline {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
        }
    }

    .ant-table {
        background: none !important;
    }

    .ant-table table {
        border-spacing: 0px 10px !important;
    }

    .ant-table-thead>tr>th {
        background: var(--app-A0BCD2) !important;
        padding: 10px 10px !important;
        font-size: 14px !important;
        color: var(--app-151515) !important;
        font-family: 'RedHat-Bold' !important;

    }

    .ant-table-tbody>tr>td {
        //background: rgba(0, 129, 157, 0.1) !important;
        padding: 5px 10px;
        background: #FCFCFC;
        border: none !important;
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;
        box-shadow: 1px 1px 1px #DADADA;
    }

    .ant-table-tbody>tr>td:first-child {
        border-left: 1px solid #EBEBEB !important;
    }

    .ant-table-tbody>tr>td:last-child {
        border-right: 1px solid #EBEBEB !important;
    }

    .ant-table-container table>thead>tr:first-child th:first-child {
        border-top-left-radius: 6px !important;
        border-left: 1px solid #E9E9E9 !important;
        border-bottom-left-radius: 6px !important;
    }

    .ant-table-container table>thead>tr:first-child th:last-child {
        border-top-right-radius: 6px !important;
        border-right: 1px solid #E9E9E9 !important;
        border-bottom-right-radius: 6px !important;
    }


    .ant-table-tbody>tr>td:first-child {
        border-top-left-radius: 6px !important;

        border-bottom-left-radius: 6px !important;
    }

    .ant-table-tbody>tr>td:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;

    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-SemiBold';
    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
        border-right: 0px !important;
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-Medium';
    }

    .ant-table.ant-table-bordered>.ant-table-container {
        border-left: 0px !important;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td,
    .ant-table-tbody>tr>td.ant-table-cell-row-hover {
        background: #FCFCFC !important;
    }


    .ant-table-pagination.ant-pagination {
        display: none !important;
    }

    .ant-select {
        width: 97%;
    }

    // .ant-select:focus,.ant-select:hover{
    //     border: 1px solid var(--app-C7C7C7) !important;
    // }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 2px solid #d9d9d9 !important;
        border-radius: 5px !important;
        align-items: center;
        border-radius: 2px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 35px;

    }

    .ant-select-selector:hover {
        border: 2px solid #d9d9d9 !important;
    }

    .ant-select-arrow {
        color: var(--app-black) !important;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
    }

    .ant-input {
        border: 2px solid var(--app-004986);
        border-radius: 5px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 30px;
    }

    .ant-table-cell-row-hover {
        .ant-input:hover {
            border: 2px solid var(--app-004986);
            border-radius: 5px;
            font-size: 14px;
            color: #232323;
            font-family: 'RedHat-Regular';
            height: 30px;
        }
    }

    .ant-input:hover,
    .ant-input:focus {
        border: 2px solid var(--app-004986);
        border-radius: 5px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 30px;
    }

    .action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-btn>.anticon {
            line-height: 0px !important;
        }

        .ant-btn {
            border-radius: 50%;
            width: 30px !important;
            height: 30px !important;
            padding: 0px !important;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .Add-btn {
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            font-size: 15px;

        }

        .delete-btn {
            color: var(--app-FFFFFF);
            background: var(--app-DD3E13);
            font-size: 15px;
        }
    }

    .btn-container {
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        margin-top: 20px;
    }

    .ant-progress {
        display: flex;
        align-items: center;
    }

    .ant-progress-line {
        width: 180px !important;
    }

    .ant-progress-inner {
        border: 2px solid var(--app-004986);
    }

    .ant-progress-outer {
        width: 150 !important;
    }

    .ant-progress-bg {
        background-color: var(--app-004986) !important;
    }

    .ant-progress-text {
        font-size: 14px;
        font-family: 'RedHat-SemiBold';
        color: var(--app-004986);
        width: 4em !important;
    }


}

.table-screen-container-bg-none {
    // background-color: var(--app-FFFFFF);
    border-radius: 6px;

    .ant-table-header {
        margin-top: -10px !important;
    }

    .ant-table-cell-scrollbar:not([rowspan]) {
        box-shadow: none;
    }

    .ant-table-measure-row {
        visibility: collapse;
    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        background-color: var(--app-004986);
        font-family: 'RedHat-Regular';
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;

        svg {
            // margin-top: 3px;
        }

        .anticon {
            color: var(--app-FFFFFF) !important;
            font-size: 20px !important;
        }
    }

    .gray-btn,
    .gray-btn:hover,
    .gray-btn:focus {
        font-size: 14px;
        color: var(--app-DFDFDF);
        background: var(--app-C0C0C0);
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 30px;
    }

    .green-btn,
    .green-btn:hover,
    .green-btn:focus {
        font-size: 14px;
        color: var(--app-FFFFFF);
        background-color: var(--app-004986);
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 30px;
    }

    .dropdown {
        .anticon {
            color: var(--app-004986) !important;
            font-size: 27px !important;
        }

    }

    .orderid {
        font-family: redhat-semibold;
        font-size: 16px;
        color: var(--app-004986);
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tat {
        background: #BBE6D1;
        color: #0A3E25;
        border-radius: 10px;
        padding: 0px 10px;
        text-align: center;
        width: fit-content;
    }

    .Assignee-content {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-family: 'RedHat-Bold' !important;
        background: var(--app-004986);
        color: #FEAE3F !important;
        font-size: 14px !important;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .gateinid-text {
        font-family: 'RedHat-SemiBold' !important;
        color: var(--app-004986) !important;
        font-size: 14px !important;

        a {
            color: var(--app-004986) !important;

        }
    }

    .gatein-id {
        font-family: 'RedHat-SemiBold' !important;
        color: var(--app-004986) !important;
        font-size: 14px !important;
        // width: 97px;
        // text-overflow: ellipsis;
        // overflow: hidden;
        cursor: pointer;

        a {
            color: var(--app-004986) !important;

        }

        .overflow-tagline {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
        }
    }

    .gatein-text {
        font-family: 'RedHat-SemiBold' !important;
        color: var(--app-004986) !important;
        font-size: 14px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;

        a {
            color: var(--app-004986) !important;

        }

        .overflow-tagline {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
        }
    }

    .client-text {
        font-family: 'RedHat-SemiBold' !important;
        color: #3F3F3F !important;
        font-size: 14px !important;
        width: 85px;
        text-overflow: ellipsis;
        overflow: hidden;

        .overflow-tagline {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
        }
    }

    .text {
        font-family: 'RedHat-SemiBold' !important;
        color: #3F3F3F !important;
        font-size: 14px !important;
        text-overflow: ellipsis;
        overflow: hidden;

        .overflow-tagline {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
        }
    }

    .ant-table {
        background: none !important;
    }

    .ant-table table {
        border-spacing: 0px 10px !important;
    }

    .ant-table-thead>tr>th {
        background: #505050 !important;
        padding: 10px 10px !important;
        font-size: 14px !important;
        color: white !important;
        font-family: 'RedHat-Bold' !important;

    }

    .ant-table-tbody>tr>td {
        //background: rgba(0, 129, 157, 0.1) !important;
        padding: 5px 10px;
        background: #FCFCFC;
        border: none !important;
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;
        box-shadow: 1px 1px 1px #DADADA;
    }

    .ant-table-tbody>tr>td:first-child {
        border-left: 1px solid #EBEBEB !important;
    }

    .ant-table-tbody>tr>td:last-child {
        border-right: 1px solid #EBEBEB !important;
    }

    .ant-table-container table>thead>tr:first-child th:first-child {
        border-top-left-radius: 6px !important;
        border-left: 1px solid #E9E9E9 !important;
        border-bottom-left-radius: 6px !important;
    }

    .ant-table-container table>thead>tr:first-child th:last-child {
        border-top-right-radius: 6px !important;
        border-right: 1px solid #E9E9E9 !important;
        border-bottom-right-radius: 6px !important;
    }


    .ant-table-tbody>tr>td:first-child {
        border-top-left-radius: 6px !important;

        border-bottom-left-radius: 6px !important;
    }

    .ant-table-tbody>tr>td:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;

    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-SemiBold';
    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
        border-right: 0px !important;
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-Medium';
    }

    .ant-table.ant-table-bordered>.ant-table-container {
        border-left: 0px !important;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td,
    .ant-table-tbody>tr>td.ant-table-cell-row-hover {
        background: #FCFCFC !important;
    }


    .ant-table-pagination.ant-pagination {
        display: none !important;
    }

    .ant-select {
        width: 97%;
    }

    // .ant-select:focus,.ant-select:hover{
    //     border: 1px solid var(--app-C7C7C7) !important;
    // }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 2px solid #d9d9d9 !important;
        border-radius: 5px !important;
        align-items: center;
        border-radius: 2px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 35px;

    }

    .ant-select-selector:hover {
        border: 2px solid #d9d9d9 !important;
    }

    .ant-select-arrow {
        color: var(--app-black) !important;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
    }

    .ant-input {
        border: 2px solid var(--app-004986);
        border-radius: 5px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 30px;
    }

    .ant-table-cell-row-hover {
        .ant-input:hover {
            border: 2px solid var(--app-004986);
            border-radius: 5px;
            font-size: 14px;
            color: #232323;
            font-family: 'RedHat-Regular';
            height: 30px;
        }
    }

    .ant-input:hover,
    .ant-input:focus {
        border: 2px solid var(--app-004986);
        border-radius: 5px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 30px;
    }

    .action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-btn>.anticon {
            line-height: 0px !important;
        }

        .ant-btn {
            border-radius: 50%;
            width: 30px !important;
            height: 30px !important;
            padding: 0px !important;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .Add-btn {
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            font-size: 15px;

        }

        .delete-btn {
            color: var(--app-FFFFFF);
            background: var(--app-DD3E13);
            font-size: 15px;
        }
    }

    .btn-container {
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        margin-top: 20px;
    }

    .ant-progress {
        display: flex;
        align-items: center;
    }

    .ant-progress-line {
        width: 180px !important;
    }

    .ant-progress-inner {
        border: 2px solid var(--app-004986);
    }

    .ant-progress-outer {
        width: 150 !important;
    }

    .ant-progress-bg {
        background-color: var(--app-004986) !important;
    }

    .ant-progress-text {
        font-size: 14px;
        font-family: 'RedHat-SemiBold';
        color: var(--app-004986);
        width: 4em !important;
    }


}

.table-process {
    .ant-progress-text {
        display: none !important;
    }
}

.process-text {
    font-family: 'RedHat-SemiBold' !important;
    color: #FEAD3F !important;
    font-size: 14px !important;
    text-overflow: ellipsis;
    overflow: hidden;

    .overflow-tagline {
        white-space: nowrap;
        cursor: context-menu;
        text-decoration: none;
    }
}

.complete-text {
    font-family: 'RedHat-SemiBold' !important;
    color: var(--app-004986) !important;
    font-size: 14px !important;
    text-overflow: ellipsis;
    overflow: hidden;

    .overflow-tagline {
        white-space: nowrap;
        cursor: context-menu;
        text-decoration: none;
    }
}

.stickering-text {
    font-family: 'RedHat-SemiBold' !important;
    color: #05b76b !important;
    font-size: 14px !important;
    text-overflow: ellipsis;
    overflow: hidden;

    .overflow-tagline {
        white-space: nowrap;
        cursor: context-menu;
        text-decoration: none;
    }
}

.rejected-text {
    font-family: 'RedHat-SemiBold' !important;
    color: #A83516 !important;
    font-size: 14px !important;
    text-overflow: ellipsis;
    overflow: hidden;

    .overflow-tagline {
        white-space: nowrap;
        cursor: context-menu;
        text-decoration: none;
    }
}