.left-column {
    // width: 345px;
    height: 100%;
    background: #FCFCFC;
    box-shadow: 1.95px 1.95px 2.6px rgba(0, 0, 0, 0.15);
    // position: fixed !important;

    .sku-location {
        padding: 17px;
        background: white !important;
        border-bottom: 1px solid #C9C9C9;

        .radio {
            font-size: 16px;
            font-family: "RedHat-Medium";
        }
    }

    .customer-container {
        padding: 15px;
        background: white !important;
        border-bottom: 1px solid #C9C9C9;

        .customer {
            font-family: "RedHat-Bold";
        }


    }

    .width {
        margin-top: 10px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #BDBDBD;
        color: #5D5D5D;
        font-family: "RedHat-SemiBold";
    }

    .location-container {
        border-bottom: 1px solid #C9C9C9;
    }

    .search-container {
        padding: 6px;
        background: white !important;
        border-bottom: 1px solid #C9C9C9;

        .ant-select{
            width: 100%;
        }

        .ant-input-affix-wrapper {
            border: 1px solid white !important;
            color: #5D5D5D;
            font-family: "RedHat-SemiBold";
            font-size: 18px;

        }
    }

    .search-view-data-conteiner {
        padding: 5px;

        .refrech-btn {
            font-size: 16px;
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            font-family: 'RedHat-Medium';
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 40px;
            margin-left: 10px;
            padding: 10px;
        }

        .search-data-container {
            background: #F8F8F8 !important;
            box-shadow: 1.95px 1.95px 2.6px rgba(0, 0, 0, 0.15);
            font-family: 'RedHat-SemiBold' !important;
            width: 100%;

            .card-title {
                margin-top: 5px;
                font-family: 'RedHat-SemiBold' !important;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px;
            }

            .label {
                font-family: 'RedHat-Bold' !important;
                color: #2D2D2D;
                font-size: 16px;
            }

            .count {
                border-radius: 5px;
                background-color: #FFFFFF !important;
                border: none !important;
                height: 37px;
                width: 60px;
                font-family: 'RedHat-Bold' !important;
                font-size: 16px;
                color: var(--app-004986);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .sku-list-container {
        background: white !important;

        .sku-title {
            padding: 6px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: "RedHat-Bold" !important;
            font-size: 14px;
            background-color: #A0BCD2;
            color: var(--app-151515);
            border: none !important;
            border-radius: 6px;
            margin: 2px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .radio-container {
            height: 50.5vh;
            overflow-y: scroll;
            padding-bottom: 30px;
        }

        .ant-radio-button-wrapper {
            height: 100%;
            margin-bottom: 10px;
        }

        .radio-bad {
            background: #FAE2DC !important;
            border: 1px solid #f34436 !important;
            border-left: 6px solid #f34436 !important;
            font-family: 'RedHat-SemiBold' !important;
            width: 100%;

            .card-title {
                margin-top: 5px;
                font-family: 'RedHat-SemiBold' !important;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px;
            }

            .label {
                font-family: 'RedHat-Bold' !important;
                color: #2D2D2D;
                font-size: 16px;
            }

            .count {
                border-radius: 5px;
                background-color: #FFFFFF !important;
                border: none !important;
                height: 50px;
                width: 60px;
                font-family: 'RedHat-Bold' !important;
                font-size: 16px;
                color: #f34436;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .radio-repair {
            background: #FFF3E2 !important;
            border: 1px solid #FEAE3F !important;
            border-left: 6px solid #FEAE3F !important;
            font-family: 'RedHat-SemiBold' !important;
            width: 100%;

            .card-title {
                margin-top: 5px;
                font-family: 'RedHat-SemiBold' !important;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px;
            }

            .label {
                font-family: 'RedHat-Bold' !important;
                color: #2D2D2D;
                font-size: 16px;
            }

            .count {
                border-radius: 5px;
                background-color: #FFFFFF !important;
                border: none !important;
                height: 50px;
                width: 60px;
                font-family: 'RedHat-Bold' !important;
                font-size: 16px;
                color: #FEAE3F;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .radio-good {
            background: #a3ee942e !important;
            border: 1px solid #1BAB66 !important;
            border-left: 6px solid #1BAB66 !important;
            font-family: 'RedHat-SemiBold' !important;
            width: 100%;

            .card-title {
                margin-top: 5px;
                font-family: 'RedHat-SemiBold' !important;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px;
            }

            .label {
                font-family: 'RedHat-Bold' !important;
                color: #2D2D2D;
                font-size: 16px;
            }

            .count {
                border-radius: 5px;
                background-color: #FFFFFF !important;
                border: none !important;
                height: 50px;
                width: 60px;
                font-family: 'RedHat-Bold' !important;
                font-size: 16px;
                color: #1BAB66;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        // .ant-radio-button-wrapper {
        //     background: #F8F8F8 !important;
        //     color: var(--app-646464);
        //     font-family: 'RedHat-Medium' !important;
        //     border: none !important;
        //     width: 100%;

        //     .card-title {
        //         margin-top: 5px;
        //         font-family: 'RedHat-Medium' !important;
        //         font-size: 14px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         margin: 5px;
        //     }

        //     .label {
        //         font-family: 'RedHat-Medium' !important;
        //         color: #2D2D2D;
        //         font-size: 14px;
        //     }

        //     .count {
        //         border-radius: 5px;
        //         background-color: #FFFFFF !important;
        //         border: none !important;
        //         height: 50px;
        //         width: 60px;
        //         font-family: 'RedHat-SemiBold';
        //         font-size: 25px;
        //         color: var(--app-004986);
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }
        // }

        // .ant-radio-button-wrapper-checked {
        //     background: rgba(254, 174, 63, 0.15) !important;
        //     color: var(--app-646464);
        //     font-family: 'RedHat-Medium';

        //     .card-title {
        //         font-family: 'RedHat-Medium';
        //         font-size: 16px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         padding: 0px !important;
        //     }

        //     .count {
        //         border-radius: 5px;
        //         background-color: #FFFFFF !important;
        //         border: none !important;
        //         height: 50px;
        //         width: 60px;
        //         font-family: 'RedHat-Medium';
        //         font-size: 25px;
        //         color: var(--app-004986);
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }
        // }

        // .ant-radio-button-checked {
        //     background: rgba(254, 174, 63, 0.15) !important;
        //     color: var(--app-646464);
        //     font-family: 'RedHat-Medium';
        //     border-left: 5px solid #FEAE3F !important;

        //     .card-title {
        //         font-family: 'RedHat-Medium';
        //         font-size: 16px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         padding: 0px !important;
        //     }

        //     .count {
        //         border-radius: 5px;
        //         background-color: #FFFFFF !important;
        //         border: none !important;
        //         height: 50px;
        //         width: 60px;
        //         font-family: 'RedHat-Medium';
        //         font-size: 25px;
        //         color: var(--app-004986);
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }

        // }
    }
}

.right-column {
    height: 100%;
    background: #FCFCFC;
    width: 100%;

    .sub-content {
        font-family: 'RedHat-Bold' !important;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}

.box-container-view {
    display: flex;
    align-items: center;

   
}

.Scanned-barcode-button {
    border: 2px solid #DB912B !important;
    cursor: pointer;
    background-color: #FFF3E2 !important;
    padding: 10px !important;

    .manageStockIconContainer{
        display: flex !important;
        justify-content: space-between !important;
        font-family: 'RedHat-Bold' !important;
        font-size: 16px;

        svg{
            height: 25px !important;
            width: 20px !important;
            path{
                fill:var(--app-004986)!important;
            }
        }
    }
}


.scanned-box {
    background: #FFFFFF !important;
    border: 1px solid #EFF0F6 !important;
    border-radius: 20px !important;
    box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06) !important;
    padding: 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    svg {
        width: 30px;
        height: 30px;
    }

    .box-label {
        font-size: 20px !important;
        color: black !important;
        font-family: 'RedHat-Bold' !important;
    }
}
.bar-code-img {
    margin-bottom: 5px;

    img {
        width: 100%;
        height: 37px !important;
    }
}
.barListContainer{
    background: #F1F1F1 !important;
    padding: 10px 10px 20px 10px !important;
    margin: 10px !important;
    border-radius: 10px !important;
}