@import "../../Globals/ColorVariables.css";

.create-manifest{
    .form-view{
        background: var(--app-f9f9f9);
        border-radius: 20px;
        padding: 20px;
        .mt-27 {
            margin-top: 27px !important;
        }
    }
}