.main-container {
    .email-container {
        .text-container {
            .content {
                margin-left: 10px;

                .notes-sub-title {
                    color: var(--app-505050);
                    font-family: 'RedHat-Medium';
                    font-size: 18px;
                }

                .notes-sub1-title {
                    color: var(--app-646464);
                    font-family: 'RedHat-Regular';
                    font-size: 18px;
                }
            }
        }

        .ant-row {
            margin: 10px;
            padding: 15px;
            background: #F0F5F8;
            border-radius: 10px;

            .san-details {
                align-items: center;

                .label {
                    color: #5F5F5F;
                    font-family: 'RedHat-SemiBold';
                    font-size: 16px;
                }

                .value {
                    color: var(--app-black);
                    font-family: 'RedHat-Regular';
                    font-size: 14px;
                    margin-top: 5px;
                }
            }
        }
    }

    .sku-table1 {
        background: #ffffff;
        padding: 5px;
        border-radius: 10px;
        .table-boxicon {
            display: flex !important;
            text-align: initial !important;

            .boxtext {
                font-family: 'RedHat-Bold' !important;
                position: relative !important;
                left: 12px !important;
            }
        }

        .ant-table {
            .unloadiing-txt {
                font-size: 14px;
                color: var(--app-FEAD3F);
                font-family: 'RedHat-Medium';
            }
    
            .stickering-txt {
                font-size: 14px;
                color: var(--app-00819D);
                font-family: 'RedHat-Medium';
            }
        }
    
        .ant-table-pagination.ant-pagination {
            display: none !important;
        }
    
        .ant-table-thead>tr>th {
            background-color: #D9ECF0 !important;
            padding: 5px 15px !important;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
            // border-bottom: 2px solid var(--app-00819D) !important;
        }
    
        .ant-table-tbody>tr>td {
            padding: 5px 15px;
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Regular';
        }
    
        .ant-table {
            background: none !important;
        }
    
        .ant-table table {
            border-spacing: 0 10px !important;
        }
    
        .ant-table-thead>tr>th {
    
            background: #505050 !important;
            padding: 10px 10px !important;
            font-size: 14px !important;
            color:white !important;
            font-family: 'RedHat-Bold' !important;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
        }
    
        .ant-table-tbody>tr>td {
            background: #FCFCFC !important;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
            padding: 5px 10px;
            box-shadow: 1px 1px 1px #DADADA;
        }
    
        .ant-table-container table>thead>tr:first-child th:first-child {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            border-left: 1px solid #EBEBEB !important;
        }
    
        .ant-table-container table>thead>tr:first-child th:last-child {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
            border-right: 1px solid #EBEBEB !important;
        }
    
    
        .ant-table-tbody>tr>td:first-child {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            border-left: 1px solid #EBEBEB !important;
    
        }
    
        .ant-table-tbody>tr>td:last-child {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
            border-right: 1px solid #EBEBEB !important;
    
        }
    
    
        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-SemiBold';
        }
    
        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
            border-right: 0px !important;
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Medium';
        }
    
        .ant-table.ant-table-bordered>.ant-table-container {
            border-left: 0px !important;
        }
    }

    .sku-table {
        background: #ffffff;
        padding: 5px;
        border-radius: 10px;
        .table-boxicon {
            display: flex !important;
            text-align: initial !important;

            .boxtext {
                font-family: 'RedHat-Bold' !important;
                position: relative !important;
                left: 12px !important;
            }
        }

        .ant-table {
            .unloadiing-txt {
                font-size: 14px;
                color: var(--app-FEAD3F);
                font-family: 'RedHat-Medium';
            }
    
            .stickering-txt {
                font-size: 14px;
                color: var(--app-00819D);
                font-family: 'RedHat-Medium';
            }
        }
    
        .ant-table-pagination.ant-pagination {
            display: none !important;
        }
    
        .ant-table-thead>tr>th {
            background-color: #D9ECF0 !important;
            padding: 5px 15px !important;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
            // border-bottom: 2px solid var(--app-00819D) !important;
        }
    
        .ant-table-tbody>tr>td {
            padding: 5px 15px;
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Regular';
        }
    
        .ant-table {
            background: none !important;
        }
    
        .ant-table table {
            border-spacing: 0 10px !important;
        }
    
        .ant-table-thead>tr>th {
    
            background: #A0BCD2 !important;
            padding: 10px 10px !important;
            font-size: 14px !important;
            color: black !important;
            font-family: 'RedHat-Bold' !important;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
        }
    
        .ant-table-tbody>tr>td {
            background: #FCFCFC !important;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
            padding: 5px 10px;
            box-shadow: 1px 1px 1px #DADADA;
        }
    
        .ant-table-container table>thead>tr:first-child th:first-child {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            border-left: 1px solid #EBEBEB !important;
        }
    
        .ant-table-container table>thead>tr:first-child th:last-child {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
            border-right: 1px solid #EBEBEB !important;
        }
    
    
        .ant-table-tbody>tr>td:first-child {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            border-left: 1px solid #EBEBEB !important;
    
        }
    
        .ant-table-tbody>tr>td:last-child {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
            border-right: 1px solid #EBEBEB !important;
    
        }
    
    
        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-SemiBold';
        }
    
        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
            border-right: 0px !important;
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Medium';
        }
    
        .ant-table.ant-table-bordered>.ant-table-container {
            border-left: 0px !important;
        }
    }

    .putaway {
        margin-top: 15px;

        .putaway-bar {
            border-left: 5px solid var(--app-FEAE3F);
            border-radius: 6px;
            padding-top: 6px;
            padding-bottom: 6px;
            background: var(--app-FFFFFF);

            .put-row {
                display: flex;
                align-items: center;

                svg {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                }

                .put-id {
                    font-family: 'RedHat-Bold';
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    width: 100%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    .put-id-txt {
                        position: relative;
                        margin-left: 5px;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .category-datas {
                    font-family: 'RedHat-SemiBold';
                    font-size: 14px;
                }

                .mover {
                    display: flex;
                    background: var(--app-rgba0-129-157-01);
                    justify-content: space-between;
                    border-radius: 8px;
                    padding: 0px 10px;
                    align-items: center;


                    .reassign-mvr {
                        position: relative;
                        font-family: 'RedHat-SemiBold';
                        font-size: 16px;
                        color: var(--app-00819D);
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .buttons{
                        display: flex;
                        align-items: center;
                        .save{
                            color: var(--app-00819D);
                            background: #FFFFFF;
                            font-size: 20px;
                            margin-left: 10px;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .cancel{
                            color: #FFFFFF;
                            background: var(--app-00819D);
                            font-size: 20px;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .mvr-container {
                        display: flex;
                        align-items: center;

                        .mvr {
                            font-family: 'RedHat-Bold';
                            position: relative;
                        }


                        .mvr-user {
                            position: relative;
                            font-family: 'RedHat-Regular';
                            font-size: 16px;
                            color: var(--app-00819D);
                        }

                        .mvr-icon {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;

                            svg {
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                            }
                        }
                    }
                }

                .inprogress {
                    background: rgba(254, 174, 63, 0.1);
                    padding: 4px 10px;
                    margin-right: 10px;
                    border-radius: 6px;
                    font-family: 'RedHat-Regular';
                    font-size: 16px;
                    color: var(--app-FEAE3F);
                    text-align: center;
                }
            }
        }

        .sub-container {
            padding: 20px;
            margin-top: 15px;
            background: var(--app-FFFFFF);
            border-radius: 8px;

            .pallete-headers {
                display: flex;
                position: relative;
                bottom: 10px;

                .pallete-id {
                    padding-right: 35px;
                    font-family: 'RedHat-Bold';
                }

                .location-id {
                    font-family: 'RedHat-Bold';
                }
            }

            .table-input-container {

                margin-top: 10px;

                .ant-table {
                    background: none !important;
                    margin-bottom: 20px;
                }

                .ant-table-thead>tr>th {
                    background-color: var(--app-505050) !important;
                    padding: 5px 10px !important;
                    color: #FFFFFF;
                    border-top: 1px solid #EBEBEB !important;
                    border-bottom: 1px solid #EBEBEB !important;
                }

                .ant-table-tbody>tr>td {
                    border-bottom: 10px solid #f0f0f0;
                    padding: 5px 10px;
                }

                // .ant-table-tbody > tr:first-child td:first-child{
                //     border-top-left-radius: 10px !important;

                //     border-bottom-left-radius: 10px !important;
                // }
                // .ant-table-tbody > tr:first-child  td:last-child{
                //     border-top-right-radius: 10px !important;
                //     border-bottom-right-radius: 10px !important;

                // }

                // .ant-table-container table>thead>tr:first-child th:first-child {
                //     border-top-left-radius: 10px !important;

                //     border-bottom-left-radius: 10px !important;
                // }

                // .ant-table-container table>thead>tr:first-child th:last-child {
                //     border-top-right-radius: 10px !important;
                //     border-bottom-right-radius: 10px !important;
                // }


                .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
                    font-size: 14px;
                    color: var(--app-black);
                    font-family: 'RedHat-SemiBold';
                    border-right: 0px !important;
                }

                .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
                    border-right: 0px !important;
                    background-color: var(--app-FFFFFF) !important;
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }

                .ant-table.ant-table-bordered>.ant-table-container {
                    border-left: 0px !important;
                }

                .ant-table-tbody>tr.ant-table-row:hover>td,
                .ant-table-tbody>tr>td.ant-table-cell-row-hover {
                    background: none !important;
                }

                .ant-table-pagination.ant-pagination {
                    display: none !important;
                }
            }
        }
    }

    .putaway-container {
        margin-top: 15px;
        padding: 8px;
        border: 1px solid var(--app-BFBFBF);
        border-radius: 8px;
        background: var(--app-rgba45-45-45-005);

        .putaway-bar {
            border-radius: 6px;
            padding-top: 6px;
            padding-bottom: 6px;
            background: var(--app-FFFFFF);

            .put-row {
                display: flex;
                align-items: center;

                svg {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                }

                .put-id {
                    font-family: 'RedHat-Bold';
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .put-id-txt {
                        position: relative;
                        margin-left: 5px;
                    }
                    .caret-icon-expanded{
                        rotate: 90deg;
                        transition: 400ms;
                    }
                    .caret-icon{
                        transition: 400ms;
                    }
                }

                .category-datas {
                    font-family: 'RedHat-SemiBold';
                    font-size: 14px;
                }

                .mover {
                    display: flex;
                    background: var(--app-rgba0-129-157-01);
                    justify-content: space-between;
                    border-radius: 8px;
                    padding: 0px 10px;
                    align-items: center;

                    .buttons{
                        display: flex;
                        align-items: center;
                        .save{
                            color: var(--app-00819D);
                            background: #FFFFFF;
                            font-size: 20px;
                            margin-left: 10px;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .cancel{
                            color: #FFFFFF;
                            background: var(--app-00819D);
                            font-size: 20px;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .reassign-mvr {
                        position: relative;
                        font-family: 'RedHat-SemiBold';
                        font-size: 16px;
                        color: var(--app-00819D);
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .mvr-container {
                        display: flex;
                        align-items: center;

                        .mvr {
                            font-family: 'RedHat-Bold';
                            position: relative;
                        }


                        .mvr-user {
                            position: relative;
                            font-family: 'RedHat-Regular';
                            font-size: 16px;
                            color: var(--app-00819D);
                        }

                        .mvr-icon {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;

                            svg {
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                            }
                        }
                    }
                }

                .inprogress {
                    background: rgba(254, 174, 63, 0.1);
                    padding: 4px 10px;
                    margin-right: 10px;
                    border-radius: 6px;
                    font-family: 'RedHat-Regular';
                    font-size: 16px;
                    color: var(--app-FEAE3F);
                    text-align: center;
                }
            }
        }

        .sub-container {
            padding: 20px;
            margin-top: 15px;
            background: var(--app-FFFFFF);
            border-radius: 8px;

            .pallete-headers {
                display: flex;
                position: relative;
                bottom: 10px;

                .pallete-id {
                    padding-right: 35px;
                    font-family: 'RedHat-Bold';
                }

                .location-id {
                    font-family: 'RedHat-Bold';
                }
            }

            .table-input-container {

                margin-top: 10px;

                .box-table {
                    .box-container {
                        display: flex;
                        align-items: center;

                        .box-label {
                            margin-left: 5px;
                            font-size: 14px;
                            font-family: "RedHat-Bold";
                            color: var(--app-004986);
                        }

                        .good-icon {
                            svg {
                                path {
                                    fill: var(--app-004986) !important
                                }
                            }
                        }

                        .box-repair-label {
                            font-size: 14px;
                            font-family: "RedHat-Bold";
                            color: #FEAE3F;

                            .repair-icon {
                                margin-left: -6px;
                                height: 14px !important;
                                width: 20px !important;

                                svg {
                                    height: 14px !important;

                                    path {
                                        fill: #FEAE3F !important
                                    }
                                }
                            }
                        }


                        .box-Bad-label {
                            font-size: 14px;
                            font-family: "RedHat-Bold";
                            color: #DD3E13;
                        }

                        .bad-icon {
                            margin-left: -6px;

                            svg {
                                height: 14px !important;

                                path {
                                    fill: #DD3E13 !important
                                }
                            }
                        }
                    }

                    .ant-table-pagination.ant-pagination {
                        display: none !important;
                    }
                
                    .ant-table-thead>tr>th {
                        background-color: var(--app-022E3A) !important;
                        padding: 5px 15px !important;
                        border-top: 1px solid #EBEBEB !important;
                        border-bottom: 1px solid #EBEBEB !important;
                        // border-bottom: 2px solid var(--app-00819D) !important;
                    }
                
                    .ant-table-tbody>tr>td {
                        padding: 5px 15px;
                        font-size: 14px;
                        color: var(--app-black);
                        font-family: 'RedHat-Regular';
                    }
                
                    .ant-table {
                        background: none !important;
                    }
                
                    .ant-table table {
                        border-spacing: 0 10px !important;
                    }
                
                    .ant-table-thead>tr>th {
                
                        background: var(--app-022E3A) !important;
                        padding: 10px 10px !important;
                        font-size: 14px !important;
                        color: white !important;
                        font-family: 'RedHat-Bold' !important;
                        border-top: 1px solid #EBEBEB !important;
                        border-bottom: 1px solid #EBEBEB !important;
                    }
                
                    .ant-table-tbody>tr>td {
                        background: #FCFCFC !important;
                        border-top: 1px solid #EBEBEB !important;
                        border-bottom: 1px solid #EBEBEB !important;
                        padding: 5px 10px;
                        box-shadow: 1px 1px 1px #DADADA;
                    }
                
                    .ant-table-container table>thead>tr:first-child th:first-child {
                        border-top-left-radius: 6px !important;
                        border-bottom-left-radius: 6px !important;
                        border-left: 1px solid #EBEBEB !important;
                    }
                
                    .ant-table-container table>thead>tr:first-child th:last-child {
                        border-top-right-radius: 6px !important;
                        border-bottom-right-radius: 6px !important;
                        border-right: 1px solid #EBEBEB !important;
                    }
                
                
                    .ant-table-tbody>tr>td:first-child {
                        border-top-left-radius: 6px !important;
                        border-bottom-left-radius: 6px !important;
                        border-left: 1px solid #EBEBEB !important;
                
                    }
                
                    .ant-table-tbody>tr>td:last-child {
                        border-top-right-radius: 6px !important;
                        border-bottom-right-radius: 6px !important;
                        border-right: 1px solid #EBEBEB !important;
                
                    }
                
                
                    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
                        font-size: 14px;
                        color: var(--app-black);
                        font-family: 'RedHat-SemiBold';
                    }
                
                    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
                        border-right: 0px !important;
                        font-size: 14px;
                        color: var(--app-black);
                        font-family: 'RedHat-Medium';
                    }
                
                    .ant-table.ant-table-bordered>.ant-table-container {
                        border-left: 0px !important;
                    }
                
                }

            }
        }

    }
    .putaway-container-1 {
        margin-top: 15px;
        padding: 8px;
        border: 1px solid #FEAE3F;
        border-radius: 8px;
        background: rgba(254, 174, 63, 0.1);

        .putaway-bar {
            border-radius: 6px;
            padding-top: 6px;
            padding-bottom: 6px;
            background: var(--app-FFFFFF);

            .put-row {
                display: flex;
                align-items: center;

                svg {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                }

                .put-id {
                    font-family: 'RedHat-Bold';
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .put-id-txt {
                        position: relative;
                        margin-left: 5px;
                    }
                    .caret-icon-expanded{
                        rotate: 90deg;
                        transition: 400ms;
                    }
                    .caret-icon{
                        transition: 400ms;
                    }
                }

                .category-datas {
                    font-family: 'RedHat-SemiBold';
                    font-size: 14px;
                }

                .mover {
                    display: flex;
                    background: var(--app-rgba0-129-157-01);
                    justify-content: space-between;
                    border-radius: 8px;
                    padding: 0px 10px;
                    align-items: center;

                    .buttons{
                        display: flex;
                        align-items: center;
                        .save{
                            color: var(--app-00819D);
                            background: #FFFFFF;
                            font-size: 20px;
                            margin-left: 10px;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .cancel{
                            color: #FFFFFF;
                            background: var(--app-00819D);
                            font-size: 20px;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .reassign-mvr {
                        position: relative;
                        font-family: 'RedHat-SemiBold';
                        font-size: 16px;
                        color: var(--app-00819D);
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .mvr-container {
                        display: flex;
                        align-items: center;

                        .mvr {
                            font-family: 'RedHat-Bold';
                            position: relative;
                        }


                        .mvr-user {
                            position: relative;
                            font-family: 'RedHat-Regular';
                            font-size: 16px;
                            color: var(--app-00819D);
                        }

                        .mvr-icon {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;

                            svg {
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                            }
                        }
                    }
                }

                .inprogress {
                    background: rgba(254, 174, 63, 0.1);
                    padding: 4px 10px;
                    margin-right: 10px;
                    border-radius: 6px;
                    font-family: 'RedHat-Regular';
                    font-size: 16px;
                    color: var(--app-FEAE3F);
                    text-align: center;
                }
            }
        }

        .sub-container {
            padding: 20px;
            margin-top: 15px;
            background: var(--app-FFFFFF);
            border-radius: 8px;

            .pallete-headers {
                display: flex;
                position: relative;
                bottom: 10px;

                .pallete-id {
                    padding-right: 35px;
                    font-family: 'RedHat-Bold';
                }

                .location-id {
                    font-family: 'RedHat-Bold';
                }
            }

            .table-input-container {

                margin-top: 10px;

                .box-table {
                    .box-container {
                        display: flex;
                        align-items: center;

                        .box-label {
                            margin-left: 5px;
                            font-size: 14px;
                            font-family: "RedHat-Bold";
                            color: var(--app-004986);
                        }

                        .good-icon {
                            svg {
                                path {
                                    fill: var(--app-004986) !important
                                }
                            }
                        }

                        .box-repair-label {
                            font-size: 14px;
                            font-family: "RedHat-Bold";
                            color: #FEAE3F;

                            .repair-icon {
                                margin-left: -6px;
                                height: 14px !important;
                                width: 20px !important;

                                svg {
                                    height: 14px !important;

                                    path {
                                        fill: #FEAE3F !important
                                    }
                                }
                            }
                        }


                        .box-Bad-label {
                            font-size: 14px;
                            font-family: "RedHat-Bold";
                            color: #DD3E13;
                        }

                        .bad-icon {
                            margin-left: -6px;

                            svg {
                                height: 14px !important;

                                path {
                                    fill: #DD3E13 !important
                                }
                            }
                        }
                    }

                    .ant-table-pagination.ant-pagination {
                        display: none !important;
                    }
                
                    .ant-table-thead>tr>th {
                        background-color: var(--app-022E3A) !important;
                        padding: 5px 15px !important;
                        border-top: 1px solid #EBEBEB !important;
                        border-bottom: 1px solid #EBEBEB !important;
                        // border-bottom: 2px solid var(--app-00819D) !important;
                    }
                
                    .ant-table-tbody>tr>td {
                        padding: 5px 15px;
                        font-size: 14px;
                        color: var(--app-black);
                        font-family: 'RedHat-Regular';
                    }
                
                    .ant-table {
                        background: none !important;
                    }
                
                    .ant-table table {
                        border-spacing: 0 10px !important;
                    }
                
                    .ant-table-thead>tr>th {
                
                        background: var(--app-022E3A) !important;
                        padding: 10px 10px !important;
                        font-size: 14px !important;
                        color: white !important;
                        font-family: 'RedHat-Bold' !important;
                        border-top: 1px solid #EBEBEB !important;
                        border-bottom: 1px solid #EBEBEB !important;
                    }
                
                    .ant-table-tbody>tr>td {
                        background: #FCFCFC !important;
                        border-top: 1px solid #EBEBEB !important;
                        border-bottom: 1px solid #EBEBEB !important;
                        padding: 5px 10px;
                        box-shadow: 1px 1px 1px #DADADA;
                    }
                
                    .ant-table-container table>thead>tr:first-child th:first-child {
                        border-top-left-radius: 6px !important;
                        border-bottom-left-radius: 6px !important;
                        border-left: 1px solid #EBEBEB !important;
                    }
                
                    .ant-table-container table>thead>tr:first-child th:last-child {
                        border-top-right-radius: 6px !important;
                        border-bottom-right-radius: 6px !important;
                        border-right: 1px solid #EBEBEB !important;
                    }
                
                
                    .ant-table-tbody>tr>td:first-child {
                        border-top-left-radius: 6px !important;
                        border-bottom-left-radius: 6px !important;
                        border-left: 1px solid #EBEBEB !important;
                
                    }
                
                    .ant-table-tbody>tr>td:last-child {
                        border-top-right-radius: 6px !important;
                        border-bottom-right-radius: 6px !important;
                        border-right: 1px solid #EBEBEB !important;
                
                    }
                
                
                    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
                        font-size: 14px;
                        color: var(--app-black);
                        font-family: 'RedHat-SemiBold';
                    }
                
                    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
                        border-right: 0px !important;
                        font-size: 14px;
                        color: var(--app-black);
                        font-family: 'RedHat-Medium';
                    }
                
                    .ant-table.ant-table-bordered>.ant-table-container {
                        border-left: 0px !important;
                    }
                
                }

            }
        }

    }

    .empty-box-container {
        margin-top: 20px;
        padding: 10px;
        border: 1px solid var(--app-BFBFBF);
        border-radius: 8px;
        background-color: #ffffff !important;

        .box-headers {
            padding: 20px;
            font-family: 'RedHat-Bold';

        }

        .boxes-container {
            background: var(--app-rgba0-129-157-015);
            padding: 10px;
            border-radius: 8px;
            display: flex;

            .boxes {
                background: var(--app-FFFFFF);
                border-radius: 8px;
                padding-top: 10px;
                padding-right: 19px;
                padding-bottom: 5px;
                padding-left: 8px;
                margin-left: 10px;

            }
        }
    }

    .sub-table-container {
        .ant-table-thead {
            background-color: rgba(169, 169, 169, 0.345) !important;
        }

        .ant-table-thead>tr>th {
            padding: 10px 10px !important;
            color: var(--app-black);
            font-family: 'RedHat-SemiBold';
            font-size: 14px;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
        }

        .ant-table-tbody {
            background: var(--app-F0F5F8)
        }

        .ant-table-tbody>tr>td {
            border-bottom: 2px solid #ffffff !important;
            color: var(--app-black);
            font-family: 'RedHat-Regular';
            font-size: 14px;
            padding: 10px 16px !important;
        }

        .ant-table-tbody>tr.ant-table-row:hover>td,
        .ant-table-tbody>tr>td.ant-table-cell-row-hover {
            background: var(--app-F0F5F8) !important
        }
    }

}