.main-header {
    background: var(--app-004986);
    color: var(--app-FFFFFF);
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // position: fixed;
    // z-index: 99;

    .user-details {
        border-radius: 5px;
        // background-color: #34b5b13b !important;
        color: #FFFFFF !important;
        border: none !important;
        height: 35px;
        width: 100%;
        // padding: 5px;
        font-family: 'RedHat-Bold' !important;
        font-size: 14px !important;
        display: block;
        line-height: 17px;
        text-align: center;
        margin-left: 5px;

        .name-field {
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
   
    .warehouse-process-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // height: 30px;
        margin-left: 20px;
        // background: rgba(255, 255, 255, 0.15);
        border-radius: 5px;
        padding: 6px;

        .ant-divider-vertical {
            background-color: white;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-left: 30%;

            .selection-title {
                font-family: "RedHat-Medium";
                font-size: 16px;
            }

            .selection-value {
                font-family: "RedHat-Bold";
                font-size: 16px;
                margin-left: 5px;
            }

            .selection-Process-title {
                font-family: "RedHat-Medium";
                font-size: 16px;
                color: #FEAE3F;
            }

            .selection-Process-value {
                font-family: "RedHat-Bold";
                font-size: 16px;
                margin-left: 5px;
                color: #FEAE3F;
            }
        }

    }

    .align {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .content {
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
        }

        .selection-title {
            font-family: "RedHat-Medium";
            font-size: 16px;
        }

        .selection-value {
            font-family: "RedHat-Bold";
            font-size: 16px;
            margin-left: 5px;
        }
    }

    .align1 {
        .sub-content {
            display: flex;
            align-items: center;

            .content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 10px;
                margin-right: 10px;
            }

            .selection-title {
                font-family: "RedHat-Medium";
                font-size: 18px;
            }

            .selection-value {
                font-family: "RedHat-Bold";
                font-size: 18px;
                margin-left: 5px;
            }
        }

        .img-col {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }



    .menu-icon {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .wms360-icon {
        margin-left: 20px;
        cursor: pointer;
    }

    // .selection-title {
    //     font-family: "RedHat-SemiBold";
    // }
    .Client-container {
        width: 300px;

        .selection-title {
            font-family: "RedHat-Regular";
            font-size: 16px;
        }
    }

    .select-title {
        font-size: 12px;
        font-family: redhat-regular;
    }

    .ant-select {
        .ant-select-selector {
            border-radius: 5px !important;
            background: var(--app-rgba255-255-255-015) !important;
            border: none !important;
            height: 25px !important;

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
                font-size: 12px !important;
            }


            span {
                font-size: 14px;
                color: var(--app-FFFFFF);
            }
        }

        .ant-select-arrow .anticon>svg {
            color: var(--app-FFFFFF);
            height: 10px !important;
        }
    }

    .logout-container {
        background-color: var(--app-rgba255-255-255-015);
        padding: 3px 10px;
        border-radius: 6px;
        // margin-top: 10px;
        width: 100%;

        .divider {
            font-size: 18px;
            padding: 0px 10px;
        }

        .label {
            font-family: "RedHat-Medium";
            font-size: 16px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .svg {
            width: 20px;
            height: 20px;

            path {
                fill: #FFFFFF
            }
        }
    }

    .profile-pic-container {
        background-color: var(--app-rgba255-255-255-015);
        height: 40px;
        max-width: 40px;
        border-radius: 50%;
        padding: 5px;
        display: flex;

        .profile-pic-inner-container {
            background-color: white;
            border-radius: 50%;
            // height: 100%;
            width: 100%;
            padding: 3px;

            .inner-container {
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                color: var(--app-004986);
                padding: 10px;
                font-family: "RedHat-Bold";
                font-size: 18px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    .logout-text {
        font-family: redhat-medium;
        font-size: 16px;
        padding: 6px;
        cursor: pointer;
    }

    .logout-icon {
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding: 6px;

        img {
            max-width: 100%;
        }
    }
}


.drawer-container {
    .ant-drawer-content-wrapper {
        width: 270px !important;
        background: black !important;
        height: 100% !important;


        .ant-drawer-body {
            padding: 0px !important;
        }

        .side-bar-menu-container {
            .menu {
                margin-top: 10px;

                .icon-bg {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba(0, 129, 157, 0.15);
                    border-radius: 5px;
                    padding: 10px;
                }

                .ant-menu-item {
                    margin-top: 20px !important;
                    padding: 0px !important;

                    // left: -25px !important;
                    .ant-menu-title-content {
                        margin-left: 5px;
                    }


                }

                .ant-menu-item a:hover {
                    color: white !important;
                }


                .ant-menu-item-selected a,
                .ant-menu-item-selected a:hover {
                    color: white !important;
                }

                .ant-menu-item-active,
                .ant-menu-item-selected {
                    background: var(--app-004986);
                    color: white;
                    border-radius: 5px;
                    padding-left: 0px !important;

                    .icon-bg {

                        background: var(--app-004986);

                        border-radius: 5px;

                        svg {
                            path {
                                fill: white !important;
                            }
                        }
                    }
                }
            }

        }

        .header-container {
            display: flex;
            align-items: center;



            .logo {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 20px;

                svg {
                    path {
                        fill: black !important;
                    }
                }
            }

            .drawer-hamicon {
                display: flex;
                justify-content: center;
                align-items: center;

                .anticon {
                    font-size: 22px !important;
                }

            }
        }


        .ant-drawer-header {
            display: none !important;
        }
    }
}

.profile-view-popover-menu {
    position: absolute;
    background: white;
    border: 1px solid var(--app-004986);
    border-radius: 5px;
    width: 230px;
    padding: 10px;
    right: 5px;
    // top: 55px;
    box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
    // z-index: 999999990;

    .ant-row {
        position: relative;
    }

    .close-btn {
        border-radius: 50%;
        width: 16px !important;
        height: 16px !important;
        padding: 0px !important;
        justify-content: center;
        align-items: center;
        border: 1px solid #DD3E13 !important;
        position: absolute;
        top: 12px;
        right: -15px;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        color: #DD3E13;
        background: #FAE2DC;
        font-size: 11px;

        svg {
            width: 10px;
            height: 10px;

            path {
                fill: #DD3E13;
            }
        }

    }


    .closeicon {
        font-size: 12px;
    }

    .sign-out-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name-container {
            white-space: nowrap;
        }

        .sign-out {
            text-decoration: underline;
            color: var(--app-FF5000);
            white-space: nowrap;
        }
    }

    .img-box {
        .img-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            background-color: var(--app-004986)!important;
            color: white !important;
            font-size: 24px;
            font-family: "RedHat-Bold";

            img {
                display: block;
                width: 75px;
                height: 75px;
                border-radius: 50%;
            }

            .overlay {
                object-fit: cover;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .3s ease;
                background-color: var(--app-rgba255-255-255-015);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 75px;
                height: 75px;
                border-radius: 50%;

                .icon {
                    color: white;
                    font-size: 30px;
                    position: absolute;
                    top: 40%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    text-align: center;
                }

                .text {
                    color: white !important;
                    font-size: 16px;
                    position: absolute;
                    top: 60%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    text-align: center;
                }
            }
        }

        .img-container:hover .overlay {
            opacity: 1;
        }
    }

    .name {
        color: black;
        font-size: 16px;
        font-family: "RedHat-SemiBold";
    }


    .email {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: context-menu;
        text-decoration: none;
        color: black;

        span {
            font-size: 16px;
            font-family: "RedHat-SemiBold";
            color: grey;
        }
    }

    .view-profile {
        text-decoration: underline;
        color: var(--app-004986);
        white-space: nowrap;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: "RedHat-SemiBold";
        cursor: pointer;

        svg {
            margin-left: 10px;
            width: 20px;
            height: 20px;

            path {
                fill: var(--app-004986)!important
            }
        }
    }
}