.sub-sider-menu {
    background: #F9F9FF;
    overflow: scroll;
    .card-title {
        font-family: 'RedHat-Bold';
        font-size: 14px;
        line-height: 23px;
        white-space: break-spaces;

    }
    .skeleton{
        height: 80px;
        padding: 15% 10% !important;
    }
    // .menu-position{
    //     position: fixed;
    //     width: 10%;
    //     top: 9vh;
    // }
    .ant-menu-item {
        height: 100%;
        margin-bottom: 5px;
        border-radius: 10px !important;
    }

    .ant-menu-item, .ant-menu-item:hover {
        background-color: #FFFFFF !important;
        border: 2px solid #E6E9EE !important;
        color: var(--app-646464) !important;
        font-family: 'RedHat-Bold' !important;
        border-radius: 10px;
        width: 100%;
        height: fit-content !important;
        .ant-menu-title-content{
            width: 100% !important;
        }
        .count {
            border-radius: 5px;
            background-color: #FFFFFF !important;
            border: 1px solid #E6E9EE !important;
            color: #037DD1;
            height: 35px;
            margin-bottom: 10px;
            height: 35px;
            padding: 5px;
            font-family: 'RedHat-Bold' !important;
            font-size: 16px !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }
    }
    
    .ant-menu-item-selected, .ant-menu-item-selected:hover {
        border-radius: 10px !important;
        background-color: #037DD1 !important;
        color: #FFFFFF !important;
        font-family: 'RedHat-Regular';
        min-height: 50px;
        .ant-menu-title-content{
            width: 100% !important;
        }
        .count {
            border-radius: 5px;
            background-color: #3497DB !important;
            color: #FFFFFF !important;
            border: none !important;
            height: 35px;
            height: 35px;
            padding: 5px;
            font-family: 'RedHat-Bold' !important;
            font-size: 16px !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }
    }

    .mismatched.ant-menu-item{
        background-color: #F7E4E4 !important;
        border: 1px solid #BB1A1A !important;
        color: var(--app-646464);
        font-family: 'RedHat-Regular';
        border-radius: 10px;
        width: 100%;
        min-height: 50px;
        .ant-menu-title-content{
            width: 100% !important;
        }
        .count {
            border-radius: 5px;
            background-color: #FFFFFF !important;
            border: 1px solid #E6E9EE !important;
            height: 35px;
            margin-bottom: 10px;
            height: 35px;
            padding: 5px;
            font-family: 'RedHat-Bold' !important;
            font-size: 16px !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }
    }

    .rto{
        display: flex;
        // justify-content: center;
        align-items: center;
        font-size: 16px !important;
        font-family: redhat-bold !important;
        min-height: 50px !important;
    }
    .rto .card-title {
        font-family: 'RedHat-Bold';
        font-size: 16px !important;
        line-height: 23px;
        white-space: break-spaces;
    }

    .mismatched.ant-menu-item-selected{
        border-radius: 10px !important;
        background: #E7403D !important;
        border: none;
        color:white  !important;
        font-family: 'RedHat-Regular';
        min-height: 50px;
        .ant-menu-title-content{
            width: 100% !important;
        }
        .count {
            border-radius: 5px;
            background-color: white !important;
            color: #E7403D  !important;
            border: none !important;
            height: 35px;
            padding: 5px;
            font-family: 'RedHat-Bold' !important;
            font-size: 16px !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }
    }


    .ant-radio-button-wrapper:last-child {
        border-left: none !important;
    }


}
.mt-minus{
    margin-top: -45px !important;
}
.sub-sider-menu::-webkit-scrollbar{
    display: none !important;
}