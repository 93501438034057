.email-container {
    .wms360-icon{
        background: linear-gradient(89.98deg, #004986 -1.05%, #3A73A2 99.98%);
        box-shadow: 0px 2px 1px rgba(20, 20, 20, 0.33);
        padding: 5px 0px 2px 20px;
    }
    .sub-table-container {
        margin-top: 20px;
        padding: 10px;
        border-radius: 8px;

        .ant-pagination {
            display: none;
        }

        .ant-table-thead>tr>th {
            background: var(--app-rgba45-45-45-005);
            text-align: center;

        }

        .ant-table-tbody>tr>td {
            text-align: center;

        }
    }

    .input-bx {
        padding: 10px;


        .ant-input {
            height: 90px !important;
        }

    }

    .regards-container {
        padding: 15px;
        margin-top: 10px;

        .best-regards {
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 5px;
        }

        .wisher {
            font-size: 16px;
        }
    }

    margin-top: 5px;
    border: 1px solid var(--app-00819D);

    .expanded-content-container {
        padding: 10px;

        .w-100 {
            background-color: var(--app-F0F5F8);
            text-align: center;
        }
    }

    .emz-icon {
        background: linear-gradient(89.98deg, #004986 -1.05%, #3A73A2 99.98%);
        padding: 3px;

        svg {
            position: relative;
            top: 4px;
            left: 15px;
        }
    }

    .text-container {
        padding: 15px 15 0px 15px;

        .notes-sub-title {
            color: var(--app-002831);
            font-size: 16px;
            font-family: 'RedHat-Bold';
            padding-bottom: 20px;
        }

        .notes-sub1-title {
            font-family: 'RedHat-Regular';
            padding-bottom: 20px;

        }
    }
}