@import "../Globals/ColorVariables.css";

.expand-collape-container {
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: var(--app-E4E5E8);
    // height: 65vh;
    overflow: auto;

    .vertical-line {
        width: 5px;
        background-color: black;
    }

}

.expand-collape-container-2 {
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: var(--app-E4E5E8);
    // height: 90vh;
    overflow-y: scroll;
    // overflow: auto;

    .vertical-line {
        width: 5px;
        background-color: black;
    }


}

.selected-container {
    padding: 10px;
    width: 100%;
    background: #E8F6EF;
    border: 1px solid #1BAB66;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;

    .text {
        font-family: 'RedHat-SemiBold' !important;

    }

    .colr-wh{
        color: #FFFFFF !important
    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        // background-color: var(--app-D9E4ED);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;

        .anticon {
            color: #ffffff !important;
            font-size: 15px !important;
        }
    }

    .delete-btn,
    .delete-btn:hover,
    .delete-btn:focus {
        background-color: var(--app-CF4F4F);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;
        .anticon {
            color: white !important;
            font-size: 15px !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }
    .value{
        font-family: redhat-semibold;
        font-size: 16px;
        
    }
    .sku-details {
        padding: 10px;
    }
}

.content-container1 .img-preview,
.content-container .img-preview {
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #DADADA;
    cursor: pointer;
    img{
        max-width: 100%;
        max-height: 100%;
    }
}

.content-container1 {
    padding: 10px;
    width: 100%;
    background: #FCFCFC;
    border: 1px solid #EBEBEB;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;
    font-family: redhat-regular !important;
    align-items: center;
    .add-btn {
        background: #E0EDF0;
        border: 1px solid var(--app-004986);
        border-radius: 6px;
        color: var(--app-004986);
        padding: 3px 10px;
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .release{
        font-family: redhat-semibold;
        text-decoration: underline;
        color: var(--app-004986);
        cursor: pointer;
        font-size: 17px;
    }

    .file{
        cursor: pointer;
        svg{
            color: var(--app-004986) !important;
            width: 50px !important;
            height: 40px !important;
        }
    }
    
    .text {
        font-family: 'RedHat-SemiBold' !important;

    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        background-color: var(--app-D9E4ED);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;
        border: 1px solid #004986;
        .anticon {
            color: #004986 !important;
            font-size: 15px !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .picker-select{
        .ant-select-selector{
            width: 110px !important;
        }
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .content-inner-container {
        margin-top: 10px;

        .expanded-content-container {
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            table {
                th {
                    font-size: 14px;
                    text-align: start;
                    font-family: 'RedHat-Bold';
                }

                td {
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }
            }
        }
    }

    .picker {
        display: flex;
        align-items: center;
        margin-right: 5px;

        .user-profile {
            background: rgba(0, 129, 157, 0.1);
            display: flex;
            align-items: center;
            padding: 4px 10px;
            border-radius: 10px;
            margin-right: 5px;

            .profile-pic {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 5px;

                img {
                    max-width: 100%;
                }
            }
        }

        .reassign {
            background: rgba(0, 129, 157, 0.1);
            color: var(--app-004986);
            padding: 5px 10px;
            border-radius: 10px;
        }
    }

    .assignement-status {
        background: var(--app-EEEEEE);
        color: var(--app-ABABAB);
        padding: 5px 10px;
        border-radius: 5px;
    }

    .open-icon {
        display: flex;
        align-items: center;

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
            margin-right: 5px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .sku-details {
        padding: 10px;
    }

    .expanded-view{
        background: white;
        margin: 10px;
        padding: 10px;
        .data{
            font-family: redhat-semibold;
            font-size: 16px;
            padding: 5px 10px;
            background: var(--app-E9E9E9);
        }
    }

}

.content-container {
    padding: 10px;
    width: 100%;
    background: #FCFCFC;
    border: 1px solid #EBEBEB;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;
    font-family: redhat-regular !important;
    display: flex;
    align-items: center;

    .add-btn {
        background: #E0EDF0;
        border: 1px solid var(--app-004986);
        border-radius: 6px;
        color: var(--app-004986);
        padding: 3px 10px;
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .text {
        font-family: 'RedHat-SemiBold' !important;

    }

    .release{
        font-family: redhat-semibold;
        text-decoration: underline;
        color: var(--app-004986);
        cursor: pointer;
        font-size: 17px;
    }

    .delete-btn,
    .delete-btn:hover,
    .delete-btn:focus {
        background-color: var(--app-CF4F4F);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;
        .anticon {
            color: white !important;
            font-size: 15px !important;
        }
    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        background-color: var(--app-D9E4ED);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;
        border: 1px solid #004986;
        .anticon {
            color: #004986 !important;
            font-size: 15px !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .content-inner-container {
        margin-top: 10px;

        .expanded-content-container {
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            table {
                th {
                    font-size: 14px;
                    text-align: start;
                    font-family: 'RedHat-Bold';
                }

                td {
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }
            }
        }
    }

    .picker {
        display: flex;
        align-items: center;
        margin-right: 5px;

        .user-profile {
            background: rgba(0, 129, 157, 0.1);
            display: flex;
            align-items: center;
            padding: 4px 10px;
            border-radius: 10px;
            margin-right: 5px;

            .profile-pic {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 5px;

                img {
                    max-width: 100%;
                }
            }
        }

        .reassign {
            background: rgba(0, 129, 157, 0.1);
            color: var(--app-004986);
            padding: 5px 10px;
            border-radius: 10px;
        }
    }

    .assignement-status {
        background: var(--app-EEEEEE);
        color: var(--app-ABABAB);
        padding: 5px 10px;
        border-radius: 5px;
    }

    .open-icon {
        display: flex;
        align-items: center;

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
            margin-right: 5px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .sku-details {
        padding: 10px;
    }

    .picker-select{
        .ant-select-selector{
            width: 110px !important;
        }
    }
}

.content-container3 {
    padding: 10px;
    width: 100%;
    background: #FCFCFC;
    border: 1px solid #EBEBEB;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;

    .add-btn {
        background: #E0EDF0;
        border: 1px solid var(--app-004986);
        border-radius: 6px;
        color: var(--app-004986);
        padding: 3px 10px;
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .text {
        font-family: 'RedHat-SemiBold' !important;

    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        background-color: var(--app-004986);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;

        .anticon {
            color: #ffffff !important;
            font-size: 15px !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .content-inner-container {
        margin-top: 10px;

        .expanded-content-container {
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            table {
                th {
                    font-size: 14px;
                    text-align: start;
                    font-family: 'RedHat-Bold';
                }

                td {
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }
            }
        }
    }

    .picker {
        display: flex;
        align-items: center;
        margin-right: 5px;

        .user-profile {
            background: rgba(0, 129, 157, 0.1);
            display: flex;
            align-items: center;
            padding: 4px 10px;
            border-radius: 10px;
            margin-right: 5px;

            .profile-pic {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 5px;

                img {
                    max-width: 100%;
                }
            }
        }

        .reassign {
            background: rgba(0, 129, 157, 0.1);
            color: var(--app-004986);
            padding: 5px 10px;
            border-radius: 10px;
        }
    }

    .assignement-status {
        background: var(--app-EEEEEE);
        color: var(--app-ABABAB);
        padding: 5px 10px;
        border-radius: 5px;
    }

    .open-icon {
        display: flex;
        align-items: center;

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
            margin-right: 5px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .sku-details {
        padding: 10px;
    }
}
.content-container-2 {
    padding: 5px;
    width: 100%;
    background: #FCFCFC;
    border: 1px solid #EBEBEB;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;

    .add-btn {
        background: #E0EDF0;
        border: 1px solid var(--app-004986);
        border-radius: 6px;
        color: var(--app-004986);
        padding: 3px 10px;
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .text {
        font-family: 'RedHat-SemiBold' !important;

    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        background-color: var(--app-D9E4ED);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;

        .anticon {
            color: #ffffff !important;
            font-size: 15px !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .content-inner-container {
        margin-top: 10px;

        .expanded-content-container {
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            table {
                th {
                    font-size: 14px;
                    text-align: start;
                    font-family: 'RedHat-Bold';
                }

                td {
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }
            }
        }
    }

    .picker {
        display: flex;
        align-items: center;
        margin-right: 5px;

        .user-profile {
            background: rgba(0, 129, 157, 0.1);
            display: flex;
            align-items: center;
            padding: 4px 10px;
            border-radius: 10px;
            margin-right: 5px;

            .profile-pic {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 5px;

                img {
                    max-width: 100%;
                }
            }
        }

        .reassign {
            background: rgba(0, 129, 157, 0.1);
            color: var(--app-004986);
            padding: 5px 10px;
            border-radius: 10px;
        }
    }

    .assignement-status {
        background: var(--app-EEEEEE);
        color: var(--app-ABABAB);
        padding: 5px 10px;
        border-radius: 5px;
    }

    .open-icon {
        display: flex;
        align-items: center;

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
            margin-right: 5px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .sku-details {
        padding: 10px;
    }
}

.content-container-green {
    padding: 10px;
    width: 100%;
    background: #DEF8EB;
    // border: 1px solid #20CD7A;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;

    .text {
        font-family: 'RedHat-SemiBold' !important;

    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        // border-color: var(--app-004986) !important;
        border: 1px solid var(--app-004986);
        background-color: var(--app-D9E4ED) !important;
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        height: 37px;
        padding: 10px !important;
        .anticon {
            color: #ffffff !important;
            font-size: 15px !important;
        }
        svg {
            color: var(--app-004986) !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986) !important;
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg) !important;
            transition: all 0.25s ease !important;
        }
    }

    .content-inner-container {
        margin-top: 10px;

        .expanded-content-container {
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            table {
                th {
                    font-size: 14px;
                    text-align: start;
                    font-family: 'RedHat-Bold';
                }

                td {
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }
            }
        }
    }
}

.content-container-green-2 {
    padding: 5px;
    width: 100%;
    background: #DEF8EB;
    border: 1px solid #20CD7A;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;

    .text {
        font-family: 'RedHat-SemiBold' !important;

    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        background-color: var(--app-D9E4ED);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;
        .anticon {
            color: #ffffff !important;
            font-size: 15px !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .content-inner-container {
        margin-top: 10px;

        .expanded-content-container {
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            table {
                th {
                    font-size: 14px;
                    text-align: start;
                    font-family: 'RedHat-Bold';
                }

                td {
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }
            }
        }
    }
}

.content-container-red {
    padding: 10px;
    width: 100%;
    background: #FAE2DC;
    border: 1px solid #f34436;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;

    .text {
        font-family: 'RedHat-SemiBold' !important;
    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        border: 1px solid var(--app-004986);
        background-color: var(--app-D9E4ED) !important;
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        height: 37px;
        padding: 10px !important;
        .anticon {
            color: #ffffff !important;
            font-size: 15px !important;
        }
        svg {
            color: var(--app-004986) !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .content-inner-container {
        margin-top: 10px;

        .expanded-content-container {
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            table {
                th {
                    font-size: 14px;
                    text-align: start;
                    font-family: 'RedHat-Bold';
                }

                td {
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }
            }
        }
    }
}

.content-container-gray {
    padding: 10px;
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #EBEBEB;
    box-shadow: 1px 1px 1px #DADADA;
    border-radius: 6px;
    font-family: redhat-regular !important;

    .add-btn {
        background: #E0EDF0;
        border: 1px solid var(--app-004986);
        border-radius: 6px;
        color: var(--app-004986);
        padding: 3px 10px;
        font-family: 'RedHat-SemiBold';
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .text {
        font-family: 'RedHat-SemiBold' !important;

    }

    .release{
        font-family: redhat-semibold;
        text-decoration: underline;
        color: var(--app-004986);
        cursor: pointer;
        font-size: 17px;
    }

    .delete-btn,
    .delete-btn:hover,
    .delete-btn:focus {
        background-color: var(--app-CF4F4F);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;
        .anticon {
            color: white !important;
            font-size: 15px !important;
        }
    }

    .eye-btn,
    .eye-btn:hover,
    .eye-btn:focus {
        // background-color: var(--app-004986);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        padding: 10px !important;

        .anticon {
            color: #ffffff !important;
            font-size: 15px !important;
        }
    }

    .status-btn {
        background: #FFF3E2;
        padding: 2px 10px;
        margin-right: 10px;
        border-radius: 6px;
        font-family: 'RedHat-SemiBold';
        font-size: 14px;
        color: #FEAE3F;
        text-align: left;
        width: 100%;
        border: 1px solid #FEAE3F;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: black;
        }

        .inprogress {
            background: rgba(254, 174, 63, 0.1);
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: var(--app-FEAE3F);
            border: 1px solid var(--app-FEAE3F);
            text-align: left;
            width: 150px;
        }

        .completed {
            background: #a3ee942e;
            padding: 5px;
            margin-right: 10px;
            border-radius: 6px;
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #51cb9a;
            border: 1px solid #51cb9a;
            text-align: left;
            width: 150px;
        }

        .comp-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
            margin-left: 3px;
        }

        .mama-container {
            display: flex;
            font-family: 'RedHat-SemiBold' !important;
            color: var(--app-004986)!important;
            font-size: 14px !important;

            .title {
                white-space: nowrap;
                cursor: pointer;
                text-decoration: none;
                // text-overflow: ellipsis;
                // overflow: hidden;
            }

            .list-num {
                white-space: nowrap;
                cursor: context-menu;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .list-num-2 {
            white-space: nowrap;
            cursor: context-menu;
            text-decoration: none;
            // text-overflow: ellipsis;
            // overflow: hidden;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .content-inner-container {
        margin-top: 10px;

        .expanded-content-container {
            padding: 10px;
            border-radius: 10px;
            background-color: white;

            table {
                th {
                    font-size: 14px;
                    text-align: start;
                    font-family: 'RedHat-Bold';
                }

                td {
                    font-size: 14px;
                    font-family: 'RedHat-Regular';
                }
            }
        }
    }

    .picker {
        display: flex;
        align-items: center;
        margin-right: 5px;

        .user-profile {
            background: rgba(0, 129, 157, 0.1);
            display: flex;
            align-items: center;
            padding: 4px 10px;
            border-radius: 10px;
            margin-right: 5px;

            .profile-pic {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 5px;

                img {
                    max-width: 100%;
                }
            }
        }

        .reassign {
            background: rgba(0, 129, 157, 0.1);
            color: var(--app-004986);
            padding: 5px 10px;
            border-radius: 10px;
        }
    }

    .assignement-status {
        background: var(--app-EEEEEE);
        color: var(--app-ABABAB);
        padding: 5px 10px;
        border-radius: 5px;
    }

    .open-icon {
        display: flex;
        align-items: center;

        .arrow {
            color: var(--app-004986);
            cursor: pointer;
            font-size: 20px;
            margin-right: 5px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }
    }

    .sku-details {
        padding: 10px;
    }
}

.bad-expand{
    background: #FAE2DC !important;
    border: 1px solid #f34436 !important;
    border-radius: 6px !important;
    margin-top: 10px !important; 
    padding: 10px !important;
}

.repair-expand{
    background: #FFF3E2 !important;
    border: 1px solid #FEAE3F !important;
    border-radius: 6px !important;
    margin-top: 10px !important; 
    padding: 10px !important;
}

.green-expand{
    background: #a3ee942e !important;
    border: 1px solid #1BAB66 !important;
    border-radius: 6px !important;
    margin-top: 10px !important; 
    padding: 10px !important;
}

.beforeExpand{
    background: #ffffff !important; 
    padding: 10px !important;
    border-radius: 6px !important;
    margin-top: 10px !important; 
}

.table-view-container {
    .ant-table-thead>tr>th {
        background-color: #505050 !important;
        padding: 5px 15px !important;
        color: #ffffff !important;
        border-top: 1px solid #EBEBEB !important;
        font-family: 'RedHat-SemiBold';
        border-bottom: 1px solid #EBEBEB !important;
        
        // border-bottom: 2px solid var(--app-004986) !important;
    }

    .ant-table-tbody>tr>td {
        padding: 5px 15px;
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-Regular';
    }

    .warning-data {
        background-color: var(--app-F7E4E4);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--app-BB1A1A);
        border-radius: 5px;
        padding: 5px 15px;
        font-family: redhat-semibold;

        .status {
            background: #FFFFFF;
            border-radius: 7px;
            color: var(--app-9D3636);
            padding: 2px 5px;
        }
        .pl-10{
            padding-left: 9px;
        }
        .float{
            right: 0;
            position: absolute;
        }
        }
}
