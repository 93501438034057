@import "../../Globals/ColorVariables.css";
.subheader{
    position: fixed !important;
    z-index: 99 !important;
    // width: 90vw;
    right: 0px !important;
    left: 0px !important;
}

.type-container {
  background: #E3ECF2;
  border-radius: 8px;
  padding: 20px;

  .lable {
      font-size: 16px !important;
      color: var(--app-black) !important;
      font-family: 'RedHat-Bold' !important;
  }

  .content-container {
      margin-top: 10px;
      background-color: #FFFFFF;
      border-radius: 8px;
      padding: 10px;
  }
}
.create-wareHouse-Container {

    background: #ECECEC;
  
    .contract-header {
      padding: 20px;
      background: #ECECEC;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
      font-family: 'RedHat-Bold';
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // position: fixed;
      // left: 100px;
      // width: 100%;
      // z-index: 99;
  
    }
  
   
  
      .header {
        padding: 20px;
        background: rgba(0, 129, 157, 0.15);
        font-family: 'RedHat-Bold';
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // position: fixed;
        // z-index: 999;
  
        .ant-row {
          width: 150px;
        }
  
      }
  
      .content-container {
        // margin-top: 80px;
        height: 81.9vh;
        overflow-y: scroll;
        // overflow-x: hidden;
        // position: fixed;
  
        .ant-radio-button-wrapper {
          height: 100%;
          margin-bottom: 10px;
        }
  
        .ant-radio-button-wrapper {
          background: #F8F8F8 !important;
          color: var(--app-646464);
          font-family: 'RedHat-medium';
          border: none !important;
          width: 100%;
  
          .card-title {
            text-align: center;
            margin-top: 5px;
            font-family: 'RedHat-medium';
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
  
          .label {
            font-family: 'RedHat-Bold';
            color: #2D2D2D;
            font-size: 16px;
          }
  
          .count {
            border-radius: 5px;
            background-color: #FFFFFF !important;
            border: none !important;
            height: 50px;
            width: 60px;
            font-family: 'RedHat-Bold';
            font-size: 17px;
            color: #00819D;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
  
        .ant-radio-button-wrapper-checked {
          background: rgba(254, 174, 63, 0.15) !important;
          color: var(--app-646464);
          font-family: 'RedHat-medium';
  
          .card-title {
            font-family: 'RedHat-medium';
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px !important;
          }
  
          .count {
            text-align: center;
            border-radius: 5px;
            background-color: #FFFFFF !important;
            border: none !important;
            height: 50px;
            width: 60px;
            font-family: 'RedHat-Bold';
            font-size: 17px;
            color: #00819D;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
  
        .ant-radio-button-checked {
          background: rgba(254, 174, 63, 0.15) !important;
          color: var(--app-646464);
          font-family: 'RedHat-medium';
          border-left: 5px solid #FEAE3F !important;
  
          .card-title {
            font-family: 'RedHat-medium';
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px !important;
          }
  
          .count {
            border-radius: 5px;
            background-color: #FFFFFF !important;
            border: none !important;
            height: 50px;
            width: 60px;
            font-family: 'RedHat-Bold';
            font-size: 17px;
            color: #00819D;
            display: flex;
            align-items: center;
            justify-content: center;
          }
  
        }
      }
  
      .content-form-container {
        background: #FFFFFF;
        padding: 20px;
  
        .btn-container {
          display: flex;
          align-items: center;
  
          .close-btn {
            font-size: 16px;
            color: #00819D;
            background-color: #D9ECF0;
            border: 2px solid #00819D;
            font-family: 'RedHat-medium';
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 40px;
  
          }
  
          .green-btn {
            margin: 0px 10px;
            font-size: 16px;
            color: #FFFFFF;
            background-color: #00819D;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 40px;
            font-family: 'RedHat-medium' !important;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
  
        .content-container-form {
          background: #F7F7F7;
          border-radius: 10px;
          padding: 10px;
          padding: 10px 20px;
  
          .label {
            font-size: 14px !important;
            color: var(--app-black) !important;
            font-family: 'RedHat-medium' !important;
            margin-bottom: 10px;
          }
  
          .type {
            background: rgba(0, 129, 157, 0.15);
            border-radius: 5px;
            padding: 5px 10px;
            font-family: 'RedHat-medium' !important;
            width: 300px
          }
  
          .dropdown {
            svg {
              height: 20px !important;
              width: 20px !important;
            }
          }
  
  
          .detail-container {
            background: #FFFFFF;
            border-radius: 5px;
            padding: 5px 20px;
            margin-top: 10px;
          }
  
          .contact-container {
            background: rgba(0, 129, 157, 0.15);
            border-radius: 5px;
            padding: 10px 20px;
            margin-top: 10px;
          }
  
          .ant-checkbox {
            border: 1px solid #00819D;
  
            .ant-checkbox-inner {
              border: none !important;
            }
          }
  
          .ant-checkbox-wrapper {
            font-size: 16px;
            font-family: "RedHat-medium";
            color: black;
          }
  
          .ant-checkbox-checked {
            background-color: #00819D;
  
            .ant-checkbox-inner {
              background-color: #00819D;
              border: none !important;
            }
          }
  
          .ant-row {
            .ant-input {
              height: 40px;
              background: #FFFFFF;
              border: 2px solid #d9d9d9 !important;
              border-radius: 4px;
              font-family: 'RedHat-medium';
            }
  
            .ant-select.ant-select-in-form-item {
              width: 100% !important;
  
            }
  
            .ant-select-selector {
              border: 2px solid #d9d9d9 !important;
              height: 40px !important;
              border-radius: 5px !important;
              align-items: center;
              font-family: 'RedHat-medium';
            }
  
            .ant-select-arrow {
              svg {
                path {
                  fill: black !important
                }
              }
            }
          }
        }
      }
  
      .view-part-container {
        margin: 20px;
        padding: 20px;
        background: linear-gradient(86.22deg, #00819D 0%, #02A5C9 100%);
        border-radius: 8px;
  
        .col-border {
          border-right: 2px solid white;
        }
  
        .client-name {
          font-family: 'RedHat-medium';
          font-size: 20px;
          color: #FFFFFF;
        }
  
        .content {
          margin-top: 15px;
  
          .title {
            font-family: 'RedHat-medium';
            font-size: 15px;
            color: #FFFFFF;
          }
  
          .value {
            font-family: 'RedHat-regular';
            font-size: 15px;
            color: #FFFFFF;
            margin-top: 5px;
          }
  
          .history-text {
            font-family: 'RedHat-medium';
            font-size: 18px;
            color: #FEAE3F !important;
            margin-top: 5px;
          }
        }
  
      }
  
  
      .content-view-container {
        height: 100%;
        background: #F8F8F8;
  
        .Client-title-header {
          background: #DEF4FC;
          box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
          font-family: 'RedHat-Bold';
          font-size: 17px;
          color: #2D2D2D;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: fixed;
          z-index: 99;
          left: 100px;
          right: 0px;
          top: 50px !important;
  
          .ant-row {
            width: 100%;
            display: flex;
            align-items: center;
          }
  
          .create-btn {
            font-size: 18px !important;
            color: #FFFFFF;
            background-color: #00819D;
            font-family: 'RedHat-Bold';
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 45px;
            padding: 0px 20px !important;
  
  
            svg {
              font-size: 20px !important;
              margin-right: 5px;
              height: 20px;
              width: 35px;
            }
          }
  
          .btn-container {
            display: flex;
            align-items: center;
  
            .close-btn {
              margin: 0px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              height: 45px;
              padding: 10px;
              color: #DD3E13 !important;
              background: #FAE2DC !important;
              border: 1px solid #DD3E13 !important;
  
              svg {
                height: 20px;
                width: 20px;
              }
            }
  
  
            .ant-input-affix-wrapper {
              width: 400px !important;
              border: 2px solid #d9d9d9 !important;
              height: 45px !important;
              border-radius: 5px !important;
              font-family: 'RedHat-medium';
            }
  
            .close-btn {
              font-size: 16px;
              color: #00819D;
              background-color: #D9ECF0;
              border: 2px solid #00819D;
              font-family: 'RedHat-Regular';
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              height: 40px;
  
            }
  
            .green-btn {
              margin: 0px 10px;
              font-size: 25px;
              color: #FFFFFF;
              background-color: #00819D;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              height: 45px;
              padding: 10px;
  
              svg {
                height: 20px;
                width: 20px;
              }
            }
  
  
          }
        }
  
        .title-header-bill {
          background: #DEF4FC;
          box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
          font-family: 'RedHat-medium';
          font-size: 20px;
          color: #2D2D2D;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          position: fixed;
          width: 100%;
          z-index: 99;
        }
  
  
  
        .view-content-design {
          width: 80%;
          position: absolute;
          top: 120%;
          left: 54%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          padding-bottom: 60px;
        }
  
  
      }
  
      // .title-header {
      //   background: #DEF4FC;
      //   box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
      //   font-family: 'RedHat-Bold';
      //   font-size: 17px;
      //   color: #2D2D2D;
      //   // display: flex;
      //   // align-items: center;
      //   // justify-content: space-between;
      //   position: fixed;
      //   width: 100%;
      //   z-index: 99;
  
      //   .ant-row {
      //     width: 62%;
      //     display: flex;
      //     align-items: center;
      //   }
  
      //   .create-btn {
      //     font-size: 18px !important;
      //     color: #FFFFFF;
      //     background-color: #00819D;
      //     font-family: 'RedHat-Bold';
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     border-radius: 5px;
      //     height: 45px;
      //     padding: 0px 20px !important;
  
  
      //     svg {
      //       font-size: 20px !important;
      //       margin-right: 5px;
      //       height: 20px;
      //       width: 35px;
      //     }
      //   }
  
      //   .btn-container {
      //     display: flex;
      //     align-items: center;
  
      //     .close-btn {
      //       margin: 0px 10px;
      //       display: flex;
      //       align-items: center;
      //       justify-content: center;
      //       border-radius: 5px;
      //       height: 45px;
      //       padding: 10px;
      //       color: #DD3E13 !important;
      //       background: #FAE2DC !important;
      //       border: 1px solid #DD3E13 !important;
  
      //       svg {
      //         height: 20px;
      //         width: 20px;
      //       }
      //     }
  
  
      //     .ant-input-affix-wrapper {
      //       width: 400px !important;
      //       border: 2px solid #d9d9d9 !important;
      //       height: 45px !important;
      //       border-radius: 5px !important;
      //       font-family: 'RedHat-medium';
      //     }
  
      //     .close-btn {
      //       font-size: 16px;
      //       color: #00819D;
      //       background-color: #D9ECF0;
      //       border: 2px solid #00819D;
      //       font-family: 'RedHat-Regular';
      //       display: flex;
      //       justify-content: center;
      //       align-items: center;
      //       border-radius: 5px;
      //       height: 40px;
  
      //     }
  
      //     .green-btn {
      //       margin: 0px 10px;
      //       font-size: 25px;
      //       color: #FFFFFF;
      //       background-color: #00819D;
      //       display: flex;
      //       align-items: center;
      //       justify-content: center;
      //       border-radius: 5px;
      //       height: 45px;
      //       padding: 10px;
  
      //       svg {
      //         height: 20px;
      //         width: 20px;
      //       }
      //     }
  
  
      //   }
  
      // }
  
      .view-content {
        padding: 10px 10px 10px 10px !important;
        height: 80vh;
        overflow-y: scroll;
  
        .content-box-design {
          border: 2px solid #CECECE;
          box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
          margin-bottom: 20px !important;
        }
  
        .content-box {
          box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
        }
  
  
        .content-box {
          padding: 10px;
          background-color: #FFFFFF;
          border-radius: 5px;
          margin-bottom: 10px;
          width: 100%;
  
          .other-warehouse-col {
            border-left: 2px solid #A5A5A5;
            padding-left: 20px !important;
          }
  
          .content-title {
            font-family: 'RedHat-medium';
            font-size: 14px;
            color: #2D2D2D;
          }
  
          .content {
            font-family: 'RedHat-medium';
            font-size: 14px;
            color: #2D2D2D;
          }
  
          .view-industry {
            background: rgba(0, 129, 157, 0.15);
            border-radius: 10px;
            padding: 5px 10px;
            font-family: 'RedHat-medium';
            font-size: 16px;
  
            svg {
              path {
                fill: #00819D !important
              }
            }
          }
  
          .detailed-view {
            background: #F1F1F1;
            border-radius: 10px;
            padding: 10px;
  
            .role-container {
              background: rgba(0, 129, 157, 0.15);
              border-radius: 10px;
              padding: 5px 10px;
  
              .name {
                font-family: 'RedHat-medium';
                font-size: 16px;
  
                span {
                  font-family: 'RedHat-medium';
                  font-size: 16px;
                  margin-left: 5px;
                }
              }
  
              .role {
                font-family: 'RedHat-medium';
                font-size: 16px;
              }
  
              .dropdown {
                svg {
                  height: 25px !important;
                  width: 25px !important;
                }
              }
            }
  
            .contact-box {
              padding: 10px;
  
              svg {
                height: 16px;
                width: 16px;
              }
  
              span {
                margin-left: 10px;
                font-family: 'RedHat-medium';
                font-size: 16px;
  
              }
            }
          }
  
          .warehouse-container {
            padding: 5px 10px;
            background: rgba(45, 45, 45, 0.1);
            border-radius: 5px;
            font-family: 'RedHat-medium';
            font-size: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            .select {
              color: #00819D;
              font-size: 16px;
              text-decoration: underline;
            }
  
            .create-btn {
              font-size: 16px;
              color: #FFFFFF;
              background-color: #00819D;
              font-family: 'RedHat-Bold';
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              height: 25px;
            }
  
          }
  
          .warehouse-create-container {
            padding: 10px;
            background: rgba(45, 45, 45, 0.1);
            border-radius: 5px;
            font-family: 'RedHat-medium';
            font-size: 17px;
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            .view-payment {
              margin-right: 10px;
  
              svg {
                margin-right: 5px;
                width: 20px;
                height: 20px;
  
                path {
                  fill: #00819D !important;
                }
              }
  
              .label {
                font-size: 14px;
                color: #00819D;
                font-family: 'RedHat-medium';
              }
            }
  
            .create-btn {
              font-size: 16px;
              color: #FFFFFF;
              background-color: #00819D;
              font-family: 'RedHat-medium';
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              height: 30px;
            }
  
            .follow-btn {
              font-size: 16px;
              background: #C7E3E9;
              border: 1px solid #00819D;
              color: #00819D;
              font-family: 'RedHat-medium';
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              height: 40px !important;
            }
  
          }
  
          .ant-row {
            padding: 10px 20px;
  
            .content {
              .title {
                font-family: 'RedHat-medium';
                font-size: 15px;
              }
  
              .value {
                font-family: 'RedHat-regular';
                font-size: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
  
                .history-text {
                  color: #00819D;
                  text-decoration: underline;
                }
              }
            }
          }
  
          .contract-container {
            margin-top: 10px;
            margin-bottom: 10px;
            background: rgba(18, 130, 159, 0.15);
            border-radius: 5px;
            padding: 5px 10px;
            align-items: center;
  
            .title {
              font-family: "RedHat-medium";
              font-size: 15px;
            }
  
            .value {
              font-family: 'RedHat-regular';
              font-size: 15px;
  
              span {
                font-family: "RedHat-medium";
              }
            }
  
          }
  
          .history-text {
            color: #00819D;
            font-family: 'RedHat-medium';
            font-size: 18px;
          }
  
          .approved-status {
            margin-top: 10px;
            margin-bottom: 10px;
            background: rgba(32, 205, 122, 0.15);
            border-radius: 5px;
            padding: 10px;
            font-family: 'RedHat-medium';
            font-size: 16px;
            display: flex;
            align-items: center;
  
            span {
              font-family: 'RedHat-medium';
              font-size: 18px;
              margin-left: 5px;
            }
          }
  
        }
      }
  
    .radio-container {
      // text-align: center;
      // position: fixed;
      padding: 10px;
      // margin-top: 70px;
      height: 81.9vh;
      overflow: scroll;
      // max-width: 215px;
      // margin-left: 5px;
  
      .ant-radio-button-wrapper {
        height: 100%;
        margin-bottom: 20px;
        border-radius: 10px !important;
        box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);
      }
  
      .ant-radio-button {
        box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05) !important;
      }
  
      .ant-radio-button-wrapper {
        background-color: #FFFFFF !important;
        border: 2px solid #E6E9EE !important;
        box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);
        color: var(--app-646464);
        font-family: 'RedHat-medium';
        border-radius: 10px !important;
        width: 100%;
  
        .card-title {
          text-align: center;
          margin-top: 5px;
          font-family: 'RedHat-medium';
          font-size: 16px;
          display: flex;
          align-items: center;
  
          svg {
  
            // height: 20px !important;
            // width: 25px !important;
            path {
              fill: #00819D !important;
            }
          }
        }
  
        .bill-count {
          border-radius: 5px;
          background: #FFFFFF;
          border: 1px solid #00819D;
          height: 35px;
          margin-bottom: 10px;
          padding: 5px;
          font-family: 'RedHat-Bold' !important;
          font-size: 16px !important;
          color: #00819D !important;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
  
        .count {
          border-radius: 5px;
          background-color: #00819D !important;
          border: 1px solid #E6E9EE !important;
          height: 35px;
          margin-bottom: 10px;
          padding: 5px;
          font-family: 'RedHat-Bold' !important;
          font-size: 16px !important;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
  
  
  
      .ant-radio-button-wrapper:hover {
        background-color: #FFFFFF !important;
        border: 2px solid #E6E9EE !important;
        box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);
        color: var(--app-646464);
        font-family: 'RedHat-medium';
        border-radius: 10px !important;
        width: 100%;
  
  
      }
  
      .ant-radio-button-wrapper-checked {
        border-radius: 10px !important;
        background-color: #00819D !important;
        color: #FFFFFF !important;
        font-family: 'RedHat-medium';
  
        .card-title {
          margin-top: 5px;
          font-family: 'RedHat-medium';
          font-size: 16px;
          display: flex;
          align-items: center;
  
          svg {
  
            // height: 20px !important;
            // width: 25px !important;
            path {
              fill: #FFFFFF !important;
            }
          }
        }
  
        .count {
          border-radius: 5px;
          background-color: #34b5b13b !important;
          color: #FFFFFF !important;
          border: none !important;
          height: 35px;
          padding: 5px;
          font-family: 'RedHat-Bold' !important;
          font-size: 16px !important;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
  
      .ant-radio-button-checked {
        border-radius: 10px !important;
        background-color: #00819D !important;
        color: #FFFFFF !important;
        font-family: 'RedHat-medium';
  
        .count {
          border-radius: 5px;
          background-color: #34b5b13b !important;
          color: #FFFFFF !important;
          border: none !important;
          height: 35px;
          padding: 5px;
          font-family: 'RedHat-Bold' !important;
          font-size: 16px !important;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
  
      }
  
  
  
  
    }
  
  }
.header-view{
    font-size: 16px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 20px;
    .client-view{
        background: #7FDAE1;
        padding: 5px 20px;
        // margin: 10px;
        width: 100%;
        border-radius: 10px;
        font-family: redhat-bold !important;
    }
    
    .status{
        background: #274248;
        color: white;
        font-family: redhat-semibold !important;
        border-radius: 10px;
        padding: 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .select{
            border-radius: 5px !important;
            border: 1px solid white;
            height: 30px !important;
            width: 100%;
            .ant-select-selector {
                // border: 2px solid #d9d9d9 !important;
                height: 30px !important;
                border-radius: 5px !important;
                border: 1px solid white;
            }
        }
    }
    .close-btn-3, .close-btn-3:hover {
        margin-left: 15px;
        font-size: 20px;
        color: var(--app-f34436);
        background-color: #FAE2DC;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        height: 37px;
        border: 1px solid var(--app-f34436);
    }
}
.on-borading-menu{
    background: var( --app-E3EBF2) !important;
    // width: 10rem;
    height: 100vh;
    font-family: redhat-semibold !important;
    font-size: 16px !important;
    padding: 20px 10px !important;
    position: absolute;
    width: 100%;
    .ant-menu-item {
        // color: white !important;
        svg{
            fill: var(--app-565656) !important;
            path{
                fill: var(--app-565656) !important;
            }
        }
    }
    .ant-menu-item-selected{
        background: var( --app-E3EBF2) !important;
        color: var(--app-1C6FB5) !important;
        svg{
            path{
                fill: var(--app-1C6FB5) !important;
            }
        }
    }
    .ant-menu-item:hover{
        color: var(--app-1C6FB5) !important;
        svg{
            path{
                fill: var(--app-1C6FB5) !important;
            }
        }
    }
}
.content-view{
    .category-listing-screen{
        .header{
            display: flex;
            align-items: center;
            background: var(--app-F4F4F4);
            border-radius: 5px;
            padding: 10px;
            font-family: redhat-bold;
            font-size: 16px;
            margin: 10px 0px !important;
        }
    }
}
.Onboarding-dashboard-screen{
    border-right: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    height: 92vh;
    overflow: scroll;
    padding-bottom: 15px;
    .client-view:hover{
        font-family: redhat-bold;
        font-family: 18px !important;
        background: rgba(254, 174, 63, 0.15);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-left: 5px solid #FEAE3F;
        cursor: pointer;
    }  
    // .client-view:first-child{
    //     font-family: redhat-bold;
    //     font-family: 18px;
    //     background: rgba(254, 174, 63, 0.15);
    //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    //     padding: 10px;
    //     border-left: 5px solid #FEAE3F;
    //     cursor: pointer;
    // }  
    .client-view{
        font-family: redhat-bold;
        font-family: 18px;
        padding: 10px;
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }  
    .warehouse-version{
        background: rgba(45, 45, 45, 0.1);
        border-radius: 5px;
        font-family: redhat-bold;
        font-size: 16px;
        margin: 10px 10px 0 5px;
        padding: 10px;
        cursor: pointer;
    }

    .last-live-view{
        background: white;
        border-radius: 10px;
        font-family: redhat-bold;
        font-size: 16px;
        margin: 10px 10px 0 5px;
        padding: 10px;
        box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
        .warehouse-location{
            background: rgba(45, 45, 45, 0.1);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 10px;
        }
        .status{
            background: rgba(32, 205, 122, 0.15);
            border-radius: 5px;
            padding: 10px;
            font-size: 16px;
            font-family: redhat-regular;
        }
    }
}
.category-listing-screen{
    
}
  
.preview-attribute{
    background: var(--app-E9E9E9);
    font-family: redhat-regular;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin: 3px;
    .cross{
        cursor: default;
        margin: 0 0 2px 5px;
        color: red;
    }
}

.attachment-title {
    font-size: 16px;
    color: var(--app-black);
    font-family: 'RedHat-Bold';
    display: flex;
    align-items: center;

    .ant-btn {
        width: 20px !important;
        height: 20px !important;
        padding: 0px !important;
        justify-content: center;
        align-items: center;
    }

    .add-btn {
        color: var(--app-FFFFFF);
        background-color: var(--app-004986);
        font-size: 14px;
        border: none !important;
        margin-left: 10px;
        border-radius: 5px;
    }
    .add-btn:focus{
        color: var(--app-FFFFFF);
        background-color: var(--app-004986);
        font-size: 14px;
        border: none !important;
        margin-left: 10px;
        border-radius: 5px;
    }

}

.attachment-title-1 {
    font-size: 14px;
    color: var(--app-black);
    font-family: 'RedHat-Bold';
    display: flex;
    align-items: center;

    .ant-btn {
        width: 20px !important;
        height: 20px !important;
        padding: 0px !important;
        justify-content: center;
        align-items: center;
    }

    .add-btn {
        color: var(--app-FFFFFF);
        background-color: var(--app-004986);
        font-size: 14px;
        border: none !important;
        margin-left: 10px;
        border-radius: 5px;
    }

    .add-btn:hover{
        color: var(--app-FFFFFF);
        background-color: var(--app-004986);
        font-size: 14px;
        border: none !important;
        margin-left: 10px;
        border-radius: 5px;
    }

}


.img-Link{
   color: var(--app-004986); 
   text-decoration: underline;
}