@import "../../Globals/ColorVariables.css";

// .associatepo-container1 {
//     margin: 20px;
//     background: rgba(32, 205, 122, 0.25);
//     border-radius: 8px;

//     .purchase-order-bar {
//         display: flex;
//         border-radius: 8px;
//         background: rgba(0, 0, 0, 0.1);
//         padding: 8px;
//         padding-left: 20px;

//         .purchse-order {
//             font-size: 16px;
//             color: var(--app-black) !important;
//             font-family: 'RedHat-Bold' !important;
//             margin-right: 100px;
//         }

//         span {
//             font-size: 16px;
//             color: var(--app-black) !important;
//             font-family: 'RedHat-Medium' !important;
//         }

//         .terms-condtns {
//             font-size: 14px;
//             font-weight: 500;
//         }
//     }

//     .purchase-categories {
//         padding: 7px;
//         border-radius: 8px;
//         background: #022E3A;
//         margin: 10px;
//         font-size: 16px;
//         color: white !important;
//         font-family: 'RedHat-SemiBold' !important;
//     }

//     .purchase-datas {
//         padding: 10px;
//         border-radius: 8px;
//         background: #FFFFFF;
//         border-bottom: 1px solid #E0E0E0;
//         margin: 10px;
//         font-size: 14px;
//         color: var(--app-black) !important;
//         font-family: 'RedHat-Regular' !important;
//     }

//     .ant-checkbox {
//         border: 1px solid var(--app-004986);

//         .ant-checkbox-inner {
//             border: none !important;
//         }
//     }

//     .ant-checkbox-wrapper {
//         font-size: 16px;
//         font-family: "RedHat-Bold";
//         color: black;
//     }

//     .ant-checkbox-checked {
//         background-color: var(--app-004986);

//         .ant-checkbox-inner {
//             background-color: var(--app-004986);
//             border: none !important;
//         }
//     }
// }

.associatepo-container {
    margin: 20px;
    background-color: #F8F8FA;
    border-radius: 8px;

    .purchase-order-bar {
        display: flex;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.1);
        padding: 8px;
        padding-left: 20px;

        .ant-row {
            width: 100%;
        }

        .purchse-order {
            font-size: 16px;
            color: var(--app-black) !important;
            font-family: 'RedHat-Bold' !important;
            margin-right: 100px;
        }

        span {
            font-size: 16px;
            color: var(--app-black) !important;
            font-family: 'RedHat-Medium' !important;
        }

        .terms-condtns {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .purchase-categories {
        padding: 7px;
        border-radius: 8px;
        background: #022E3A;
        margin: 10px;
        font-size: 16px;
        color: white !important;
        font-family: 'RedHat-SemiBold' !important;
    }

    .purchase-datas {
        padding: 10px;
        border-radius: 8px;
        background: #FFFFFF;
        border-bottom: 1px solid #E0E0E0;
        margin: 10px;
        font-size: 14px;
        color: var(--app-black) !important;
        font-family: 'RedHat-Regular' !important;
    }

    .ant-checkbox {
        border: 1px solid var(--app-004986);

        .ant-checkbox-inner {
            border: none !important;
        }
    }

    .ant-checkbox-wrapper {
        font-size: 16px;
        font-family: "RedHat-Bold";
        color: black;
    }

    .ant-checkbox-checked {
        background-color: var(--app-004986);

        .ant-checkbox-inner {
            background-color: var(--app-004986);
            border: none !important;
        }
    }

    .send-btn {
        display: flex;
        justify-content: flex-end;
        padding: 15px 0px;

        .send {
            background: var(--app-004986);
            font-size: 18px;
            color: white;
            border: 1px solid var(--app-004986);
            border-radius: 5px;
            padding: 5px 20px;

            a {
                color: white;
            }

        }
    }

}

.associatepo-container1 {
    margin: 20px;
    background-color: rgba(32, 205, 122, 0.25);
    border-radius: 8px;

    .purchase-order-bar {
        display: flex;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.1);
        padding: 8px;
        padding-left: 20px;

        .ant-row {
            width: 100%;
        }

        .purchse-order {
            font-size: 16px;
            color: var(--app-black) !important;
            font-family: 'RedHat-Bold' !important;
            margin-right: 100px;
        }

        span {
            font-size: 16px;
            color: var(--app-black) !important;
            font-family: 'RedHat-Medium' !important;
        }

        .terms-condtns {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .purchase-categories {
        padding: 7px;
        border-radius: 8px;
        background: #022E3A;
        margin: 10px;
        font-size: 16px;
        color: white !important;
        font-family: 'RedHat-SemiBold' !important;
    }

    .purchase-datas {
        padding: 10px;
        border-radius: 8px;
        background: #FFFFFF;
        border-bottom: 1px solid #E0E0E0;
        margin: 10px;
        font-size: 14px;
        color: var(--app-black) !important;
        font-family: 'RedHat-Regular' !important;
    }

    .ant-checkbox {
        border: 1px solid var(--app-004986);

        .ant-checkbox-inner {
            border: none !important;
        }
    }

    .ant-checkbox-wrapper {
        font-size: 16px;
        font-family: "RedHat-Bold";
        color: black;
    }

    .ant-checkbox-checked {
        background-color: var(--app-004986);

        .ant-checkbox-inner {
            background-color: var(--app-004986);
            border: none !important;
        }
    }

    .send-btn {
        display: flex;
        justify-content: flex-end;
        padding: 15px 0px;

        .send {
            background: var(--app-004986);
            font-size: 18px;
            color: white;
            border: 1px solid var(--app-004986);
            border-radius: 5px;
            padding: 5px 20px;

            a {
                color: white;
            }

        }
    }

}

.main-container {
    padding: 0px !important;

    .box-bar {
        padding: 10px;

        .bar-listing-container {
            padding: 10px;
            background-color: #F8F8F8;
            border-radius: 5px;
            margin: 10px;
            height: 100vh !important;
            overflow-y: scroll;

            .ant-input {
                border: 2px solid #d9d9d9;
            }

            .radio-container {
                padding: 5px;


                .ant-radio-group {
                    width: 100%;
                }

                .ant-radio-button-wrapper {
                    height: 90px;
                    margin-top: 10px;
                }

                .ant-radio-button-wrapper {
                    background-color: #FFFFFF !important;
                    border: 2px solid #E6E9EE !important;
                    color: var(--app-646464);
                    font-family: 'RedHat-Regular';
                    width: 100%;

                    .emiza-stickering-count {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;

                        .emiza-logo {
                            img {
                                width: 60px !important;
                            }
                        }

                        .count {
                            font-size: 18px;
                            font-family: "RedHat-Bold";
                            color: black;
                        }

                    }

                    .ant-radio-button-checked {
                        border: 2px solid var(--app-004986)!important;
                        background-color: #FFFFFF !important;
                        font-family: 'RedHat-Regular';

                        .emiza-stickering-count {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 10px;

                            .emiza-logo {
                                img {
                                    width: 60px !important;
                                }
                            }

                            .count {
                                font-size: 18px;
                                font-family: "RedHat-Bold";
                                color: black;
                            }

                        }
                    }

                    .ant-radio-button-wrapper-checked {
                        border: 2px solid var(--app-004986)!important;
                        background-color: #FFFFFF !important;
                        font-family: 'RedHat-Regular';

                        .emiza-stickering-count {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 10px;

                            .emiza-logo {
                                img {
                                    width: 60px !important;
                                }
                            }

                            .count {
                                font-size: 18px;
                                font-family: "RedHat-Bold";
                            }

                        }
                    }

                    .bar-code-img {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 20px;
                        padding: 10px;
                    }
                }


            }

        }

        .detail-QC-cndtns {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 500;
            color: var(--app-004986);
            text-decoration: underline;
        }

        .line {
            margin-top: 20px;

            hr {
                background: #5B5B5B !important;
            }
        }

        .details-view {

            .ant-row {
                background-color: rgba(0, 0, 0, 0.1);
                padding: 0px;
                border-radius: 8px !important;
                align-items: center;
                font-size: 14px;
                font-weight: 500;

                .box-row {
                    position: relative;
                    left: 50px;
                }

                .repairable {
                    position: relative;
                    left: 60px;
                }
            }

            .box-num {
                background: rgba(0, 0, 0, 0.4);
                padding: 10px;
                border-radius: 8px;
                color: white;
                position: relative;
                right: 12px;
                width: 75px !important;

            }
        }

        .details-inputView {

            .ant-row {

                padding: 0px;
                border-radius: 8px !important;
                align-items: center;
                font-size: 14px;
                font-weight: 500;

                .box-input {
                    display: flex;
                    align-items: center;

                    .s-no {
                        background: #FEAE3F;
                        padding: 2px 2px 2px 8px;
                        border-radius: 28px;
                        width: 26px;
                        position: relative;
                        right: 10px;
                        color: white;

                    }

                    .s-data {

                        margin-right: 90px !important;
                    }
                }


            }

            .box-row {
                .ant-input {
                    margin: 1px;
                    width: 65px;
                    position: relative;
                    left: 53px;
                    border: 2px solid #d9d9d9 !important;
                    text-align: center;
                }
            }

            .box-row1 {
                .ant-input {
                    margin: 1px !important;
                    width: 65px !important;
                    position: relative !important;
                    left: 60px !important;
                    border: 2px solid #d9d9d9 !important;
                    text-align: center;
                }
            }
        }

        .canned-cmtd {
            padding: 15px 10px 10px 10px;
            background: rgba(169, 169, 169, 0.1);
            border-radius: 8px;
            margin-top: 15px;

            .c-headers {
                display: flex;
                position: relative;
                bottom: 10px;
                font-size: 14px;
                font-weight: 500;

                .cmts {
                    position: relative;
                    left: 95px;
                }
            }

            .ant-select-selector {
                border: 2px solid #d9d9d9 !important;
            }

            .ant-input {
                border: 2px solid #d9d9d9 !important;
            }
        }

        .img-videoContainer {
            margin-top: 10px;
            padding: 15px;
            background: rgba(169, 169, 169, 0.1);
            border-radius: 8px;
            position: relative;

            .ant-btn {
                border-radius: 50%;
                width: 20px !important;
                height: 20px !important;
                padding: 0px !important;
                justify-content: center;
                align-items: center;
            }

            .close-btn {
                position: absolute;
                top: 20px;
                right: 0;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                color: #FFFFFF;
                background: #DD3E13;
                font-size: 11px;
            }

            .notes {
                font-size: 15px !important;
                font-weight: 500;

                .notes-content {
                    padding: 5px;
                }
            }

            .img-header {
                font-size: 16px;
                font-weight: 500;
                margin-top: 15px;
            }

            .card-container {

                .cards {
                    display: flex !important;
                    margin-top: 20px;

                }

                .screenshot-img-container {
                    height: 70px;
                    width: 100px;
                    margin-right: 10px;

                    img {
                        max-width: 100%;
                    }
                }
            }

            .vdo-header {
                font-size: 16px;
                font-weight: 500;
                margin-top: 15px;
            }

            .vdo-container {
                margin-top: 10px;

                .vdo {
                    width: 100%;
                    position: relative;
                }

                .ant-btn {
                    border-radius: 50%;
                    width: 30px !important;
                    height: 30px !important;
                    padding: 0px !important;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }

                .vdo-btn {
                    position: absolute;
                    top: 50%;
                    left: 15%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    font-size: 20px;
                }
            }
        }

        .van-buttons {
            display: flex;
            justify-content: flex-end;
            padding: 15px 0px;

            .cancel {
                background: var(--app-004986);
                border: 1px solid var(--app-004986);
                border-radius: 5px;
                color: var(--app-FFFFFF);
                font-size: 18px;
                margin: 0px 15px 0px 0px;
                padding: 5px 20px;
            }
        }
    }

}

.qc-invoice-details-screen-container {
    background-color: var(--app-FFFFFF);


    .ant-table {
        background: none !important;
    }

    .ant-table-thead>tr>th {
        background-color: var(--app-rgba0-90-109-01) !important;
        padding: 5px 15px !important;
        border-bottom: 0px !important;
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;
    }

    .ant-table-tbody>tr>td {
        border-bottom: 0px !important;
        padding: 6px 10px;
    }

    .ant-table-container table>thead>tr:first-child th:first-child {
        border-top-left-radius: 10px !important;

        border-bottom-left-radius: 10px !important;
    }

    .ant-table-container table>thead>tr:first-child th:last-child {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }


    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-Bold';
        border-right: 0px !important;
    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
        border-right: 0px !important;
    }

    .ant-table.ant-table-bordered>.ant-table-container {
        border-left: 0px !important;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td,
    .ant-table-tbody>tr>td.ant-table-cell-row-hover {
        background: none !important;
    }

    .ant-table-pagination.ant-pagination {
        display: none !important;
    }

    .ant-select {
        width: 97%;
    }

    // .ant-select:focus,.ant-select:hover{
    //     border: 1px solid var(--app-C7C7C7) !important;
    // }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 2px solid #d9d9d9 !important;
        border-radius: 5px !important;
        align-items: center;
        border-radius: 2px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 35px;

    }

    .ant-select-selector:hover {
        border: 2px solid #d9d9d9 !important;
    }

    .ant-select-arrow {
        color: var(--app-black) !important;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
    }

    .ant-input {
        border: 2px solid var(--app-C7C7C7);
        border-radius: 5px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 35px;
    }

    .ant-table-cell-row-hover {
        .ant-input:hover {
            border: 2px solid var(--app-C7C7C7);
            border-radius: 5px;
            font-size: 14px;
            color: #232323;
            font-family: 'RedHat-Regular';
            height: 35px;
        }
    }

    .ant-input:hover,
    .ant-input:focus {
        border: 2px solid var(--app-C7C7C7);
        border-radius: 2px;
        font-size: 14px;
        color: #232323;
        font-family: 'RedHat-Regular';
        height: 30px;
    }

    .action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-btn>.anticon {
            line-height: 0px !important;
        }

        .ant-btn {
            border-radius: 50%;
            width: 30px !important;
            height: 30px !important;
            padding: 0px !important;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .Add-btn {
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            font-size: 15px;

        }

        .delete-btn {
            color: var(--app-FFFFFF);
            background: #DD3E13;
            font-size: 15px;
        }
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
    }
}

.start-recieving-container {
    .ant-table {
        background: none !important;
    }

    .ant-table-thead>tr>th {
        background-color: var(--app-C7C7C7) !important;
        padding: 10px 15px !important;
        // border-bottom: 10px solid #f0f0f0;

    }

    .ant-table-tbody>tr>td {
        border-bottom: 10px solid #f0f0f0;
        padding: 10px 15px;
    }

    // .ant-table-tbody > tr:first-child td:first-child{
    //     border-top-left-radius: 10px !important;

    //     border-bottom-left-radius: 10px !important;
    // }
    // .ant-table-tbody > tr:first-child  td:last-child{
    //     border-top-right-radius: 10px !important;
    //     border-bottom-right-radius: 10px !important;

    // }

    // .ant-table-container table>thead>tr:first-child th:first-child {
    //     border-top-left-radius: 10px !important;

    //     border-bottom-left-radius: 10px !important;
    // }

    // .ant-table-container table>thead>tr:first-child th:last-child {
    //     border-top-right-radius: 10px !important;
    //     border-bottom-right-radius: 10px !important;
    // }


    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-SemiBold';
        border-right: 0px !important;
    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
        border-right: 0px !important;
        background-color: var(--app-FFFFFF) !important;
        font-size: 14px;
        font-family: 'RedHat-Regular';
    }

    .ant-table.ant-table-bordered>.ant-table-container {
        border-left: 0px !important;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td,
    .ant-table-tbody>tr>td.ant-table-cell-row-hover {
        background: none !important;
    }

    .ant-table-pagination.ant-pagination {
        display: none !important;
    }
}

.start-recieving-view-container {
    width: 100%;
    padding: 10px;

    .ant-row{
        width: 100%;
    }

    .bar-listing-container {
        padding: 10px;
        background-color: #F8F8F8;
        border-radius: 5px;
        margin: 0px 10px 10px 10px;
        height: 90vh;
        overflow-y: scroll;

        .ant-input {
            border: 2px solid #d9d9d9;
        }

        .radio-container {

            .ant-radio-group {
                width: 100%;
            }

            .ant-radio-button-wrapper {
                height: 90px;
                margin-top: 10px;
            }

            .ant-radio-button-wrapper {
                background-color: #FFFFFF !important;
                border: 2px solid #E6E9EE !important;
                color: var(--app-646464);
                font-family: 'RedHat-Regular';
                width: 100%;

                .emiza-stickering-count {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;

                    .emiza-logo {
                        img {
                            width: 60px !important;
                        }
                    }

                    .count {
                        font-size: 18px;
                        font-family: "RedHat-Bold";
                        color: black;
                    }

                }

                .ant-radio-button-checked {
                    border: 2px solid var(--app-004986)!important;
                    font-family: 'RedHat-Regular';

                    .emiza-stickering-count {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;

                        .emiza-logo {
                            img {
                                width: 60px !important;
                            }
                        }

                        .count {
                            font-size: 18px;
                            font-family: "RedHat-Bold";
                            color: black;
                        }

                    }
                }

                .ant-radio-button-wrapper-checked {
                    border: 2px solid var(--app-004986)!important;
                    background-color: #FFFFFF !important;
                    font-family: 'RedHat-Regular';

                    .emiza-stickering-count {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;

                        .emiza-logo {
                            img {
                                width: 60px !important;
                            }
                        }

                        .count {
                            font-size: 18px;
                            font-family: "RedHat-Bold";
                        }

                    }
                }

                .bar-code-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        max-width: 100%;
                    }
                }
            }

            .barcode-button {
                border: 2px solid #d9d9d9;
                margin-top: 10px;
                cursor: pointer;
                background-color: white;
                padding: 5px;

               
            }

            .barcode-button-1 {
                border: 2px solid var(--app-004986);
                margin-top: 10px;
                cursor: pointer;
                background-color: #E5F2F5;
                padding: 5px;

               
            }

            .selected {
                // position: relative;
                border: 3px solid #46C76A;
                box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
            }



            .select-icon {
                font-size: 24px;
                // height: 24px;
                background: white;
                color: #46C76A;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                top: -7px;
                right: -7px;
                position: absolute;
            }
        }

    }
    .bar-listing-container-1 {
        padding: 10px;
        background-color: #F8F8F8;
        border-radius: 5px;
        margin: 0px 10px 10px 10px;

        .ant-input {
            border: 2px solid #d9d9d9;
        }

        .radio-container {

            .ant-radio-group {
                width: 100%;
            }

            .ant-radio-button-wrapper {
                height: 90px;
                margin-top: 10px;
            }

            .ant-radio-button-wrapper {
                background-color: #FFFFFF !important;
                border: 2px solid #E6E9EE !important;
                color: var(--app-646464);
                font-family: 'RedHat-Regular';
                width: 100%;

                .emiza-stickering-count {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;

                    .emiza-logo {
                        img {
                            width: 60px !important;
                        }
                    }

                    .count {
                        font-size: 18px;
                        font-family: "RedHat-Bold";
                        color: black;
                    }

                }

                .ant-radio-button-checked {
                    border: 2px solid var(--app-004986)!important;
                    font-family: 'RedHat-Regular';

                    .emiza-stickering-count {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;

                        .emiza-logo {
                            img {
                                width: 60px !important;
                            }
                        }

                        .count {
                            font-size: 18px;
                            font-family: "RedHat-Bold";
                            color: black;
                        }

                    }
                }

                .ant-radio-button-wrapper-checked {
                    border: 2px solid var(--app-004986)!important;
                    background-color: #FFFFFF !important;
                    font-family: 'RedHat-Regular';

                    .emiza-stickering-count {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;

                        .emiza-logo {
                            img {
                                width: 60px !important;
                            }
                        }

                        .count {
                            font-size: 18px;
                            font-family: "RedHat-Bold";
                        }

                    }
                }

                .bar-code-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        max-width: 100%;
                    }
                }
            }

            .barcode-button {
                border: 2px solid #d9d9d9;
                margin-top: 10px;
                cursor: pointer;
                background-color: white;
                padding: 5px;

               
            }

            .barcode-button-1 {
                border: 2px solid var(--app-004986);
                margin-top: 10px;
                cursor: pointer;
                background-color: #E5F2F5;
                padding: 5px;

               
            }

            .selected {
                // position: relative;
                border: 3px solid #46C76A;
                box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
            }



            .select-icon {
                font-size: 24px;
                // height: 24px;
                background: white;
                color: #46C76A;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                top: -7px;
                right: -7px;
                position: absolute;
            }
        }

    }

    .box-container {
        .add-content {
            background: #EFEFEF;
            border-radius: 2px;
            padding: 5px;
            width: 300px;

            .close-btn {
                font-size: 15px;
                color: var(--app-f34436);
                background: rgba(221, 62, 19, 0.15);
                display: flex;
                border-radius: 3px;
                padding: 3px;
            }

            .bin-box {
                color: var(--app-004986);
                font-size: 14px;
                font-family: 'RedHat-SemiBold';

                span {
                    color: #000000 !important;
                    font-size: 14px !important;
                    font-family: 'RedHat-SemiBold' !important;
                }
            }
        }

        .move-bin-container {
            width: 900px;

            .ant-row {


                background: rgba(0, 129, 157, 0.1);
                border-radius: 8px;
                padding: 5px 5px;

                .ant-input-affix-wrapper {
                    border: 2px solid #d9d9d9 !important;
                }

                .move-text {
                    color: #000000;
                    font-size: 14px;
                    font-family: 'RedHat-Bold';
                    width: 150px
                }

                .add-txt {
                    font-size: 14px !important;
                    font-family: "RedHat-SemiBold" !important;
                    color: var(--app-004986)!important;
                    width: 45px !important;
                }

                .cancel-txt {
                    font-size: 14px !important;
                    font-family: "RedHat-SemiBold" !important;
                    color: black !important;
                }

                .good-box-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #FFFFFF;
                    margin-left: 20px;
                    padding: 5px;
                    border-radius: 3px;
                    width: 500px;

                    .text {
                        font-size: 14px !important;
                        font-family: "RedHat-SemiBold" !important;
                        color: black !important;
                        width: 45px !important;

                    }


                    .ant-input {
                        background: #FFFFFF;
                        border: 2px solid #d9d9d9;
                        color: #232323;
                        width: 100px;
                        height: 25px;
                        border-radius: 3px;
                    }
                }

                .ant-select {
                    width: 100%;
                }

                .ant-select-selector {
                    border: none !important;
                }
            }
        }

        .attribute-box {
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.1);
            font-size: 14px;
            font-family: "RedHat-Bold";
            color: black;
        }

        .add-btn {
            background-color: #D9ECF0;
            color: var(--app-004986);
            border-radius: 5px;
            font-size: 14px;
            font-family: "RedHat-SemiBold";
            padding: 6px;
            width: 100px;
            text-align: center;
        }

        .box-info {
            padding: 8px;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.1);
            font-size: 14px;
            font-family: "RedHat-Bold";
            color: black;
        }

        .view-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .ant-checkbox {
            border: 1px solid var(--app-004986);

            .ant-checkbox-inner {
                background-color: #D9ECF0;
                border: none !important;
            }
        }

        .ant-checkbox-wrapper {
            font-size: 14px;
            font-family: "RedHat-SemiBold";
            color: black;
        }

        .ant-checkbox-checked {

            .ant-checkbox-inner {
                background-color: var(--app-004986);
                border: none !important;
            }
        }

        .button-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .btn-container {
            display: flex;
            align-items: center;

            .green-btn {
                border: 2px solid var(--app-004986);
                background-color: #FFFFFF;
                border-radius: 5px;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: "RedHat-SemiBold";
                color: var(--app-004986);
                width: 100px;
            }



        }

        .input-container {
            position: relative;
            width: 100%;

            .ant-input {
                border: 2px solid #d9d9d9;
            }

            .ant-select {
                width: 100%;
            }

            .ant-select-selector {
                border: 2px solid #d9d9d9 !important;
            }

            .ant-picker {
                width: 100%;
                border: 2px solid #d9d9d9;
            }

            .ant-select-arrow {
                svg {
                    path {
                        fill: var(--app-004986)!important
                    }
                }
            }

            .ant-picker-suffix {
                svg {
                    path {
                        fill: var(--app-004986)!important
                    }
                }
            }

            .import-label {
                position: absolute;
                top: 0%;
                left: 25%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                background-color: #FFFFFF;
                color: black;
                font-size: 14px;
                padding: 0px 5px;
                border: none;
                border-radius: 5px;
                text-align: center;
                font-family: "RedHat-Regular";
                color: black;
            }

            .cannedReason-label {
                position: absolute;
                top: 0%;
                left: 30%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                background-color: #FFFFFF;
                color: black;
                font-size: 14px;
                padding: 0px 5px;
                border: none;
                border-radius: 5px;
                text-align: center;
                font-family: "RedHat-Regular";
                color: black;
            }

            .comments-label {
                position: absolute;
                top: 0%;
                left: 5%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                background-color: #FFFFFF;
                color: black;
                font-size: 14px;
                padding: 0px 5px;
                border: none;
                border-radius: 5px;
                text-align: center;
                font-family: "RedHat-Regular";
                color: black;
            }

            .label {
                position: absolute;
                top: 0%;
                left: 20%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                background-color: #FFFFFF;
                color: black;
                font-size: 14px;
                padding: 0px 5px;
                border: none;
                border-radius: 5px;
                text-align: center;
                font-family: "RedHat-Regular";
                color: black;
            }
        }


        .box-row-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .box-info {
                padding: 8px;
                border-radius: 5px;
                background-color: rgba(0, 0, 0, 0.1);
                font-size: 14px;
                font-family: "RedHat-Bold";
                color: black;
            }

            .ant-input {
                border: 2px solid #d9d9d9;
                height: 30px;
            }

        }

        .good-bad-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .good-box-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #009d4b33;
                margin-right: 20px;
                padding: 5px;
                border-radius: 5px;

                .text {
                    font-size: 14px;
                    font-family: "RedHat-SemiBold";
                    color: black;
                    width: 45px;

                }

                .ant-input {
                    border: 2px solid var(--app-004986);
                    background-color: #D9ECF0;
                    color: #232323;
                    width: 130px;
                    height: 25px;
                    border-radius: 5px;
                }
            }

            .add-bad-text {
                font-size: 14px;
                font-family: "RedHat-Bold";
                color: var(--app-004986);
            }

            .bad-box-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #fad0c594;
                padding: 5px;
                border-radius: 5px;

                .text {
                    font-size: 14px;
                    font-family: "RedHat-SemiBold";
                    color: black;
                    width: 45px;
                }

                .ant-input {
                    border: 2px solid #E6E9EE;
                    background-color: white;
                    color: #232323;
                    width: 115px;
                    height: 25px;
                    margin-right: 10px;
                    border-radius: 5px;
                }

                .close-btn {
                    font-size: 15px;
                    color: var(--app-f34436);
                    background-color: white;
                    display: flex;
                    border-radius: 3px;
                    border: 1px solid var(--app-f34436);
                    padding: 3px;
                }
            }

        }

    }
}

.edit-green-btn {
    svg {
        height: 15px !important;
    }
}


.entry-view-container {

    .user-container {
        background-color: #FFFFFF !important;
        ;
        border-radius: 10px;


        .manager-name {
            color: var(--app-004986);
            font-size: 14px;
            font-family: "RedHat-SemiBold";
            margin-left: 14px;

        }
    }

    .user-img {
        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }

    .vehicle-info-box1 {
        .vehicle-info-title {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Bold';
        }

        .vehicle-info {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Regular';
        }
    }

    .vehicle-info-box2 {
        margin-top: 20px;

        .vehicle-info-title {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Bold';
        }

        .vehicle-info {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Regular';
        }
    }

    .vehicle-detail-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sku-container {
        margin-top: 20px;
        padding: 5px 10px;
        background: rgba(0, 90, 109, 0.1);
        border-radius: 8px;
        font-size: 14px;
        font-family: "RedHat-Bold";
    }

    .value {
        padding: 5px 10px;
        border-radius: 8px;
        font-size: 14px;
        font-family: "RedHat-Regular";
    }

}