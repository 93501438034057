.GateInReport-Container {
    .invoice-header {
        background-color: var(--app-505050) !important;
        color: white !important;
        font-family: 'RedHat-SemiBold';
    }

    .sku-header {
        background-color: var(--app-505050) !important;
        color: white !important;
        font-family: 'RedHat-SemiBold';
    }

    .Grn-container {
        padding: 10px;
        border-radius: 6px;
        background-color: var(--app-FFFFFF);
    }

    .send-san-view-screen-container {
        padding: 10px;
        border-radius: 6px;
        background-color: var(--app-FFFFFF);

        .btn-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 20px;
        }

        .send-san-container {
            border: 1px solid var(--app-004986);
            background-color: var(--app-FFFFFF);

            .wms360-logo-container {
                background: linear-gradient(89.98deg, #004986 -1.05%, #3A73A2 99.98%);
                // box-shadow: 0px 2px 1px rgba(20, 20, 20, 0.33);
                padding: 5px 15px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .template-view {
                padding: 15px;

                .content {
                    // margin-left: 10px;

                    .notes-sub-title {
                        color: var(--app-505050);
                        font-size: 18px;
                        margin-bottom: 10px;
                    }

                    .notes-sub1-title {
                        color: var(--app-646464);
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                }

                .info-text {
                    color: var(--app-646464);
                    font-size: 18px;
                }

                .ant-row {
                    margin-bottom: 10px;
                    padding: 15px;
                    background: #F0F5F8;
                    border-radius: 10px;

                    .san-details {
                        display: flex;
                        align-items: center;

                        .label {
                            color: #5F5F5F;
                            font-family: 'RedHat-Bold';
                            font-size: 16px;
                        }

                        .value {
                            color: var(--app-black);
                            font-family: 'RedHat-Regular';
                            font-size: 14px;
                            margin-left: 5px;
                        }
                    }
                }

                .ant-input {
                    margin-top: 10px;
                    background-color: #eaeaea7c;
                    border-radius: 5px;
                }

                .ant-input:hover {
                    border-color: #eaeaea7c !important;
                }

                .regard-container {
                    .regard-txt {
                        color: #5F5F5F;
                        font-family: 'RedHat-SemiBold';
                        font-size: 18px;
                    }

                    .sign {
                        color: #5F5F5F;
                        font-family: 'RedHat-Regular';
                        font-size: 16px;
                    }
                }
            }


        }
    }
}