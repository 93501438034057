:root {

    --app-00819D: var(--app-004986);
    --app-black: black;
    --app-rgba0-0-0-01: rgba(0, 0, 0, 0.1);
    --app-rgba32-183-232-015: rgba(32, 183, 232, 0.15);
    --app-rgba0-129-157-015: rgba(0, 129, 157, 0.15);
    --app-rgba45-45-45-005: rgba(45, 45, 45, 0.05);
    --app-rgba255-255-255-015: rgba(255, 255, 255, 0.15);
    --app-rgba0-90-109-01: rgba(0, 90, 109, 0.1);
    --app-rgba0-129-157-01: rgba(0, 129, 157, 0.1);
    --app-rgba254-174-63-01: rgba(254, 174, 63, 0.1);
    --app-FFFFFF: #FFFFFF;
    --app-D0D0D0: #D0D0D0;
    --app-F8F8F8: #F8F8F8;
    --app-002831: #002831;
    --app-F0F5F8: #F0F5F8;
    --app-646464: #646464;
    --app-022E3A: #022E3A;
    --app-C7C7C7: #C7C7C7;
    --app-505050: #505050;
    --app-FEAD3F: #FEAD3F;
    --app-F0F5F8: #F0F5F8;
    --app-BFBFBF: #BFBFBF;
    --app-FEAE3F: #FEAE3F;
    --app-5E5E5E: #5E5E5E;
    --app-C7C7C7: #C7C7C7;
    --app-F5F5F5: #F5F5F5;
    --app-F4F4F4: #F4F4F4;
    --app-f34436: #f34436;
    --app-FCFCFC: #FCFCFC;
    --app-EEEEEE: #EEEEEE;
    --app-ABABAB: #ABABAB;
    --app-CF4F4F: #CF4F4F;
    --app-BB1A1A: #BB1A1A;
    --app-F7E4E4: #F7E4E4;
    --app-9D3636: #9D3636;
    --app-f9f9f9: #F9F9F9;
    --app-264147: #264147;
    --app-E9E9E9: #E9E9E9;
    --app-EFFCFF: #EFFCFF;
    --app-505050: #505050;
    --app-A0BCD2: #A0BCD2;
    --app-151515: #151515;
    --app-8ECAE6: #8ECAE6;
    --app-004986: #004986;
    --app-D9E4ED: #D9E4ED;
    --app-525252: #525252;
    --app-FBFBFB: #FBFBFB;
    --app-DADADA: #DADADA;
    --app-FDF7F7: #FDF7F7;
    --app-EBEBEB: #EBEBEB;
    --app-2D2D2D: #2D2D2D;
    --app-F5FAFB: #F5FAFB;
    --app-FBFCFC: #FBFCFC;
    --app-EFF0F6: #EFF0F6;
    --app-1BAB66: #1BAB66;
    --app-D6D6D9: #D6D6D9;
    --app-E0EDF0: #E0EDF0;
    --app-5D5D5D: #5D5D5D;
    --app-BDBDBD: #BDBDBD;
    --app-EDEDED: #EDEDED;
    --app-F9A369: #F9A369;
    --app-000000: #000000;
    --app-CFCFCF: #CFCFCF;
    --app-575757: #575757;
    --app-E8F4F6: #E8F4F6;
    --app-DFDFDF: #DFDFDF;
    --app-C0C0C0: #C0C0C0;
    --app-DD3E13: #DD3E13;
    --app-004986: #004986;
    --app-E3EBF2: #E3EBF2;
    --app-565656: #565656;
    --app-1C6FB5: #1C6FB5;
    


}