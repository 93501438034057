.packing-material-container {
    .invoice-header {
        background-color: #0a596bee !important;
        color: white !important;
    }

    .selected-packing-header {
        background-color: #D9ECF0;
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 10px;
        font-family: "RedHat-Bold";
        font-size: 16px;
    }

    .selected-packing {
        background: #FAFAFA;
        border: 1px solid #EEEEEE;
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 10px;
        font-family: "RedHat-Bold";
        font-size: 16px;

        .close-btn {
            background: rgba(221, 62, 19, 0.15);
            border: none !important;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3px;
            

            svg {
                height: 15px;
                width: 15px;
            }
        }
    }

    .create-content-view-container {
        background: #F4F4F4;
        padding: 10px;
        border-radius: 10px;
        font-family: "RedHat-Bold";
        font-size: 16px;

        .put-away-id-container {
            background-color: #D9ECF0;
            border: 2px solid #0a596b94;
            padding: 6px;
            border-radius: 6px;
            font-family: "RedHat-Bold";
            font-size: 16px;
        }

        .content-container-po {
            height: 60vh !important;
            overflow: scroll;
            padding-bottom: 40;

            .ant-radio-button-wrapper {
                height: 100%;
                margin-bottom: 10px;
            }

            .ant-radio-button-wrapper {
                background-color: #D9ECF0 !important;
                color: #575757 !important;
                border: none !important;
                width: 100%;
                // padding: 6px !important;

                .card-title-container {
                    // margin-top: 5px;
                    font-family: 'RedHat-Bold';
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 50px;


                    .label {
                        font-family: 'RedHat-Bold';
                        color: #2D2D2D;
                        font-size: 16px;
                    }

                    .count {
                        border-radius: 5px;
                        background-color: #FFFFFF !important;
                        border: none !important;
                        width: 60px;
                        font-family: 'RedHat-Bold';
                        font-size: 18px;
                        color: var(--app-004986);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }

                .label {
                    font-family: 'RedHat-Bold';
                    color: #2D2D2D;
                    font-size: 16px;
                }

                .count {
                    border-radius: 5px;
                    background-color: #FFFFFF !important;
                    border: none !important;
                    width: 60px;
                    font-family: 'RedHat-Bold';
                    font-size: 25px;
                    color: var(--app-004986);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .ant-radio-button-wrapper-checked {
                background-color: #D9ECF0 !important;
                border: 1px solid #0a596b94 !important;
                color: #575757 !important;

                .card-title {
                    font-family: 'RedHat-Bold';
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0px !important;
                    height: 50px;

                }

                .count {
                    border-radius: 5px;
                    background-color: #FFFFFF !important;
                    border: none !important;
                    width: 60px;
                    font-family: 'RedHat-Bold';
                    font-size: 25px;
                    color: var(--app-004986);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .ant-radio-button-checked {
                background-color: #D9ECF0 !important;
                border: 1px solid #0a596b94 !important;
                color: #575757 !important;

                .card-title {
                    font-family: 'RedHat-Bold';
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0px !important;
                }

                .count {
                    border-radius: 5px;
                    background-color: #FFFFFF !important;
                    border: none !important;
                    height: 50px;
                    width: 60px;
                    font-family: 'RedHat-Bold';
                    font-size: 25px;
                    color: var(--app-004986);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

            }

        }
    }
}