@import "../../Globals/ColorVariables.css";

.verified-container {
    .header-part {
        padding: 10px;
        background-color: #D9ECF0;
        align-items: center;
    }

}

.ant-form-item {
    margin-bottom: 0px !important;
}

.no-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    height: 100vh;

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: redhat-semibold;
        font-size: 24px;
        background-color: white;
        color: #676767;
    }

}

.pick-released-screen {
    .picker {
        background: rgba(0, 129, 157, 0.1);
        display: flex;
        align-items: center;
        margin-right: 5px;
        justify-content: space-around;
        border-radius: 10px;

        .user-details {
            display: flex;
            align-items: center;
            padding: 4px 10px;
            margin-right: 5px;
            font-family: redhat-bold;
            display: flex;
            align-items: center;
            justify-content: space-around;
            // .profile-pic {
            //     width: 25px;
            //     height: 25px;
            //     border-radius: 50%;
            //     overflow: hidden;
            //     margin-right: 5px;

            //     img {
            //         max-width: 100%;
            //     }
            // }
            .name {
                color: #00819D;
            }

        }

        .buttons {
            display: flex;
            align-items: center;

            .save {
                color: var(--app-00819D);
                background: #FFFFFF;
                font-size: 20px;
                margin-left: 10px;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .cancel {
                color: #FFFFFF;
                background: var(--app-00819D);
                font-size: 20px;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .reassign-button {
            color: var(--app-00819D);
            padding: 5px 10px;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .assignement-status {
        background: var(--app-EEEEEE);
        color: var(--app-ABABAB);
        padding: 5px 10px;
        border-radius: 5px;
    }

    .open-icon {
        display: flex;
        align-items: center;

        .arrow {
            color: var(--app-00819D);
            cursor: pointer;
            font-size: 20px;
            margin-right: 5px;
        }

        .arrow-right {
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
        }

        .arrow-down {
            transform: rotate(90deg);
            transition: all 0.25s ease;
        }

        .warning {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background: var(--app-CF4F4F);
            margin-left: 10px;
        }
    }

    .sku-details {
        padding: 10px;

        .manifest-data {
            color: "#022E3A";
            font-size: 16;
            font-family: "RedHat-SemiBold";
            margin-left: 3px;
        }
    }
}

.startPacking-btn {
    background: #E0EDF0;
    border: 1px solid #00819D;
    border-radius: 5px;
    color: #00819D;
    height: 30px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

}

.create-manifest-screen-container {
    padding: 20px;

    .content-container {
        padding: 8px;
        background: #F9F9F9;
        border-radius: 20px;

        .select-container {
            display: flex;
            align-items: center;

            .select-courier {
                display: flex;
                align-items: center;

                .courier-label {
                    font-family: "RedHat-Bold";
                    font-size: 18px;
                    width: 30%;
                }
            }

            .ant-checkbox-checked {
                background-color: #00819D !important;

                .ant-checkbox-inner {
                    background-color: #00819D !important;
                    border: none !important;
                }
            }

        }

        .manifest-container {
            height: 60vh;
            overflow-y: scroll;
            overflow-x: hidden;

            .sku-container {
                padding: 10px;
                background: #FFFFFF;
                border-radius: 10px;
                box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
                margin-top: 10px;

                .ant-row {
                    display: flex;
                    align-items: center;
                }

                .img-checkbox-container {
                    img {
                        width: 20px !important;
                        height: 40px !important;
                    }
                }

                .label {
                    font-family: "RedHat-Bold";
                    font-size: 16px;
                    color: #2D2D2D;
                }

                .value {
                    font-family: "RedHat-Medium";
                    font-size: 16px;
                    color: #2D2D2D;
                }

            }
        }
    }
}

.dragger-container {
    margin-bottom: 20px;
    background-color: var(--app-FFFFFF);
    width: 100%;
    border-radius: 10px;

    .ant-upload.ant-upload-drag {
        background: rgba(207, 231, 236, 0.4) !important;
        border: 1px dashed var(--app-00819D) !important;
        border-radius: 10px;
        position: relative;
    }

    .ant-btn {
        border-radius: 50%;
        width: 20px !important;
        height: 20px !important;
        padding: 0px !important;
        justify-content: center;
        align-items: center;
        border: 1px solid #DD3E13 !important;
    }

    .close-btn {
        position: absolute;
        top: 20px;
        right: 0;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        color: #DD3E13;
        background: #FAE2DC;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 12px;
            width: 12px;
        }
    }



    .ant-upload.ant-upload-drag .ant-upload {
        padding: 20px !important;
    }

    .ant-upload-drag-container {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .dragger-content {

        .drag-txt {
            font-size: 16px;
            color: var(--app-black);
            font-family: 'RedHat-SemiBold';
        }

        .browse-txt {
            font-size: 16px;
            color: var(--app-00819D);
            font-family: 'RedHat-SemiBold';
            text-decoration: underline;
        }

        .note-content {
            font-size: 14px;
            color: #676767;
            font-family: 'RedHat-Regular';
        }
    }
}

.RTS-btn-container {
    svg {
        width: 20px;
        height: 20px;

        path {
            fill: #00819D !important;
        }
    }
}

.createManifest-btn {
    background: #7ABDCB;
    border-radius: 5px;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #002831;
    font-family: "RedHat-Bold";
    font-size: 16;
    margin-left: 15px;
    cursor: pointer;
}

.close-btn {
    margin: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 40px;
    padding: 10px;
    color: #DD3E13 !important;
    background: #FAE2DC !important;
    border: 1px solid #DD3E13 !important;

    svg {
        height: 15px;
        width: 15px;
    }
}

.ant-modal.modal-view {
    width: 55% !important;
    border: 2px solid #00819D;
    border-radius: 10px;
}

.ant-modal.modal-view-2 {
    width: 75% !important;
    border: 2px solid #00819D;
    border-radius: 10px;
    top: 100px !important
}

.modal-view .ant-modal-close-x {
    display: none;
}

.modal-view-2 .ant-modal-body{
    height: 270px !important;
    overflow: scroll;
}

.buttons {
    display: flex;
    align-items: center;

    .save {
        color: var(--app-00819D);
        background: #FFFFFF;
        font-size: 20px;
        margin-left: 10px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .cancel {
        color: #FFFFFF;
        background: var(--app-00819D);
        font-size: 20px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.model-title-container-view {

    .product-view {
        border: 1px solid #ECECEC;
        width: 100%;
        border-radius: 6px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            width: 70px;
            height: 65px;
        }

    }

    .brand-view {
        margin-top: 8px;
        width: 100%;
        border: 1px solid #ECECEC;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;

    }

    .to-pack-qty-count {
        margin-top: 10px;
        background: #FCFCFC;
        border: 1px solid #D5D5D5;
        border-radius: 6px;
        color: #5A5A5A;
        padding: 25px 30px;
        width: fit-content;
        font-size: 20px;
        font-family: 'RedHat-Bold';
    }

    .less-packed-qty {
        margin-top: 10px;
        background: #FCFCFC;
        border: 1px solid #D5D5D5;
        border-radius: 6px;
        color: #FEAE3F;
        padding: 25px 30px;
        width: fit-content;
        font-size: 20px;
        font-family: 'RedHat-Bold';
    }

    .packed-qty-count {
        margin-top: 10px;
        background: #FCFCFC;
        border: 1px solid #D5D5D5;
        border-radius: 6px;
        color: #5A5A5A;
        padding: 25px 30px;
        width: fit-content;
        font-size: 20px;
        font-family: 'RedHat-Bold';

    }

    .less-remaining-qty-count {
        margin-top: 10px;
        background: #FCFCFC;
        border: 1px solid #D5D5D5;
        border-radius: 6px;
        color: #e22020;
        padding: 25px 30px;
        width: fit-content;
        font-size: 20px;
        font-family: 'RedHat-Bold';
    }

    .remaining-qty-count {
        margin-top: 10px;
        background: #FCFCFC;
        border: 1px solid #D5D5D5;
        border-radius: 6px;
        color: green;
        padding: 25px 30px;
        width: fit-content;
        font-size: 20px;
        font-family: 'RedHat-Bold';

    }

    .packed-type {
        background: white;
        border-radius: 6px;
        padding: 20px;
        height: 90%;

        .types {
            margin-top: 15px;
            background: #CAE5EA;
            border-radius: 8px;
            padding: 8px
        }
    }


}

.border-blue {
    border: 1px solid #7ABDCB !important;
}

.sku-scroll {
    background: #FAFAFA !important;
    padding: 10px;
    border-radius: 6px !important;
    height: 150px !important;
    overflow: scroll;
    margin-top: 10px;
}

.catons-container {
    padding: 10px;
    background-color: var(--app-FFFFFF);
    border-radius: 10px;
    height: 265px;

    .table-header {
        background-color: #D9ECF0 !important;
    }

    .title {
        color: black !important;
    }
}

.blue-box {
    background-color: #D9ECF0 !important;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin-right: 10px;

    svg {
        height: 20px;
        width: 30px;

        path{
            fill:#00819D !important
        }
    }
}

.close-carton {
    background-color: #F8E6E6 !important;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C02B34;
    font-family: "RedHat-Bold";
    font-size: 13px;
    cursor: pointer;
}

.red-box {
    background-color: #F8E6E6 !important;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    cursor: pointer;

    svg {
        height: 20px;
        width: 30px
    }
}

.green-box{
    background: #a3ee942e !important;
    // border: 1px solid #1BAB66;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    cursor: pointer;

    svg {
        height: 20px;
        width: 30px;
        path{
            fill:#1BAB66;
        }
        
    }
}

.gray-box {
    background-color: #EEEEEE !important;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    cursor: pointer;

    svg {
        height: 20px;
        width: 30px
    }
}

.return-info{
    font-size: 12px !important;
    font-family: 'RedHat-Medium' !important;
    color: #2D2D2D;
}

.save-btn{
    background-color: #FFFFFF !important;
    padding: 5px;
    border-radius: 6px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 35px !important;
    width: 35px !important;
    margin-right: 10px;
    cursor: pointer;

    svg {
        height: 20px !important;
        width: 25px !important;
        path{
            fill:#00819D
        }
    }
}

.white-box {
    background-color: #FFFFFF !important;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    cursor: pointer;

    svg {
        height: 20px;
        width: 30px;
        path{
            fill:#00819D !important
        }
    }
}

.gray-box {
    background-color: #FFFFFF !important;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    cursor: not-allowed;

    svg {
        height: 20px;
        width: 30px;
        path{
            fill: var(--app-D0D0D0) !important
        }
    }
}


.body-view {
    max-height: 200px !important;
    overflow: scroll;
}

.modal-Sku-table-container {
    padding: 10px;
    background-color: var(--app-FFFFFF);
    border-radius: 10px;
    height: 265px;
}

.cartonning-table-container {
    padding: 10px;
    background-color: var(--app-FFFFFF);
    border-radius: 10px;
    max-height: 265px;

    .table-header {
        background-color: #D9ECF0 !important;
    }

    .title {
        color: black !important;
    }

}

.Barcode-Img-Container {
    padding: 10px;
    background-color: var(--app-FFFFFF);
    border-radius: 10px;
    height: 265px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px !important;
    font-family: 'RedHat-Bold' !important;
    color: #2D2D2D;
    border: 1px solid #2D2D2D;
    margin-bottom: 10px;
}

.packing-list{
    padding: 10px;
    height: 842px;
    position: relative;
    box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
    .address{
        bottom: 0;
        font-size: 12;
        font-family: "RedHat-Medium";
        color: "#2D2D2D";
        position: absolute
    }
}

.viewPackingList-container {
    background-color: #FFFFFF;

    .Title-Container {
        align-items: center;

        .packing-title {
            text-align: right;
            font-size: 30px !important;
            font-family: 'RedHat-Bold' !important;
            color: #2D2D2D;
        }

        .order-title {
            font-size: 16px !important;
            font-family: 'RedHat-Bold' !important;
            color: #2D2D2D;
        }
    }

    .carton-container {
        margin-top: 10px;

        .carton-num {
            font-size: 20px !important;
            font-family: 'RedHat-Bold' !important;
            color: #2D2D2D;
        }

        .barcode-container {
            img {
                width: 100%;
                height: 40px;
            }
        }
    }

    .bill-ship-container {
        background-color: #EEEEEE;
        padding: 5px;
        font-size: 16px !important;
        font-family: 'RedHat-Bold' !important;
        color: #2D2D2D;
    }

    .address-container {
        font-size: 14px !important;
        font-family: 'RedHat-Medium' !important;
        color: #2D2D2D;
    }

    .packing-list-table-container {
        .ant-table-summary {
            font-size: 16px !important;
            font-family: 'RedHat-Bold' !important;
            color: #2D2D2D;
        }

        .ant-table-thead>tr>th {
            background-color:#EEEEEE !important;
            padding: 5px 15px !important;
            font-family: 'RedHat-Bold' !important;
            color: #2D2D2D;
            font-size: 16px !important;
            // border-bottom: 2px solid var(--app-00819D) !important;
        }

        .ant-table{
            border: 1px solid #C7C7C7 !important;
        }
    }
}