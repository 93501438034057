@import "../../Globals/ColorVariables.css";

.put-away-container {
    .ant-table {
        background: none !important;
    }

    .ant-table table {
        border-spacing: 0 10px !important;
    }

    .ant-table-thead>tr>th {
        background-color: #D9ECF0 !important;
        padding: 10px 10px !important;
        font-size: 14px !important;
        color: black !important;
        font-family: 'RedHat-Bold' !important;
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;
    }

    .ant-table-tbody>tr>td {
        background: #FCFCFC !important;
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;
        box-shadow: 1px 1px 1px #DADADA;
        padding: 5px 10px;
    }

    .ant-table-tbody>tr>td:first-child {
        border-left: 1px solid #EBEBEB !important;
    }

    .ant-table-tbody>tr>td:last-child {
        border-right: 1px solid #EBEBEB !important;
    }

    .ant-table-container table>thead>tr:first-child th:first-child {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        border-left: 1px solid #EBEBEB !important;
    }

    .ant-table-container table>thead>tr:first-child th:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        border-right: 1px solid #EBEBEB !important;
    }


    .ant-table-tbody>tr>td:first-child {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        border-left: 1px solid #EBEBEB !important;

    }

    .ant-table-tbody>tr>td:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        border-right: 1px solid #EBEBEB !important;

    }


    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-SemiBold';
    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
        border-right: 0px !important;
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-Medium';
    }

    .ant-table.ant-table-bordered>.ant-table-container {
        border-left: 0px !important;
    }


    // .ant-table-tbody>tr.ant-table-row:hover>td,
    // .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    //     background: rgba(0, 129, 157, 0.1) !important;
    // }

}

.backGround {
    padding: 20px;
    background-color: #FFFFFF;
}

.data-view {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.header-part {
    margin-bottom: 10px;
    padding: 10px;
    background: #A0BCD2;
    border-radius: 5px;

    .text {
        font-size: 14px !important;
        color: black !important;
        font-family: 'RedHat-SemiBold' !important;
    }
}

.header-part-2 {
    color: var(--app-FFFFFF);
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--app-505050);
    border-radius: 5px;
    border: 1px solid #F2F8FA;

    .text {
        font-size: 14px !important;
        font-family: 'RedHat-regular' !important;
        color: var(--app-FFFFFF) !important;
    }
}

.put-away-view-container {
    padding: 10px;

    .scanned-box-list-container {
        background: #FFFFFF;
        border: 1px solid #EFF0F6;
        border-radius: 20px;
        box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
        padding: 20px;
        height: 100%;

        .scanned-box-title {
            font-size: 20px !important;
            color: black !important;
            font-family: 'RedHat-Bold' !important;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                margin-left: 5px;
            }
        }

        .box-container {
            display: flex;
            align-items: center;

            .Scanned-barcode-button {
                border: 2px solid #DB912B;
                margin-top: 10px;
                cursor: pointer;
                background-color: #FFF3E2;
                padding: 10px;
            }


            .scanned-box {
                background: #FFFFFF;
                border: 1px solid #EFF0F6;
                border-radius: 20px;
                box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 30px;
                    height: 30px;
                }

                .box-label {
                    font-size: 20px !important;
                    color: black !important;
                    font-family: 'RedHat-Bold' !important;
                }
            }
        }
    }

    .selected-option {
        background: #F4F4F4;
        padding: 10px;
        border-radius: 10px;

        .title {
            font-size: 14px;
            font-family: "RedHat-Bold";
            color: black;
            margin-bottom: 10px;
        }

        .put-away-id-container {
            background-color: #E5E5E5;
            width: 100%;
            border-radius: 5px;
            padding: 10px 10px;
            font-size: 14px;
            font-family: "RedHat-Bold";
            color: black;
        }

    }

    .table-input-container {
        padding: 10px;
        background-color: #FFFFFF;
        border-radius: 10px;
        margin-top: 10px;

        .ant-input {
            border: 2px solid #d9d9d9 !important;
        }

        .ant-table {
            background: none !important;
            margin-bottom: 20px;
        }

        .ant-table table {
            border-spacing: 0 10px !important;
        }

        .ant-table-thead>tr>th {
            background-color: var(--app-505050) !important;
            padding: 5px 10px !important;
            color: #FFFFFF;

        }

        .ant-table-tbody>tr>td {
            padding: 5px 10px;
            border-top: 2px solid #C7C7C7 !important;
            border-bottom: 2px solid #C7C7C7 !important;
        }

        .ant-table-tbody>tr>td:first-child {
            border-left: 2px solid #C7C7C7 !important;
        }

        .ant-table-tbody>tr>td:last-child {
            border-right: 2px solid #C7C7C7 !important;

        }

        .ant-table-container table>thead>tr>th:first-child {
            border-top-left-radius: 3px !important;

            border-bottom-left-radius: 3px !important;
        }

        .ant-table-container table>thead>tr>th:last-child {
            border-top-right-radius: 3px !important;
            border-bottom-right-radius: 3px !important;
        }

        .ant-table-tbody>tr>td:first-child {
            border-top-left-radius: 3px !important;

            border-bottom-left-radius: 3px !important;
        }

        .ant-table-tbody>tr>td:last-child {
            border-top-right-radius: 3px !important;
            border-bottom-right-radius: 3px !important;

        }


        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-SemiBold';
            border-right: 0px !important;
        }

        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
            border-right: 0px !important;
            background-color: var(--app-FFFFFF) !important;
            font-size: 14px;
            font-family: 'RedHat-Regular';
        }

        .ant-table.ant-table-bordered>.ant-table-container {
            border-left: 0px !important;
        }

        .ant-table-tbody>tr.ant-table-row:hover>td,
        .ant-table-tbody>tr>td.ant-table-cell-row-hover {
            background: none !important;
        }

        .ant-table-pagination.ant-pagination {
            display: none !important;
        }
    }

    .bar-listing-container-scroll {
        background-color: #F4F4F4;
        border-radius: 5px;
        margin: 10px;
        height: 82% !important;
        margin-bottom: 35px;

        .ant-input {
            border: 2px solid #d9d9d9;
        }

        .action-btn {
            margin-right: -12px !important;
            color: var(--app-004986) !important;
            margin-top: 4px;
        }

        .ant-radio-group {
            width: 100%;
            padding-bottom: 20px !important;

        }

        overflow: auto;

        .ant-radio-button-wrapper {
            height: 100%;
            margin-top: 10px;
            background-color: #F4F4F4 !important;
            border: 0px !important
        }



        .barcode-button {
            border: 2px solid #d9d9d9;
            margin-top: 10px;
            cursor: pointer;
            background-color: white;
            padding: 10px;
            color: var(--app-575757);
        }

        .ant-radio-button-wrapper {
            // background-color: #FFFFFF !important;
            // border: 2px solid #E6E9EE !important;
            color: var(--app-646464);
            font-family: 'RedHat-Regular';
            width: 100%;
            padding: 0px 10px !important;

            .emiza-sticer-countF {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                margin-top: -10px;

                .emiza-logo {
                    img {
                        width: 60px !important;
                    }
                }

                .storage-box {

                    background: rgba(0, 157, 53, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    font-size: 14px;
                    font-family: "RedHat-Bold";
                    color: #009421;
                    height: 20px;
                }




                .count {
                    font-size: 18px;
                    font-family: "RedHat-Bold";
                    color: black;
                }
            }

            .emiza-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;

                img {
                    width: 60px !important;
                }
            }

            .emiza-stickering-count {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                margin-top: 5px;




                .storage-box {

                    background: rgba(0, 157, 53, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    font-size: 14px;
                    font-family: "RedHat-Bold";
                    color: #009421;
                    height: 20px;
                }

                .storageG-box {
                    background: #E5F2F5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    font-size: 14px;
                    font-family: "RedHat-Bold";
                    color: var(--app-004986);
                    height: 20px;
                }

                .storageC-box {
                    background: rgba(55, 84, 237, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    font-size: 14px;
                    font-family: "RedHat-Bold";
                    color: #3754ED;
                    height: 20px;
                }

                .box-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;

                    img {
                        width: 30px !important;
                        height: 20px !important;
                    }
                }

                .count {
                    font-size: 18px;
                    font-family: "RedHat-Bold";
                    color: black;
                }

            }

            .bar-code-img {
                margin-bottom: 5px;

                img {
                    width: 100%;
                }
            }

            .code {
                font-size: 14px !important;
                font-family: "RedHat-Bold" !important;
                color: black !important;
                margin-top: 3px;
                margin-bottom: 3px;
            }

            // .ant-radio-button-checked {
            //     border: 2px solid var(--app-004986)!important;
            //     font-family: 'RedHat-Regular';

            //     .emiza-stickering-count {
            //         display: flex;
            //         justify-content: space-between;
            //         align-items: center;
            //         padding: 10px;

            //         .emiza-logo {
            //             img {
            //                 width: 100% !important;
            //             }
            //         }

            //         .count {
            //             font-size: 18px;
            //             font-family: "RedHat-Bold";
            //             color: black;
            //         }

            //     }
            // }

            // .ant-radio-button-wrapper-checked {
            //     border: 2px solid var(--app-004986)!important;
            //     font-family: 'RedHat-Regular';

            //     .emiza-stickering-count {
            //         display: flex;
            //         justify-content: space-between;
            //         align-items: center;
            //         padding: 10px;

            //         .emiza-logo {
            //             img {
            //                 width: 60px !important;
            //             }
            //         }

            //         .count {
            //             font-size: 18px;
            //             font-family: "RedHat-Bold";
            //         }

            //     }
            // }

            .bar-code-img {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                }
            }
        }
    }

    .bar-listing-container {
        // padding: 10px;
        background-color: #F4F4F4;
        border-radius: 5px;
        margin: 10px;
        height:  550 !important;
        margin-bottom: 35px;

        .ant-input {
            border: 2px solid #d9d9d9;
        }

        .action-btn {
            margin-right: -12px !important;
            color: var(--app-004986) !important;
            margin-top: 4px;
        }

        .ant-radio-group {
            width: 100%;
            padding-bottom: 20px !important;

        }

        overflow: auto;

        .ant-radio-button-wrapper {
            height: 100%;
            margin-top: 10px;
            background-color: #F4F4F4 !important;
            border: 0px !important
        }



        .barcode-button {
            border: 2px solid #d9d9d9;
            margin-top: 10px;
            cursor: pointer;
            background-color: white;
            padding: 10px;
            color: var(--app-575757);
        }

        .ant-radio-button-wrapper {
            // background-color: #FFFFFF !important;
            // border: 2px solid #E6E9EE !important;
            color: var(--app-646464);
            font-family: 'RedHat-Regular';
            width: 100%;
            padding: 0px 10px !important;

            .emiza-sticer-countF {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                margin-top: -10px;

                .emiza-logo {
                    img {
                        width: 60px !important;
                    }
                }

                .storage-box {

                    background: rgba(0, 157, 53, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    font-size: 14px;
                    font-family: "RedHat-Bold";
                    color: #009421;
                    height: 20px;
                }




                .count {
                    font-size: 18px;
                    font-family: "RedHat-Bold";
                    color: black;
                }
            }

            .emiza-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;

                img {
                    width: 60px !important;
                }
            }

            .emiza-stickering-count {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                margin-top: 5px;




                .storage-box {

                    background: rgba(0, 157, 53, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    font-size: 14px;
                    font-family: "RedHat-Bold";
                    color: #009421;
                    height: 20px;
                }

                .storageG-box {
                    background: #E5F2F5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    font-size: 14px;
                    font-family: "RedHat-Bold";
                    color: var(--app-004986);
                    height: 20px;
                }

                .storageC-box {
                    background: rgba(55, 84, 237, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    font-size: 14px;
                    font-family: "RedHat-Bold";
                    color: #3754ED;
                    height: 20px;
                }

                .box-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;

                    img {
                        width: 30px !important;
                        height: 20px !important;
                    }
                }

                .count {
                    font-size: 18px;
                    font-family: "RedHat-Bold";
                    color: black;
                }

            }

            .bar-code-img {
                margin-bottom: 5px;

                img {
                    width: 100%;
                }
            }

            .code {
                font-size: 14px !important;
                font-family: "RedHat-Bold" !important;
                color: black !important;
                margin-top: 3px;
                margin-bottom: 3px;
            }

            .bar-code-img {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                }
            }
        }
    }

    .content-container1 {
        background: #DDEEF2;
        border: 1px solid #F2F8FA;
        box-shadow: 1px 1px 1px #DADADA;

        .createPutAway {
            font-size: 16px;
            font-family: "RedHat-Bold";
            color: black;
        }


    }

    .content-container {
        background-color: white !important;

        .createPutAway {
            font-size: 16px;
            font-family: "RedHat-Bold";
            color: black;
        }
    }

    .content-container-put-away {
        width: 100%;
        background: #FCFCFC;
        border: 1px solid #EBEBEB;
        box-shadow: 1px 1px 1px #DADADA;
        border-radius: 6px;
        font-family: redhat-regular !important;
        display: flex;
        align-items: center;
    }

    .radio-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: "RedHat-Regular";
        color: black;

        .ant-radio-inner {
            border: 1px solid var(--app-004986) !important;
        }

        .ant-radio-inner::after {
            background-color: var(--app-004986);
        }

        .ant-radio-checked {
            .ant-radio-inner {
                border: 1px solid var(--app-004986) !important;
            }
        }


    }

    .create-content-view-container {
        background: #F4F4F4;
        padding: 10px;
        border-radius: 6px;

        .data {
            font-size: 14px;
            font-family: "RedHat-Medium";
            color: black;
        }

        .box-container {
            display: flex;
            align-items: center;

            .box-label {
                margin-left: 5px;
                font-size: 14px;
                font-family: "RedHat-Bold";
                color: var(--app-004986);
            }

            .good-icon {
                svg {
                    path {
                        fill: var(--app-004986) !important
                    }
                }
            }

            .box-repair-label {
                font-size: 14px;
                font-family: "RedHat-Bold";
                color: #FEAE3F;

                .repair-icon {
                    margin-left: -6px;
                    height: 14px !important;
                    width: 20px !important;

                    svg {
                        height: 14px !important;

                        path {
                            fill: #FEAE3F !important
                        }
                    }
                }
            }


            .box-Bad-label {
                font-size: 14px;
                font-family: "RedHat-Bold";
                color: #DD3E13;
            }

            .bad-icon {
                margin-left: -6px;

                svg {
                    height: 14px !important;

                    path {
                        fill: #DD3E13 !important
                    }
                }
            }
        }

        .action-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .ant-btn>.anticon {
                line-height: 0px !important;
            }

            .ant-btn {
                border-radius: 50%;
                width: 20px !important;
                height: 20px !important;
                padding: 0px !important;
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            .delete-btn {
                color: #DD3E13;
                background-color: #FAE2DC;
                font-size: 12px;
            }
        }

        .title {
            font-size: 14px;
            font-family: "RedHat-Bold";
            color: black;
            margin-bottom: 5px;
        }

        .ant-input {
            border: 2px solid #d9d9d9 !important;
        }


        .ant-select-selector {
            border: 2px solid #d9d9d9 !important;
        }

        .ant-select-arrow {
            svg {
                path {
                    fill: black !important
                }
            }
        }

        .put-away-id-container {
            background-color: #E5E5E5;
            width: 100%;
            border-radius: 5px;
            padding: 5px 10px;
            font-size: 14px;
            font-family: "RedHat-Bold";
            color: black;
        }

        .btn-container {
            display: flex;
            justify-content: space-between;

            .gud-btn,
            .gud-btn:hover,
            .gud-btn:focus {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #e1f8ecf2;
                border: 2px solid #88d8b3;
                padding: 5px;
                border-radius: 5px;
                margin-left: 10px;
                margin-right: 10px;
                width: 160px;
                font-size: 14px;
                font-family: "RedHat-Bold";
                color: black;
                padding-left: 10px;

            }

            .bad-btn,
            .bad-btn:hover,
            .bad-btn:focus {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: rgba(221, 62, 19, 0.15);
                border: 1px solid var(--app-f34436);
                padding: 5px;
                border-radius: 5px;
                margin-left: 10px;
                margin-right: 10px;
                width: 160px;
                font-size: 14px;
                font-family: "RedHat-Bold";
                color: black;
                padding-left: 10px;
            }

            .repairable-btn,
            .repairable-btn:hover,
            .repairable-btn:focus {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: rgba(254, 174, 63, 0.15);
                border: 1px solid #FEAE3F;
                padding: 5px;
                border-radius: 5px;
                margin-left: 10px;
                margin-right: 10px;
                width: 160px;
                font-size: 14px;
                font-family: "RedHat-Bold";
                color: black;
                padding-left: 10px;
            }

            .delete-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #fad0c5;
                color: var(--app-f34436);
                border-radius: 5px;
                font-size: 14px;
                padding: 5px;
            }
        }

        .assignToMover-container {
            .ant-select {
                width: 500px;
            }

            .select-field {
                width: 100%;

                .ant-select-selector {
                    border: 2px solid #d9d9d9 !important;
                    height: 40px !important;
                    border-radius: 5px !important;
                    align-items: center;
                }

                .user-container {
                    background-color: rgba(45, 45, 45, 0.15) !important;
                    ;
                    border-radius: 10px;


                    .manager-name {
                        color: var(--app-004986);
                        font-size: 14px;
                        font-family: "RedHat-SemiBold";
                        margin-left: 14px;

                    }
                }

                .user-img {
                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                }

            }
        }
    }
}