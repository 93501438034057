@import "../../Globals/ColorVariables.css";

.main-container {
    padding: 20px;

    .content-container {
        border: 2px solid var(--app-004986);

        .wms360-icon {
            background: linear-gradient(90deg, var(--app-002831) -1.6%, var(--app-004986) 101.53%);
            padding: 3px;

            svg {
                position: relative;
                top: 4px;
                left: 15px;
            }
        }

        .text-container {
            padding: 15px;

            .notes-sub-title {
                color: var(--app-002831);
                font-size: 16px;
                font-weight: 600;
                padding-bottom: 20px;
            }

            .details {
                display: flex;
                padding: 10px;
                background: var(--app-F0F5F8);
                border-radius: 6px;
                margin: 10px 10px 10px 0px;


                .prev-key {
                    padding-right: 10px;
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--app-002831);
                }

            }

            .prev-table {
                margin-bottom: 10px;
                padding: 10px;
                background: var(--app-F0F5F8);
                border-radius: 6px;

                .c-row {
                    display: flex;

                    .c-key {
                        padding-right: 10px;
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--app-002831);
                    }

                    .c-value {
                        font-size: 16px;
                        color: var(--app-646464);
                    }
                }
            }

            .num {
                // display: flex;
                padding: 10px;
                background: var(--app-F0F5F8);
                border-radius: 6px;

                .c-row {
                    display: flex;

                    .c-key {
                        padding-right: 10px;
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--app-002831);
                    }

                    .c-value {
                        font-size: 16px;
                        color: var(--app-646464);
                    }
                }
            }

            .comment-container {
                padding-top: 20px;

                .add-comments {
                    font-size: 16px;
                    font-weight: 500;
                    padding-bottom: 5px;
                }

                .input-cmt {

                    .ant-input {
                        height: 90px !important;
                    }
                }

            }

            .regards-container {
                padding-top: 20px;

                .best-regards {
                    font-size: 16px;
                    font-weight: 500;
                    padding-bottom: 5px;
                }

                .wisher {
                    font-size: 16px;
                }
            }
        }
    }

    .van-buttons {
        display: flex;
        justify-content: flex-end;
        padding: 15px 0px;

        .cancel {
            background: var(--app-rgba0-129-157-015);
            border: 1px solid var(--app-004986);
            border-radius: 5px;
            color: var(--app-004986);
            font-size: 18px;
            margin: 0px 15px 0px 0px;
            padding: 5px 20px;
        }

        .send-van {
            background: var(--app-004986);
            font-size: 18px;
            color: var(--app-FFFFFF);
            border: 1px solid var(--app-004986);
            border-radius: 5px;
            height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
        }
    }
}

.gatein-entry-view-container {
    padding: 10px;
    height: 100vh;


    .content-containter {
        background: var(--app-rgba32-183-232-015);

        .blue-box {
            background-color: var(--app-004986);
            border-radius: 10px;
            height: 20px;
        }

        .entry-view-container {
            padding: 10px 20px 10px 20px;

            .vehicle-info-box1 {
                .vehicle-info-title {
                    font-size: 16px;
                    color: var(--app-black);
                    font-family: 'RedHat-Bold';
                }

                .vehicle-info {
                    font-size: 14px;
                    color: var(--app-black);
                    font-family: 'RedHat-Regular';
                }
            }

            .vehicle-info-box2 {
                margin-top: 20px;

                .vehicle-info-title {
                    font-size: 16px;
                    color: var(--app-black);
                    font-family: 'RedHat-Bold';
                }

                .vehicle-info {
                    font-size: 14px;
                    color: var(--app-black);
                    font-family: 'RedHat-Regular';
                }
            }

            .vehicle-detail-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }


        }

        .comment-container {
            padding: 20px 20px 20px 20px;

            .heading {
                font-size: 16px;
                color: var(--app-black);
                font-family: 'RedHat-Bold';
            }

            .text {
                font-size: 14px;
                color: var(--app-black);
                font-family: 'RedHat-SemiBold';
            }
        }
    }

    .attachment-title-img-comtainer {
        margin-top: 20px;

        .attachment-title {
            font-size: 16px;
            color: var(--app-black);
            font-family: 'RedHat-Bold';
            display: flex;
            align-items: center;

            .ant-btn {
                width: 20px !important;
                height: 20px !important;
                padding: 0px !important;
                justify-content: center;
                align-items: center;
            }

            .add-btn {
                color: var(--app-FFFFFF);
                background-color: var(--app-004986);
                font-size: 14px;
                border: none !important;
                margin-left: 10px;
                border-radius: 5px;
            }

        }

        .img-box {
            margin-top: 10px;
            display: flex;

            .img-comtainer {
                background: var(--app-E9E9E9);
                margin-right: 20px;
                font-family: "RedHat-Regular";
                position: relative;
                padding: 2px 5px;
                width: 100%;
                display: flex;
                max-width: 100px;
                align-items: center;
                justify-content: space-between;
                border-radius: 5px;
                margin: 3px;
                color: var(--app-004986);
                text-decoration: underline;
                width: 150px;
                height: 100px;

                .ant-btn {
                    border-radius: 50%;
                    width: 20px !important;
                    height: 20px !important;
                    padding: 0px !important;
                    justify-content: center;
                    align-items: center;
                }

                img{
                    max-height: 100%;
                    width: 100%;
                }

                .delete-btn {
                    position: absolute;
                    top: 20px;
                    left: 90%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    color: var(--app-FFFFFF);
                    background-color: var(--app-004986);
                    font-size: 12px;
                    border: none !important;
                }
            }
        }

    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dragger-container {
        margin-bottom: 20px;
        background-color: var(--app-FFFFFF);

        .ant-upload.ant-upload-drag {
            background: rgba(207, 231, 236, 0.4) !important;
            border: 1px dashed var(--app-004986) !important;
            border-radius: 10px;
            position: relative;
        }

        .ant-btn {
            border-radius: 50%;
            width: 20px !important;
            height: 20px !important;
            padding: 0px !important;
            justify-content: center;
            align-items: center;
            border: 1px solid #DD3E13 !important;
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 0;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            color: #DD3E13;
            background: #FAE2DC;
            font-size: 11px;
        }

        .ant-upload.ant-upload-drag .ant-upload {
            padding: 20px !important;
        }

        .ant-upload-drag-container {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }

        .dragger-content {

            .drag-txt {
                font-size: 16px;
                color: var(--app-black);
                font-family: 'RedHat-SemiBold';
            }

            .browse-txt {
                font-size: 16px;
                color: var(--app-004986);
                font-family: 'RedHat-SemiBold';
                text-decoration: underline;
            }

            .note-content {
                font-size: 14px;
                color: #676767;
                font-family: 'RedHat-Regular';
            }
        }
    }


}

.gate-in-add-container {
    padding: 15px;

    .type-container {
        background: rgba(32, 183, 232, 0.15);
        border-radius: 8px;
        padding: 20px;

        .lable {
            font-size: 16px !important;
            color: var(--app-black) !important;
            font-family: 'RedHat-Bold' !important;
        }

        .content-container {
            margin-top: 10px;
            background-color: #FFFFFF;
            border-radius: 8px;
            padding: 10px;
        }
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .close-btn {
            font-size: 16px;
            color: var(--app-004986);
            background-color: #D9ECF0;
            border: 2px solid var(--app-004986);
            font-family: 'RedHat-Regular';
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 37px;
            margin-right: 10px;
        }
    }

    .ant-picker {
        width: 100% !important;
        height: 40px !important;
        border: 2px solid #d9d9d9 !important;
        border-radius: 5px !important;
    }

    .ant-radio-group {
        padding: 3px !important;
        border: 1px solid #DEF4FC !important;
        border-radius: 5px !important;
        background: #DEF4FC !important;
    }

    .ant-radio-button-wrapper {
        background: #DEF4FC !important;
        border: none !important;
        font-size: 14px !important;
        color: var(--app-black) !important;
        font-family: 'RedHat-Regular' !important;
    }

    .ant-radio-button-wrapper-checked {
        background-color: rgba(20, 117, 147, 0.4) !important;
        border: none !important;
        border-radius: 5px !important;
        font-size: 14px !important;
        color: var(--app-black) !important;
        font-family: 'RedHat-Regular' !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none !important;
    }


    .ant-switch-handle {
        width: 60px !important;
        height: 35px !important;
    }

    .ant-input {
        width: 100% !important;
        // border: 2px solid #d9d9d9 !important;
        height: 40px !important;
        border-radius: 5px !important;
    }

    .ant-select.ant-select-in-form-item {
        width: 100% !important;

    }

    .ant-select-selector {
        // border: 2px solid #d9d9d9 !important;
        height: 40px !important;
        border-radius: 5px !important;
        align-items: center;
    }

    .ant-select-arrow {
        svg {
            path {
                fill: black !important
            }
        }
    }

    .ant-form-item {
        width: 100%;
        margin-bottom: 0px !important;
    }

    .ant-form-vertical .ant-form-item-label>label {
        font-size: 14px !important;
        color: var(--app-black) !important;
        font-family: 'RedHat-Bold' !important;
    }
}

.ant-btn.ant-btn-primary.ant-btn-block.add-new-gateIn-button {
    background-color: var(--app-004986);
    border-radius: 5px;
}
