@import "../Globals/ColorVariables.css";

.app-buttons-container {
    // margin: 0px 10px 0px 0px;
    .solid-linear-green,
    .solid-linear-green:hover,
    .solid-linear-green:focus {
        font-size: 16px;
        // color: var(--app-004986);
        // background-color: var(--app-8ECAE6);
        border-color: var( --app-004986) !important;
        color: var( --app-004986) !important;
        background-color: var(--app-D9E4ED) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        margin-left: 10px;
    }
    
    .solid-light-green,
    .solid-light-green:hover {
        font-size: 16px;
        color: var(--app-004986) !important;
        background: rgba(0, 129, 157, 0.15) !important;
        border: none !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        margin-left: 10px;
    }

    .Solid-red-outline,
    .Solid-red-outline:hover {
        font-size: 16px;
        background-color: var(--app-F7E4E4) !important;
        border: 1px solid var( --app-BB1A1A) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        margin-left: 10px;
        color: var(--app-BB1A1A) !important
    }

    .borderless-white-button,
    .borderless-white-button:hover{
        font-size: 16px;
        border-color: var(--app-D9E4ED)!important;
        color: var( --app-004986) !important;
        background-color: var(--app-D9E4ED) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        height: 37px;
        margin-left: 10px;
    }

    .solid-white-button,
    .solid-white-button:hover {
        font-size: 16px;
        border-color: var( --app-004986) !important;
        color: var( --app-004986) !important;
        background-color: var(--app-D9E4ED) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        height: 37px;
        margin-left: 10px;
    }

    .solid-white-button-1,
    .solid-white-button-1:hover {
        font-size: 16px;
        border-color: var( --app-004986) !important;
        color: var(--app-FFFFFF) !important;
        background-color: var(--app-004986) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        height: 37px;
        margin-left: 10px;
    }

    .solid-linear-green-1,
    .solid-linear-green-1:hover,
    .solid-linear-green-1:focus {
        font-size: 16px;
        color: var(--app-FFFFFF) !important;
        background-color: var(--app-004986) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        margin-left: 10px;
    }
    .solid-linear-green-2,
    .solid-linear-green-2:hover,
    .solid-linear-green-2:focus {
        font-size: 16px;
        color: var(--app-FFFFFF) !important;
        background-color: var(--app-004986) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        margin-left: 10px;
    }
    .solid-linear-green-3,
    .solid-linear-green-3:hover,
    .solid-linear-green-3:focus {
        font-size: 16px;
        color: var(--app-FFFFFF) !important;
        background-color: var(--app-004986) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        margin-left: 10px;
    }
    .solid-linear-green-6,
    .solid-linear-green-6:hover,
    .solid-linear-green-6:focus {
        font-size: 16px;
        color: var(--app-FFFFFF) !important;
        background-color: var(--app-004986) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 45px;
    }
    .solid-linear-green-4,
    .solid-linear-green-4:hover,
    .solid-linear-green-4:focus {
        font-size: 16px;
        color: var(--app-FFFFFF) !important;
        background-color: var(--app-004986) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
    }
    .solid-linear-green-5,
    .solid-linear-green-5:hover,
    .solid-linear-green-5:focus {
        font-size: 16px;
        color: var(--app-FFFFFF) !important;
        background-color: var(--app-004986) !important;
        font-family: 'RedHat-Medium';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        margin-left: 10px;
    }
    .circle{
        border-radius: 50% !important;
    }

    .next-button{
        position: absolute;
        top: 50%;
        right: -10px;
        font-size: 25px !important;
        border-radius: 50% !important;
    }
    .previous-button{
        position: absolute;
        top: 50%;
        left: -20px;
        font-size: 25px !important;
        border-radius: 50% !important;
    }
}
.ant-btn.ant-btn-primary.add-boxes-button
{
    border-color: var( --app-004986) !important;
    color: var(--app-FFFFFF) !important;
    background-color: var(--app-004986) !important;
    font-family: 'RedHat-Medium' ;  
    border-radius: 3px;  
    height: 37px;
    margin-left: 10px;
}

.ant-btn.ant-btn-primary.add-boxes-btn{
    border-color: var( --app-004986) !important;
    color: var(--app-004986) !important;
    background-color: var(--app-D9E4ED) !important;
    font-family: 'RedHat-Medium' ; 
    border-radius: 5px;  
    height: 37px; 
}

