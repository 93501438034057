.menubar-container {
    height: 100vh;
    background: #004986 !important;
    min-width: 270px;
    position: fixed;
    .app-icon {
        box-shadow: 0px 2px 1px rgba(20, 20, 20, 0.33);
        height: 10vh;
    }

    .wms-container {
        padding: 15px 21px;
        height: 10vh;
        .icon-backbround {
            background: #11568E;
            border-radius: 4px;
            padding: 5px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .wms {
            display: flex;
            align-items: center;
            color: #F9A369;
            font-size: 18px;
            font-family: 'RedHat-Bold';
            height: fit-content;
        }

    }

    .bottom-line-container {
        height: 2px;
        background: rgba(255, 255, 255, 0.16);
        border-radius: 20px;
        margin: -3px 21px 0px;

    }

    .submenu-container::-webkit-scrollbar {
        display: none !important;
    }
    // .asside{
    //     height: 100vh;
        .submenu-container {
            padding: 5px 10px 0px 24px;
            height: 62vh;
            overflow: scroll;

            .wms-main-container {
                display: flex;
                align-items: center;
                padding: 8px 0px;
                margin-top: 5px;
                margin-top: 5px;
                cursor: pointer;

                .icon-container-seleted {
                    svg {
                        path {
                            fill: #F9A369;
                        }
                    }
                }

                .icon-container {
                    svg {
                        path {
                            fill: #FFFFFF;
                        }
                    }
                }
            }

            .wms-submenu-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 5px;
                background: #11568E;
                border-radius: 10px;
                margin: 7px 0px 7px 0px;
                cursor: pointer;
            }

            .wms-submenu-container-selected {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 5px;
                background: #037DD1;
                border-radius: 10px;
                margin: 7px 0px 7px 0px;
                cursor: pointer;
            }

            .main-menu {
                background: #004986;

                .ant-menu-item {
                    padding-left: 0px !important;

                    .ant-menu-title-content {
                        color: #FFFFFF;
                        font-family: RedHat-SemiBold;
                        margin-left: 10px;
                        font-size: 16px;
                    }

                    svg {
                        path {
                            fill: #FFFFFF;
                        }
                    }
                }

                .ant-menu-item-selected {
                    background: none;
                }
            }

        }

        .user-detail-container {
            padding: 5px 15px;
            // position: absolute;
            // bottom: 10px;
            height: 18vh;

            .warehouse-container {
                background: #11568E;
                border-radius: 10px;
                padding: 10px 8px;
                display: flex;
                align-items: center;

                .warehouse-name {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    color: white;
                    font-size: 16px;
                    font-family: RedHat-SemiBold;
                }
            }

            .use-detail-container {
                display: flex;
                align-items: center;
                background: #11568E;
                border-radius: 10px;
                height: 50px;
                padding: 10px 8px;
                width: 75%;

                .name-view {
                    color: white;
                    font-size: 16px;
                    font-family: RedHat-SemiBold;
                }
            }

            .exit-container {
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #11568E;
                border-radius: 10px;
                padding: 10px 8px;
                width: 20%;
                cursor: pointer;
            }
        }
    // }
}

.inner-menu {
    margin-left: 8px;
    width: 80%;
    white-space: normal;
    line-height: initial;
    font-size: 14px;
    color: #FFFFFF;
    font-family: RedHat-Medium;

}

.menu-count-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14%;
    font-size: 14px;
    font-family: RedHat-Medium;
    color: #FFFFFF;
    background: #2B699A;
    height: 28px;
    border-radius: 4px;

}

.menu-count-bg-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14%;
    font-size: 14px;
    font-family: RedHat-Medium;
    color: #FFFFFF;
    height: 28px;
    background: #218DD7;
    border-radius: 4px;

}

.search-component-view {
    position: fixed;
    z-index: 9999;
    left: 270px;
    top: 0px;
    bottom: 0px;
}

.main-popup-container {
    height: 100%;
    width: 500px;
    padding: 15px 20px;
    border: 5px solid #037DD1;
    background: white;

    .header-container-view {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-title {
            color: #A8A8A8;
            font-size: 16px;
            font-family: RedHat-Bold;
        }

        .close-icon {
            cursor: pointer;

            svg {
                path {
                    fill: #A8A8A8;
                }
            }

        }
    }

    .search-alphabets {
        width: 100%;
        margin-top: 15px;
        display: flex;

        .alphabets-container {
            width: 100%;
        }
    }

    .divider-line {
        border-top: 2px solid rgba(0, 0, 0, 0.06) !important;
    }
}

.label-color-black {
    color: black;
    font-family: 'RedHat-Bold';
    text-decoration: underline;
    font-size: 12px;

}

.label-color-blue {
    text-decoration: underline;
    color: #004986;
    font-size: 12px;
    font-family: 'RedHat-Bold';
    cursor: pointer;
}

.label-color-blue-1 {
    color: #004986;
    font-size: 14px;
    font-family: 'RedHat-Bold';
    cursor: pointer;
}

.label-selected{
    background-color: #004986;
    color: var(--app-F9A369);
    font-size: 16px;
    font-family: RedHat-SemiBold;
    padding: 5px;
    border-radius: 5px;
}

.label-color-blue-2 {
    color: #004986;
    font-size: 14px;
    font-family: 'RedHat-Bold';
}

.oms-client-tat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 5px;
    background: #11568E;
    border-radius: 10px;
    margin: 7px 0px 7px 0px;
    cursor: pointer;
    border: 3px solid #037DD1;
}