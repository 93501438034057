.d-flex {
    display: flex !important;
}

.ant-layout-content {
    background-color: var(--app-FFFFFF);
}

.jc-sb {
    justify-content: space-between;
}

.jc-sa {
    justify-content: space-around;
}

.jc-c {
    justify-content: center;
}

.align-c {
    align-items: center !important;
}

.antd-content-white {
    background-color: var(--app-FFFFFF);
}

.mr-10 {
    margin-right: 10px;
}

.mr-5 {
    margin-right: 5px;
}

.greenColor {
    color: #4AB381
}
.redColor{
    color: #CC3333
}

.list-skeleton {
    background-color: var(--app-FFFFFF);
    box-shadow: 2px 2px 2px var(--app-DADADA);
    margin: 20px 10px 10px 10px;
    border-radius: 10px;
    height: 40px;
    padding: 10px;
}

.table-header {
    background-color: var(--app-A0BCD2);
    box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
    border-radius: 5px;
    padding: 10px;
    color: var(--app-151515);
    font-family: 'RedHat-Bold' !important;
}

.header-color {
    background-color: #D9ECF0 !important
}

.table-header-2 {
    background-color: var(--app-505050);
    box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
    border-radius: 5px;
    padding: 10px;
    color: var(--app-FFFFFF);
    font-family: 'RedHat-regular' !important;
}

.table-body-gateout {
    background: var(--app-FDF7F7);
    font-family: 'RedHat-Medium' !important;
    padding: 8px;
    color: var(--app-black);
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 1px var(--app-DADADA);
    border: 1.5px solid var(--app-BB1A1A) !important;
}

.table-body {
    background: rgba(0, 129, 157, 0.1);
    background: var(--app-FCFCFC);
    font-family: 'RedHat-Medium' !important;
    padding: 8px;
    color: var(--app-black);
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--app-EBEBEB) !important;
    /* box-shadow: 1px 1px 1px #dadada; */
}

.margin {
    margin: 15px;

}

.header-skeleton {
    background-color: white;
    box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
    padding: 10px;
    height: 55px;

}

.ant-skeleton-title {
    width: 100% !important;
}


.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50%;
}

.w-70 {
    width: 70%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 5px;
    margin-right: 5px !important;
    height: 3px !important;
    /* min-height: 2px !important;
    max-height: 3px !important; */
}

::-webkit-scrollbar {
    width: 5px;
    height: 3px !important;
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: none !important;
}

.ant-layout-sider {
    max-width: 100px !important;
    min-width: 100px !important;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.p-25 {
    padding: 25px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-25 {
    padding-left: 25px;
}

.pr-25 {
    padding-right: 25px;
}

.pr-10 {
    padding-right: 10px;
}

.required::after {
    content: "*";
    color: red;
}

.pt-10 {
    padding-top: 10px;
}

.c-p {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

.app-blue {
    color: var(--app-004986);
}

.mt-45 {
    margin-top: 50px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.top-55 {
    top: 54px !important;
}

.top-50 {
    top: 50px !important;
}

.mt-55 {
    margin-top: 55px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table {
    border-top: 0px !important;
}

.ant-table-thead>tr>th {
    background: none !important;
    border-right: none !important;
    border-top: 1px solid var(--app-EBEBEB) !important;
    border-bottom: 1px solid var(--app-EBEBEB) !important;
    font-family: "RedHat-SemiBold"
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border-right: none !important;
    font-family: "RedHat-SemiBold"
}

.ant-menu-inline {
    border-right: 0px !important;
}

.ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #F9F9FF !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: var(--app-FFFFFF) !important;
}

/* .ant-drawer-right>.ant-drawer-content-wrapper {
    max-height: 620px !important;
    margin-top: 55px !important;
} */

.ant-drawer-close {
    display: none !important;
}

.ant-drawer-title {
    padding: 10px 15px !important;
    background: var(--app-A0BCD2);
    font-size: 15px !important;
    color: var(--app-black) !important;
    font-family: 'RedHat-Bold' !important;
    /* border-radius: 5px !important; */
}

.ant-drawer-body {
    padding: 10px !important;
}

.ant-drawer-header {
    padding: 0px !important;
}

.cross-btn {
    color: var(--app-FBFBFB) !important;
    background-color: var(--app-525252) !important;
    /* border: 2px solid var(--app-525252) !important; */
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* padding: 3px !important; */
    border-radius: 50% !important;
    height: 22px !important;
    /* margin-right: 10px; */
    width: 22px !important;
    font-size: 13px !important;

}

.ant-btn>.anticon {
    line-height: 0 !important;
}

.round-cross-btn {
    color: #DD3E13 !important;
    background-color: #FAE2DC !important;
    font-size: 15px !important;
    border: 1px solid #DD3E13 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 3px !important;
    border-radius: 50% !important;
    height: 25px !important;
    margin-right: 10px;
    width: 25px !important;
}


.edit-green-btn {
    color: var(--app-FFFFFF) !important;
    background-color: var(--app-004986) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 3px !important;
    border-radius: 6px !important;
    height: 30px !important;
    margin-right: 10px;
    width: 30px !important;
}

.cancel-btn {
    font-size: 16px !important;
    color: var(--app-004986) !important;
    background-color: #D9ECF0 !important;
    border: 2px solid var(--app-004986) !important;
    font-family: 'RedHat-Regular' !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px !important;
    border-radius: 6px !important;
    height: 37px !important;
    margin-right: 10px !important;
}

.cancel-btn-d {
    font-size: 16px !important;
    color: var(--app-004986) !important;
    background-color: #D9ECF0 !important;
    border: 2px solid var(--app-004986) !important;
    font-family: 'RedHat-Regular' !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px !important;
    border-radius: 6px !important;
    height: 37px !important;
}

.ant-drawer-header {
    border-bottom: 0px !important;
}

.ant-form-vertical .ant-form-item-label>label {
    font-size: 14px !important;
    color: var(--app-black) !important;
    font-family: 'RedHat-Bold' !important;

}

.showDate {
    width: 100% !important;
    height: 40px !important;
    border-radius: 5px !important;
    font-family: "RedHat-SemiBold";
    background: var(--app-CFCFCF);
    color: var(--app-2D2D2D);
    font-size: 14px;
    padding: 10px;
}

.input-field {
    width: 100% !important;
    border: 2px solid #d9d9d9;
    height: 37px !important;
    border-radius: 5px !important;
    font-family: "RedHat-SemiBold"

}

.redhat-semibold {
    font-family: "RedHat-SemiBold"
}

.redhat-regular {
    font-family: "RedHat-Regular"
}

.redhat-bold {
    font-family: "RedHat-Bold"
}

.mt-20 {
    margin-top: 20px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

.text-field {
    width: 100% !important;
    border: 2px solid #d9d9d9 !important;
    height: 70px !important;
    border-radius: 5px !important;
}

.ant-dropdown {
    width: 170px;
    border-radius: 10px !important;
    margin: 5px !important;
}

.ant-dropdown-menu {
    border-radius: 10px !important;
    font-size: 14px;
    font-family: 'RedHat-Bold';
    margin: 5px !important;
}

.ant-dropdown-menu-item {
    border-radius: 10px !important;
    font-size: 14px;
    font-family: 'RedHat-Bold';
    margin: 5px !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
    background-color: none !important;
    font-size: 14px;
    font-family: 'RedHat-Bold';
    margin: 5px !important;
}

.ant-dropdown-menu-item:hover {
    font-size: 14px !important;
    background-color: var(--app-004986) !important;
    font-family: 'RedHat-Bold' !important;
    color: var(--app-FFFFFF) !important;
    margin: 5px !important;
    border-radius: 10px;
}

.ant-select-selection-item {
    font-size: 14px;
    font-family: 'RedHat-SemiBold';
}

.ant-select-item-option {
    border-radius: 10px !important;
    font-size: 14px;
    font-family: 'RedHat-Bold';
    margin: 5px !important;
    box-shadow: none !important;
}

.ant-select-item-option:hover {
    font-size: 14px !important;
    background-color: var(--app-004986) !important;
    font-family: 'RedHat-Bold' !important;
    color: var(--app-FFFFFF) !important;
    margin: 5px !important;
    border-radius: 10px;
    box-shadow: none !important;
}

.ant-select-item .ant-select-item-option .ant-select-item-option-active {
    background-color: none !important;
    font-size: 14px;
    font-family: 'RedHat-Bold';
    margin: 5px !important;
    box-shadow: none !important;
}

.ant-select-selection-item {
    height: 30px !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    font-size: 20px !important;
}

.text {
    font-family: 'RedHat-Bold' !important;
    color: #3F3F3F !important;
    font-size: 14px !important;
}

.text-regular {
    font-family: 'RedHat-Regular' !important;
    color: #3F3F3F !important;
    font-size: 14px !important;
}

.text-1 {
    font-family: 'RedHat-Bold' !important;
    color: #3F3F3F !important;
    font-size: 14px !important;
}

.text-2 {
    font-family: 'RedHat-Bold' !important;
    color: #3F3F3F !important;
    font-size: 16px !important;
}

.text-3 {
    font-family: 'RedHat-Bold' !important;
    color: #3F3F3F !important;
    font-size: 18px !important;
}

/* .ant-progress-text {
    display: none !important;
} */

.ant-progress {
    display: flex;
    align-items: center;
}

.ant-progress-line {
    width: 180px !important;
}

.ant-progress-inner {
    border: 2px solid var(--app-004986);
}

.ant-progress-outer {
    width: 150 !important;
}

.ant-progress-bg {
    background-color: var(--app-004986) !important;
}

.ant-progress-text {
    font-size: 14px;
    font-family: 'RedHat-SemiBold';
    color: var(--app-004986);
    width: 4em !important;
}

.ant-pagination-item {
    margin-right: 5px !important;
    height: 25px !important;
    width: 25px !important;
    min-width: 0px !important;
    line-height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-pagination-prev {
    margin-right: 0px !important;
}

.ant-pagination-item-active a {
    color: var(--app-FFFFFF) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none !important;
}

.ant-input:focus {
    box-shadow: none !important;
}

.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
}

.ant-picker-focused {
    box-shadow: none !important;
}

.ant-picker-input input {
    font-size: 14px !important;
    font-family: 'RedHat-SemiBold' !important;
}

.menu {
    height: 100vh !important;
}

.border-design {
    border-left: 1px solid var(--app-000000);
}

.ant-layout-sider {
    background-color: #022f3b !important;
}

.green-btn {
    cursor: pointer;
}

.green-btn-1 {
    cursor: not-allowed;
}

.row-container {
    height: 100%;
    margin: 0px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 6px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -10px !important
}

.borderproperty {
    border: 2px solid green !important;
}

.pending-san-view-container {
    margin-top: 10px !important;
}

.jc-c {
    justify-content: center !important;
}

.ant-radio-wrappe ant-radio {
    border-color: var(--app-004986) !important;
}

.ant-radio-wrapper:hover .ant-radio {
    border-color: var(--app-004986) !important
}

.flex-end {
    justify-content: flex-end;
}

.ant-modal-header {
    padding: 10px !important;
    background: rgba(0, 73, 134, 0.23) !important;
    border-radius: 4px 4px 0px 0px !important;

}

.ant-modal-content {
    border-radius: 6px !important;
}

.ant-modal-title {
    color: #151515 !important;
}

.ant-modal-close-x svg {
    fill: white !important;
}

.tbl-srl {
    overflow: auto;
}

.start-receivingscreen-modal .ant-modal-close-x {
    display: none !important;
}

.start-receivingscreen-modal {
    border: 1px solid var(--app-004986) !important;
    border-radius: 6px;
    background-color: white;
    top: 10px;

}


.ant-modal-content {
    background-color: white;
    box-shadow: none !important;
}



.ant-drawer-content-wrapper {
    z-index: 9999 !important;
}

/* .srl-ppt {
    position: sticky;
    top: 0;
    z-index: 9;
} */

.ant-form-item-label {
    font-size: 14px !important;
    color: var(--app-black) !important;
    font-family: 'RedHat-Bold' !important;
}


.btn-cstm .solid-linear-green,
.solid-linear-green:hover,
.solid-linear-green:focus {
    font-size: 16px;
    color: var(--app-FFFFFF);
    background-color: var(--app-004986);
    font-family: 'RedHat-Medium';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 37px;

}

.selected-attribute {
    background: var(--app-FFFFFF);
    border: 1px solid var(--app-CFCFCF);
    border-radius: 6px;
    padding: 10px;
    margin-top: 10px;
}

.select-attribute {
    background: var(--app-FFFFFF);
    border: 1px solid var(--app-004986);
    border-radius: 6px;
    padding: 10px;
    margin-top: 10px;
}

.attribute-header {
    background: var(--app-505050);
    color: var(--app-FFFFFF);
    font-family: 'Redhat-Regular';
    padding: 5px;
    font-size: 14px;
    /* text-align: center; */
    /* border-radius: 5px; */
}

.attribute-value {
    font-family: 'Redhat-Regular';
    padding: 5px;
    /* text-align: center; */
    color: var(--app-black);
    border: 1px solid var(--app-DADADA);
    font-size: 14px;
}

.font-14 {
    font-size: 14px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-16-semi-bold {
    font-size: 16px;
    font-family: 'RedHat-SemiBold';

}

.font-17 {
    font-size: 17px !important;
}

.font-18 {
    font-size: 18px !important;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px;
}

.ml-25 {
    margin-left: 25px;
}

.mt-25 {
    margin-top: 25px;
}

.pt-15 {
    padding-top: 15px;
}

.height {
    height: 80px;
}

.ml-50 {
    margin-left: 50%;
}

.mr-40 {
    margin-right: 40%;
}

.bin-container-red {
    background-image: url("../src/Images/redBox.svg");
    background-repeat: no-repeat;
    height: 45px;
    width: 45px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.mt-0 {
    margin-top: 0px !important;
}

.movebin-container-red {
    background-image: url("../src/Images/redMoveBin.svg");
    background-repeat: no-repeat;
    height: 55px;
    width: 75px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.bin-container-red-1 {
    margin-left: 5px;
    background-image: url("../src/Images/redBox.svg");
    height: 45px;
    width: 45px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.text-center {
    text-align: center;
}

.bin-container-green {
    background-image: url('../src/Images/greenBox.svg');
    height: 45px;
    width: 45px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.movebin-container-green {
    background-image: url('../src/Images/greenMoveBin.svg');
    height: 55px;
    width: 75px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.movebin1-container-green {
    background-image: url('../src/Images/greenMoveBin.svg');
    height: 55px;
    width: 45px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.movebin1-container-red{
    background-image: url("../src/Images/redMoveBin.svg");
    height: 55px;
    width: 45px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.height{
    height: 500px;
}

.mr-15 {
    margin-right: 10px;
}

.count-container-rip {
    position: absolute;
    top: 40%;
    left: 30%;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    color: var(--app-FFFFFF);
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.count-container-cpt {
    font-family: 'RedHat-Bold';
    font-size: 16px;
}

.rip-count {
    font-size: 27px;
    color: var(--app-575757);
    font-family: 'RedHat-SemiBold';
}

.storage-type {
    margin-top: 10px;
    font-size: 18px;
    color: var(--app-575757);
    font-family: 'RedHat-SemiBold';
}

.dispay-block {
    display: block !important;
}

.attribute-container {
    background: var(--app-E8F4F6);
    padding: 10px;
    max-height: 190px;
    overflow: scroll;
    margin-top: 10px;
}

.ant-modal {
    background: #FFFFFF !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ant-modal-body {
    padding: 10px !important;
    padding-bottom: 0px !important;
    background: var(--app-FFFFFF) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.Add-Attribute-container {

    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'RedHat-SemiBold';
    color: var(--app-2D2D2D);
    font-size: 14px;
}

.show-label {
    font-family: 'RedHat-SemiBold' !important;
    color: var(--app-004986) !important;
    font-size: 14px !important;
    text-decoration: underline !important;
}

.Box-info {
    font-family: 'RedHat-SemiBold';
    font-size: 16px;
    color: var(--app-2D2D2D);
}

.down-arrow .arrow {
    color: var(--app-004986);
    cursor: pointer;
    font-size: 20px;
}

.down-arrow .arrow-right {
    transform: rotate(0deg);
    transition: all 0.25s ease;
}

.down-arrow .arrow-down {
    transform: rotate(90deg);
    transition: all 0.25s ease;
}

.select-box {
    padding: 10px;
    background: var(--app-FBFCFC);
    border: 1px solid var(--app-EFF0F6);
    box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
    border-radius: 10px;
}

.ant-radio-group {
    width: 100%;
}

.ant-input-number .ant-input-number-handler-wrap {
    visibility: hidden !important;
}

.ant-input-number {
    width: 90% !important;
}

.ant-steps-item-icon {
    background: var(--app-1BAB66) !important;
    border-radius: 0px !important;
    border: none !important;
}

.ant-steps-icon {
    color: var(--app-FFFFFF) !important;
}

.ant-steps-item-tail::after {
    background: var(--app-1BAB66) !important;
}

.ant-steps-item-title {
    font-family: 'RedHat-Medium' !important;
}

.ant-steps-item-description {
    font-family: 'RedHat-Regular' !important;
}

.ant-input-number-input {
    border-radius: 6px !important;
}

.cls-input {
    height: 37px !important;
}

.container-input {
    height: 37px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.spin-modal,
.spin-modal .ant-modal-body {
    background: transparent !important;
    box-shadow: none;
}

.spin-modal .ant-modal-content {
    background: transparent !important;
}

/* .ant-form-item-control-input {
    margin-bottom: 10px;
} */

.ant-radio-inner {
    border: 2px solid var(--app-004986) !important;
}

.ant-radio-inner::after {
    background-color: var(--app-004986) !important;
}

.MuiFormControl-root {
    height: 37px !important;
}

.ant-btn {
    padding: 5px 10px !important;
}

.background-container-component {
    margin-top: 10px;
    /* border: 1px solid #EBEBEB;
    border-radius: 6px;
    background: #FCFCFC; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.courier {
    background: var(--app-D6D6D9);
    width: 70%;
    border-radius: 6px;
    padding: 7px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    margin-left: 10px;
}

.css-fvipm8 {
    height: 37px !important;
}

.css-p0rm37 {
    transform: translate(14px, 8px) scale(1) !important;
}

.content-title-16 {
    font-family: "RedHat-Bold";
    font-size: 16px;
}

.content-value-16 {
    color: "#022E3A";
    font-size: 16px;
    font-family: "RedHat-SemiBold";

}

.arrow-down {
    transform: rotate(90deg) !important;
    transition: all 0.25s ease !important;
}

.arrow {
    color: var(--app-004986) !important;
    cursor: pointer;
    font-size: 20px;
}

.stock-action-btn {
    background: var(--app-E0EDF0);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    color: var(--app-004986);
    font-size: 13px;
    font-family: "RedHat-Bold";
    height: 35px;
    width: 100%;
}

.noData {
    font-size: 18px;
    font-family: redhat-semibold;
}

.height-class {
    height: 25vh !important;
}

.ant-tooltip-inner {
    background-color: var(--app-FFFFFF) !important;
    color: var(--app-000000) !important;
    font-family: redhat-semibold;
}

.ant-tooltip-arrow-content {
    --antd-arrow-background-color: white !important
}

.overflow-ellipse {
    /* display: -webkit-box !important; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* height: 30px; */
}

.overflow-ellipse-1 {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
}

.ant-modal.search-modal {
    width: 85% !important;
    border: 2px solid var(--app-004986);
    border-radius: 10px;
}

.search-modal .ant-modal-close-x {
    display: none;
}

.location-view-scroll {
    max-height: 400px !important;
    overflow-y: scroll !important;
}

.wr-in-progress {
    color: var(--app-FEAE3F);
}

.colr-wh {
    color: var(--app-FFFFFF) !important
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.Configure {
    background: var(--app-E0EDF0);
    border: 1px solid var(--app-004986);
    border-radius: 5px;
    padding: 3px 7px;
    font-size: 16px;
    font-family: 'RedHat-SemiBold';
    color: var(--app-004986);
    align-items: center;
    height: 40px;
    width: 120px;
    cursor: pointer;
}

.detail-box {
    background: var(--app-E0EDF0);
    border: 1px solid var(--app-004986);
    padding: 15px 10px;
    font-size: 16px;
    font-family: 'RedHat-SemiBold';
    border-radius: 10px;
    margin-top: 10px;
}

.title-bold {
    font-size: 16px !important;
    font-family: 'RedHat-Bold' !important;
    margin-bottom: 8px !important;
}

.bg-white {
    background: var(--app-FFFFFF) !important;
}

.filefiled {
    display: flex;
    align-items: center;
    padding: 5px;
    color: var(--app-5D5D5D);
    width: 60%;
    background: var(--app-EDEDED);
    font-size: 16px;
    font-family: 'RedHat-Medium';
    height: 37px;
    border: 1px solid var(--app-BDBDBD);
    overflow-x: hidden;
    border-radius: 6px;
}

.filefiled-2 {
    display: flex;
    align-items: center;
    padding: 5px;
    color: var(--app-5D5D5D);
    width: 66%;
    background: var(--app-EDEDED);
    font-size: 16px;
    font-family: 'RedHat-Medium';
    height: 37px;
    border: 1px solid var(--app-BDBDBD);
    overflow-x: hidden;
    border-radius: 6px;
}

.ant-upload-list {
    display: none;
}

.scroll-cw {
    height: 56vh;
    overflow-y: scroll;
}

.scroll-cw-2 {
    height: 79vh;
    overflow-y: scroll;
}

.sider-menu-360 {
    height: 100% !important;
    background: var(--app-004986) !important;
    min-width: 270px !important;
    /* max-width: 17% !important; */
}

.ant-layout-has-sider {
    height: 100%;
}

.ant-divider-horizontal {
    margin: 0px !important;
}

.color-orange {
    color: var(--app-F9A369);
    font-size: 16px;
    font-family: RedHat-SemiBold;
}

.color-white {
    color: var(--app-FFFFFF);
    font-size: 16px;
    font-family: RedHat-SemiBold;
}

.scroll-content {
    height: 92.5vh;
    overflow: scroll;
}

.header-color-stock-moment {
    background: #505050 !important;
    color: #FFFFFF !important;

}

.menu-height {
    height: 92.5vh !important;
}

.cross-btn-1 {
    align-items: center !important;
    background-color: rgb(250, 226, 220) !important;
    color: rgb(221, 62, 19) !important;
    display: flex !important;
    font-size: 15px !important;
    height: 35px !important;
    justify-content: center !important;
    width: 35px !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: rgb(221, 62, 19) !important;
    border-image: initial !important;
    border-radius: 6px !important;
}

.w-100px {
    width: 100px !important;
}

@media print {
    @page {
        size: 3mm 2mm;
        margin: 0;
        padding: 0;
        transform: scale(.94);

    }

    html,
    body {
        position: relative;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 97%;
        margin: 0;
        padding: 0;
    }
}

.img-model-container {
    max-width: 100%;
    /* height: 200px; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-view {
    width: 85%;
    height: 100%;
    border-radius: 10px;
}

.img-view-modal {
    max-width: 100vw;
    width: fit-content !important;
    height: fit-content;
    max-height: 100vh;
    overflow: hidden;
}

.img-view-modal img {
    max-width: 100vw;
    max-height: 100vh;
}

.img-view-modal .ant-modal-close-x {
    color: #DD3E13 !important;
    background-color: #FAE2DC !important;
    font-size: 18px !important;
    border: 2px solid #DD3E13 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 3px !important;
    height: 36px !important;
    margin-right: 10px;
    margin-top: 10px;
    width: 40px !important;
}

.img-view-modal .ant-modal-close-x svg path {
    fill: red;
}

.carousel-view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 75vw;
    background: #CFCFCF;
}

.carousel-img {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 75vw;
    background: #CFCFCF;
    overflow: hidden;
    position: relative;
}

.carousel-img img {
    max-width: 100%;
    max-height: 100%;
}

.position-relative {
    position: relative;
}

.title-blue {
    font-family: 'RedHat-Bold' !important;
    color: var(--app-004986) !important;
    font-size: 16px !important;
}

.mt-5 {
    margin-top: 5px;
}

.ant-row {
    width: 100% !important;
}

.upload-icon svg path {
    color: var(--app-004986);
}

.upload-icon svg {
    width: 25px;
    height: 25px;
    margin: 5px 0 0 10px;
    cursor: pointer;
}

.close-button {
    position: absolute !important;
    top: 25px;
    right: 0;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: #DD3E13 !important;
    /* background: #FAE2DC !important; */
    font-size: 11px;
    /* border-radius: 5px;
    height: 20px; */
    /* border: 1px solid var(--app-f34436); */



}

.setAsButton {
    position: absolute;
    top: 0;
    right: 40px;
}

.select-width {
    width: 225px !important;
}

.scroll {
    max-height: 160px;
    overflow: scroll;
}

.ant-table-filter-dropdown-search-input input {

    min-width: 120px !important;

}

.ant-btn-primary {
    display: flex;
    align-items: center;
}

.ant-table-cell {
    font-family: 'RedHat-Bold' !important;
    color: #2D2D2D !important;
    font-size: 16px !important;
}

.ant-table-filter-trigger {
    color: #D9ECF0 !important;
}

.table-scroll {
    height: 450px;
    overflow: scroll;
}

.textalign-c {
    text-align: center !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.default-btn {
    background: var(--app-004986);
    border: 1px solid var(--app-004986);
    border-radius: 5px;
    font-family: "RedHat-Medium";
    font-size: 16px;
    padding: 0px 6px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
}

.inner-table-blue-header {
    background-color: #505050 !important;
    color: white !important;
}

.inner-table-skyblue-header {
    background-color: #DEDEDE !important;
    color: black !important;
}

.table-background-green {
    background-color: #EBFBF2 !important;
    border: 1px solid #218448 !important;

}

.table-background-red {
    background-color: #FDF3F0 !important;
    border: 1px solid #DD3E13 !important;

}

.default-btn-2 {
    background: rgba(0, 129, 157, 0.1);
    border: 1px solid var(--app-004986);
    border-radius: 5px;
    font-family: "RedHat-Medium";
    font-size: 16px;
    padding: 0px 6px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--app-004986) !important;
}

.approve-btn {
    font-size: 14px;
    background: #C7E3E9;
    border-radius: 5px;
    color: #2D2D2D;
    padding: 5px 10px;
    font-weight: RedHat-Medium;
}

.reject-btn {
    font-size: 14px;
    background: #F7D1C6;
    border-radius: 5px;
    color: #A83516;
    padding: 5px 10px;
    font-weight: RedHat-Medium;
}

.text-16 {
    font-family: 'RedHat-Bold' !important;
    font-size: 16px !important;
}

.Add-Attribute-container.font-12 {
    font-size: 12px !important;
}

.bin-container-ambur {
    background-image: url("../src/Images/yellowbox.svg");
    background-repeat: no-repeat;
    height: 45px;
    width: 45px;
    font-family: 'RedHat-Bold';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.text-ellipsis {
    font-family: 'RedHat-Bold' !important;
    color: #3F3F3F !important;
    font-size: 18px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-align-c {
    display: flex;
    justify-content: center;
}

.dragger {
    background: rgba(207, 231, 236, .4) !important;
    border: 1px dashed var(--app-004986) !important;
    border-radius: 10px;
    position: relative;
    padding: 15px;
}

.bin-btn {
    font-size: 16px;
    border: 1px solid var(--app-004986) !important;
    color: var(--app-004986) !important;
    background-color: white !important;
    font-family: 'RedHat-Medium';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    height: 37px;
    margin-right: 10px;
    width: 220px !important;
}
.btn-hover-btn{
    font-size: 16px;
    color: var(--app-FFFFFF) !important;
    background-color: var(--app-004986) !important;
    font-family: 'RedHat-Medium';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 37px;
    margin-right: 10px;
    width: 220px !important;

}
.w-500{
    width: 500px !important;
}