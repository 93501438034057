@import "../../../Globals/ColorVariables.css";

.ant-layout-content {
  padding: 0px !important;
}

// .main-header {
//   background: var(--app-022E3A) !important;
//   color: var(--app-FFFFFF) !important;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   padding-left: 10px;
//   height: 50px;
//   .title {
//     font-family: "RedHat-Medium";
//     font-size: 20px;
//   }

//   .header-info {
//     width: 100%;

//     .ant-row {
//       width: 100%;
//       background: rgba(255, 255, 255, 0.15);
//       border-radius: 5px;

//     }
//   }

//   .warehouse-process-container {
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
//     width: 85%;
//     height: 30px;
//     margin-left: 20px;
//     background: rgba(255, 255, 255, 0.15);
//     border-radius: 5px;

//     .ant-divider-vertical {
//       background-color: white;
//     }

//     .content {
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       .selection-title {
//         font-family: "RedHat-Medium";
//         font-size: 16px;
//       }

//       .selection-value {
//         font-family: "RedHat-Regular";
//         font-size: 16px;
//         margin-left: 5px;
//       }

//       .selection-Process-title {
//         font-family: "RedHat-Medium";
//         font-size: 16px;
//         color: #FEAE3F;
//       }

//       .selection-Process-value {
//         font-family: "RedHat-Regular";
//         font-size: 16px;
//         margin-left: 5px;
//         color: #FEAE3F;
//       }
//     }

//   }

//   .align {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;

//     .content {
//       display: flex;
//       align-items: center;
//       margin-left: 10px;
//       margin-right: 10px;
//     }

//     .selection-title {
//       font-family: "RedHat-Medium";
//       font-size: 16px;
//     }

//     .selection-value {
//       font-family: "RedHat-Regular";
//       font-size: 16px;
//       margin-left: 5px;
//     }
//   }

//   .align1 {
//     .sub-content {
//       display: flex;
//       align-items: center;

//       .content {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         margin-left: 10px;
//         margin-right: 10px;
//       }

//       .selection-title {
//         font-family: "RedHat-Medium";
//         font-size: 18px;
//       }

//       .selection-value {
//         font-family: "RedHat-Regular";
//         font-size: 18px;
//         margin-left: 5px;
//       }
//     }

//     .img-col {
//       display: flex;
//       justify-content: flex-end;
//       align-items: center;
//     }
//   }



//   .menu-icon {
//     cursor: pointer;
//   }

//   .wms360-icon {
//     cursor: pointer;

//     svg {
//       height: 25px !important;
//     }
//   }

//   // .selection-title {
//   //     font-family: "RedHat-SemiBold";
//   // }
//   .Client-container {
//     width: 300px;

//     .selection-title {
//       font-family: "RedHat-Regular";
//       font-size: 16px;
//     }
//   }

//   .ant-select {
//     .ant-select-selector {
//       border-radius: 5px !important;
//       background: var(--app-rgba255-255-255-015) !important;
//       border: none !important;
//       height: 30px !important;

//       .ant-select-selection-item,
//       .ant-select-selection-placeholder {
//         font-size: 12px !important;
//       }


//       span {
//         font-size: 14px;
//         color: var(--app-FFFFFF);
//       }
//     }

//     .ant-select-arrow .anticon>svg {
//       color: var(--app-FFFFFF);
//       height: 10px !important;
//     }
//   }

//   .profile-pic-container {
//     background: rgba(255, 255, 255, 0.15);
//     ;
//     height: 40px;
//     width: 40px;
//     border-radius: 15px;
//     padding: 5px;
//     margin-right: 10px;

//     .profile-pic-inner-container {
//       background-color: white;
//       border-radius: 50%;
//       height: 100%;
//       width: 100%;
//       padding: 3px;

//       .inner-container {
//         overflow: hidden;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         height: 100%;
//         width: 100%;
//         border-radius: 50%;

//         img {
//           max-width: 100%;
//           max-height: 100%;
//         }
//       }
//     }
//   }
// }

.create-wareHouse-Container {
  background: #ECECEC;

  .client-column {
    height: 92.5vh;
    width: 284px;
  }

  .sub-sider-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    .ant-menu {
      background: none !important;
      width: 200px !important;
    }

    .ant-menu-vertical {
      border-right: none !important;
    }
  }

  .contract-header {
    padding: 20px;
    background: #ECECEC;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'RedHat-Bold';
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .select-right-container {
    // border-left: 1px solid #d1d1d1;
    // height: 100%;
    // background: #F8F8F8;


    .input-property {
      font-size: 16px;
      // background: #00819d26;
      font-family: "RedHat-Regular";
      padding: 10px;
      border: none;
      position: fixed;
      z-index: 99;
      width: 284px;
      top: 50px;

      // .ant-input {
      //   background: #00819d26 !important;
      // }
    }

    .input-property-2 {
      font-size: 16px;
      // background: #00819d26;
      font-family: "RedHat-Regular";
      padding: 10px;
      border: none;
    }

    // .header {
    //   padding: 10px;
    //   background: rgba(0, 129, 157, 0.15);
    //   box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    //   font-family: 'RedHat-Bold';
    //   font-size: 20px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    // }

    .content-container-po {
      padding-left: 1px;

      .ant-radio-button-wrapper {
        height: 100%;
        margin-bottom: 10px;
      }

      .ant-radio-button-wrapper {
        background: #F8F8F8 !important;
        color: var(--app-646464);
        font-family: 'RedHat-Regular';
        border: none !important;
        width: 100%;

        .card-title-container {
          // margin-top: 5px;
          font-family: 'RedHat-Bold';
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;


          .label {
            font-family: 'RedHat-Bold';
            color: #2D2D2D;
            font-size: 16px;
          }

          .count {
            border-radius: 5px;
            background-color: #FFFFFF !important;
            border: none !important;
            width: 60px;
            font-family: 'RedHat-Bold';
            font-size: 18px;
            color: var(--app-004986);
            display: flex;
            align-items: center;
            justify-content: center;
          }

        }

        .label {
          font-family: 'RedHat-Bold';
          color: #2D2D2D;
          font-size: 16px;
        }

        .count {
          border-radius: 5px;
          background-color: #FFFFFF !important;
          border: none !important;
          width: 60px;
          font-family: 'RedHat-Bold';
          font-size: 25px;
          color: var(--app-004986);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-radio-button-wrapper-checked {
        background: rgba(254, 174, 63, 0.15) !important;
        color: var(--app-646464);
        font-family: 'RedHat-Regular';

        .card-title {
          font-family: 'RedHat-Bold';
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px !important;
          height: 50px;

        }

        .count {
          border-radius: 5px;
          background-color: #FFFFFF !important;
          border: none !important;
          width: 60px;
          font-family: 'RedHat-Bold';
          font-size: 25px;
          color: var(--app-004986);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-radio-button-checked {
        background: rgba(254, 174, 63, 0.15) !important;
        color: var(--app-646464);
        font-family: 'RedHat-Regular';
        border-left: 5px solid #FEAE3F !important;

        .card-title {
          font-family: 'RedHat-Bold';
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px !important;
        }

        .count {
          border-radius: 5px;
          background-color: #FFFFFF !important;
          border: none !important;
          height: 50px;
          width: 60px;
          font-family: 'RedHat-Bold';
          font-size: 25px;
          color: var(--app-004986);
          display: flex;
          align-items: center;
          justify-content: center;
        }

      }

    }

    .content-form-container {
      background: #FFFFFF;
      padding: 20px;

      .btn-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .close-btn {
          font-size: 16px;
          color: var(--app-004986);
          background-color: #D9ECF0;
          border: 2px solid var(--app-004986);
          font-family: 'RedHat-Regular';
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          height: 37px;

        }

        .green-btn {
          margin: 0px 10px;
          font-size: 16px;
          color: #FFFFFF;
          background-color: var(--app-004986);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          height: 40px;
          font-family: 'RedHat-Regular' !important;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .content-container-form {
        background: #F7F7F7;
        border-radius: 10px;
        padding: 10px;
        padding: 10px 20px;

        .label {
          font-size: 14px !important;
          color: var(--app-black) !important;
          font-family: 'RedHat-Bold' !important;
          margin-bottom: 10px;
        }

        .type {
          background: rgba(0, 129, 157, 0.15);
          border-radius: 5px;
          padding: 5px 10px;
          font-family: 'RedHat-Bold' !important;
          width: 300px
        }

        .dropdown {
          svg {
            height: 20px !important;
            width: 20px !important;
          }
        }

        .btn-container {}

        .detail-container {
          background: #FFFFFF;
          border-radius: 5px;
          padding: 5px 20px;
          margin-top: 10px;
        }

        .contact-container {
          background: rgba(0, 129, 157, 0.15);
          border-radius: 5px;
          padding: 10px 20px;
          margin-top: 10px;
        }

        .ant-checkbox {
          border: 1px solid var(--app-004986);

          .ant-checkbox-inner {
            border: none !important;
          }
        }

        .ant-checkbox-wrapper {
          font-size: 16px;
          font-family: "RedHat-Bold";
          color: black;
        }

        .ant-checkbox-checked {
          background-color: var(--app-004986);

          .ant-checkbox-inner {
            background-color: var(--app-004986);
            border: none !important;
          }
        }

        .ant-row {
          .ant-input {
            height: 40px;
            background: #FFFFFF;
            border: 2px solid #d9d9d9 !important;
            border-radius: 4px;
            font-family: 'RedHat-Regular';
          }

          .ant-select.ant-select-in-form-item {
            width: 100% !important;

          }

          .ant-select-selector {
            border: 2px solid #d9d9d9 !important;
            height: 40px !important;
            border-radius: 5px !important;
            align-items: center;
            font-family: 'RedHat-Regular';
          }

          .ant-select-arrow {
            svg {
              path {
                fill: black !important
              }
            }
          }
        }
      }
    }

    .view-part-container {
      margin: 20px;
      padding: 20px;
      background: linear-gradient(86.22deg, var(--app-004986)0%, #02A5C9 100%);
      border-radius: 8px;

      .col-border {
        border-right: 2px solid white;
      }

      .client-name {
        font-family: 'RedHat-Bold';
        font-size: 20px;
        color: #FFFFFF;
      }

      .content {
        margin-top: 15px;

        .title {
          font-family: 'RedHat-SemiBold';
          font-size: 18px;
          color: #FFFFFF;
        }

        .value {
          font-family: 'RedHat-Regular';
          font-size: 18px;
          color: #FFFFFF;
          margin-top: 5px;
        }

        .history-text {
          font-family: 'RedHat-Regular';
          font-size: 18px;
          color: #FEAE3F !important;
          margin-top: 5px;
        }
      }

    }


    .content-view-container {
      // border-left: 1px solid #d1d1d1;
      height: 100%;

      //background: #F8F8F8;
      .title-header {
        left: 33% !important;
        top: 58px;
        // position: fixed !important;
        margin-left: 6px;
        // margin-top: -10px !important;
      }

      .header-part {
        margin-top: 70px !important;
      }

      .view-content-design {
        position: absolute;
        top: 90%;
        left: 53%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        padding-bottom: 60px;

      }

      .view-content {
        padding: 10px !important;

        .content-box-design {
          border: 2px solid #CECECE;
          box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
          margin-bottom: 20px !important;
        }

        .content-box {
          box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
        }


        .content-box {
          padding: 10px;
          background-color: #FFFFFF;
          border-radius: 5px;
          margin-bottom: 10px;
          width: 100%;

          .other-warehouse-col {
            border-left: 2px solid #A5A5A5;
            padding-left: 20px !important;
          }

          .content-title {
            font-family: 'RedHat-Bold';
            font-size: 14px;
            color: #2D2D2D;
          }

          .content {
            font-family: 'RedHat-Medium';
            font-size: 14px;
            color: #2D2D2D;
          }

          .view-industry {
            background: rgba(0, 129, 157, 0.15);
            border-radius: 10px;
            padding: 5px 10px;
            font-family: 'RedHat-Bold';
            font-size: 16px;

            svg {
              path {
                fill: var(--app-004986) !important
              }
            }
          }

          .detailed-view {
            background: #F1F1F1;
            border-radius: 10px;
            padding: 10px;

            .role-container {
              background: rgba(0, 129, 157, 0.15);
              border-radius: 10px;
              padding: 5px 10px;

              .name {
                font-family: 'RedHat-Bold';
                font-size: 16px;

                span {
                  font-family: 'RedHat-Medium';
                  font-size: 16px;
                  margin-left: 5px;
                }
              }

              .role {
                font-family: 'RedHat-medium';
                font-size: 16px;
              }

              .dropdown {
                svg {
                  height: 25px !important;
                  width: 25px !important;
                }
              }
            }

            .contact-box {
              padding: 10px;

              svg {
                height: 16px;
                width: 16px;
              }

              span {
                margin-left: 10px;
                font-family: 'RedHat-Medium';
                font-size: 16px;

              }
            }
          }

          .warehouse-container {
            padding: 5px 10px;
            background: rgba(45, 45, 45, 0.1);
            border-radius: 5px;
            font-family: 'RedHat-Bold';
            font-size: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .select {
              color: var(--app-004986);
              font-size: 16px;
              text-decoration: underline;
            }

            .create-btn {
              font-size: 16px;
              color: #FFFFFF;
              background-color: var(--app-004986);
              font-family: 'RedHat-Bold';
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              height: 25px;
            }

          }

          .warehouse-create-container {
            padding: 10px;
            background: rgba(45, 45, 45, 0.1);
            border-radius: 5px;
            font-family: 'RedHat-Bold';
            font-size: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .view-payment {
              margin-right: 10px;

              svg {
                margin-right: 5px;
                width: 20px;
                height: 20px;

                path {
                  fill: var(--app-004986) !important;
                }
              }

              .label {
                font-size: 16px;
                color: var(--app-004986);
                font-family: 'RedHat-Regular';
              }
            }

            .create-btn {
              font-size: 16px;
              color: #FFFFFF;
              background-color: var(--app-004986);
              font-family: 'RedHat-Bold';
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              height: 30px;
            }

            .follow-btn {
              font-size: 16px;
              background: #C7E3E9;
              border: 1px solid var(--app-004986);
              color: var(--app-004986);
              font-family: 'RedHat-Bold';
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              height: 40px !important;
            }

          }

          .ant-row {
            padding: 10px 20px;

            .content {
              .title {
                font-family: 'RedHat-Bold';
                font-size: 18px;
              }

              .value {
                font-family: 'RedHat-Medium';
                font-size: 18px;

                .history-text {
                  color: var(--app-004986);
                  text-decoration: underline;
                }
              }
            }
          }

          .contract-container {
            margin-top: 10px;
            margin-bottom: 10px;
            background: rgba(18, 130, 159, 0.15);
            border-radius: 5px;
            padding: 5px 10px;
            align-items: center;

            .title {
              font-family: "RedHat-Bold";
              font-size: 20px;
            }

            .value {
              font-family: 'RedHat-Medium';
              font-size: 18px;

              span {
                font-family: "RedHat-Bold";
              }
            }

          }

          .history-text {
            color: var(--app-004986);
            font-family: 'RedHat-Medium';
            font-size: 18px;
          }

          .approved-status {
            margin-top: 10px;
            margin-bottom: 10px;
            background: rgba(32, 205, 122, 0.15);
            border-radius: 5px;
            padding: 10px;
            font-family: 'RedHat-Bold';
            font-size: 20px;
            display: flex;
            align-items: center;

            span {
              font-family: 'RedHat-Medium';
              font-size: 18px;
              margin-left: 5px;
            }
          }

        }
      }
    }
  }

  .radio-container {
    height: 100%;
    background: #F9F9FF !important;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;

    .left-side-top {
      background: #F3F3F9;
      font-size: 16px;
      font-family: "RedHat-Medium";
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      // position: sticky;
      top: 50px;
      // z-index: 99;
    }

    .ant-radio-group {
      padding: 20px;
    }

    .ant-radio-button-wrapper {
      height: 100%;
      margin-bottom: 10px;
      border-radius: 10px !important;
      box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);
    }

    .ant-radio-button {
      box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05) !important;
    }

    .ant-radio-button-wrapper {
      background-color: #FFFFFF !important;
      border: 2px solid #E6E9EE !important;
      box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);
      color: var(--app-646464);
      font-family: 'RedHat-Regular';
      border-radius: 10px !important;
      width: 100%;

      .card-title {
        // margin-top: 5px;
        margin: 10px;
        font-family: 'RedHat-SemiBold';
        font-size: 20px;
        display: flex;
        align-items: center;

        svg {

          // height: 20px !important;
          // width: 25px !important;
          path {
            fill: var(--app-004986) !important;
          }
        }
      }

      .bill-count {
        border-radius: 5px;
        background: #FFFFFF;
        border: 1px solid #E6E9EE !important;
        // border: 1px solid var(--app-004986);
        height: 35px;
        margin-bottom: 10px;
        padding: 5px;
        font-family: 'RedHat-Bold' !important;
        font-size: 16px !important;
        color: var(--app-004986) !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .count {
        border-radius: 5px;
        background-color: var(--app-004986) !important;
        border: 1px solid #E6E9EE !important;
        height: 35px;
        margin-bottom: 10px;
        padding: 5px;
        font-family: 'RedHat-Bold' !important;
        font-size: 16px !important;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .ant-radio-button-wrapper:hover {
      background-color: #FFFFFF !important;
      border: 2px solid #E6E9EE !important;
      box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);
      color: var(--app-646464);
      font-family: 'RedHat-Regular';
      border-radius: 10px !important;
      width: 100%;


    }

    .ant-radio-button-wrapper-checked {
      border-radius: 10px !important;
      background-color: #022f3b !important;
      color: #FEAE3F !important;
      font-family: 'RedHat-Regular';

      .card-title {
        // margin-top: 5px;
        font-family: 'RedHat-SemiBold';
        font-size: 20px;
        display: flex;
        align-items: center;
        margin: 10px;

        svg {

          // height: 20px !important;
          // width: 25px !important;
          path {
            fill: #FEAE3F !important;
          }
        }
      }

      .bill-count {
        border-radius: 5px;
        background: #34b5b13b;
        height: 35px;
        margin-bottom: 10px;
        border: none !important;
        padding: 5px;
        font-family: 'RedHat-Bold' !important;
        font-size: 16px !important;
        color: #FEAE3F !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .count {
        border-radius: 5px;
        background-color: #34b5b13b !important;
        color: #FFFFFF !important;
        border: none !important;
        height: 35px;
        padding: 5px;
        font-family: 'RedHat-Bold' !important;
        font-size: 16px !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .ant-radio-button-checked {
      border-radius: 10px !important;
      background-color: #022f3b !important;
      color: #FFFFFF !important;
      font-family: 'RedHat-Regular';

      .count {
        border-radius: 5px;
        background-color: #34b5b13b !important;
        color: #FFFFFF !important;
        border: none !important;
        height: 35px;
        padding: 5px;
        font-family: 'RedHat-Bold' !important;
        font-size: 16px !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

    }




  }

}

.payment-Container {
  font-family: 'RedHat-SemiBold';
  color: #434343;
  font-size: 16px;
  margin-bottom: 20px;
}

.payment-list-container {

  .ant-table {
    background: none !important;
  }

  .ant-table table {
    border-spacing: 0 10px !important;
  }

  .ant-table-thead>tr>th {
    background: #022E3A;
    padding: 10px 10px !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-family: 'RedHat-Bold' !important;
    text-align: left !important;

  }

  .ant-table-tbody>tr>td {
    //background: rgba(0, 129, 157, 0.1) !important;
    // border-top: 10px solid #ffffff !important;
    padding: 5px 10px;
    background: #FCFCFC;
    border-top: 1px solid #EBEBEB !important;
    border-bottom: 1px solid #EBEBEB !important;
  }

  .ant-table-thead .ant-table-cell {
    border-radius: 0px !important;
  }

  .ant-table-tbody>tr>td:first-child {
    border-left: 1px solid #EBEBEB !important;
  }

  .ant-table-tbody>tr>td:last-child {
    border-right: 1px solid #EBEBEB !important;
  }

  .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 6px !important;
    border-left: 1px solid #E9E9E9 !important;
    border-bottom-left-radius: 6px !important;
  }

  .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 6px !important;
    border-right: 1px solid #E9E9E9 !important;
    border-bottom-right-radius: 6px !important;
  }


  .ant-table-tbody>tr>td:first-child {
    border-top-left-radius: 6px !important;

    border-bottom-left-radius: 6px !important;
  }

  .ant-table-tbody>tr>td:last-child {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;

  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
    font-size: 14px;
    color: black;
    font-family: 'RedHat-SemiBold';
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border-right: 0px !important;
    font-size: 14px;
    color: black;
    font-family: 'RedHat-Medium';
  }

  .ant-table.ant-table-bordered>.ant-table-container {
    border-left: 0px !important;
  }

  .ant-table-tbody>tr.ant-table-row:hover>td,
  .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: #FCFCFC !important;
  }


  .ant-table-pagination.ant-pagination {
    display: none !important;
  }

  .payment-info {
    font-family: 'RedHat-SemiBold';
    color: #3C9A56;
    font-size: 16px;
    margin-bottom: 10px;
    background: #F0FBF3;
    border-radius: 6px;
  }

  .details {
    padding: 15px;
    font-family: 'RedHat-SemiBold';
    color: #434343;
    font-size: 16px;
    background: #EDF6F8;
    border-radius: 6px;

    .user-info {
      display: flex;
      align-items: center;

      .img-container {
        width: 40px;
        height: 40px;
        background-color: var(--app-004986);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

      }

      .user-detail {
        margin-left: 10px;

        .name {
          font-family: 'RedHat-SemiBold';
          color: #434343;
          font-size: 16px;
        }

        .date {
          font-family: 'RedHat-SemiBold';
          color: #757575;
          font-size: 16px;
        }

      }

    }

    .follow-up-info {
      font-family: 'RedHat-SemiBold';
      color: #434343;
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.cancel-btn {
  font-size: 16px;
  color: var(--app-004986);
  background-color: #FFFFFF;
  font-family: 'RedHat-Regular';
  border: 1px solid var(--app-004986);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  margin-right: 10px;
}


.cancel-btn:focus {
  font-size: 16px;
  color: var(--app-004986);
  background-color: #FFFFFF;
  font-family: 'RedHat-Regular';
  border: 1px solid var(--app-004986);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  margin-right: 10px;
}

.cancel-btn:hover {
  font-size: 16px;
  color: var(--app-004986);
  background-color: #FFFFFF;
  font-family: 'RedHat-Regular';
  border: 1px solid var(--app-004986);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  margin-right: 10px;
}

.eye-btn,
.eye-btn:hover,
.eye-btn:focus {
  border: 1px solid var(--app-004986) !important; 
  background-color: var(--app-D9E4ED);
  font-family: 'RedHat-Regular';
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 37px;
  padding: 10px !important;

  svg {
    // margin-top: 3px;
    color: var(--app-004986);
  }

  .anticon {
    color: var(--app-FFFFFF) !important;
    font-size: 20px !important;
  }
}
.onBoardedBtn,
.onBoardedBtn:focus,
.onBoardedBtn:hover{
  font-size: 16px !important;
  color: var(--app-004986);
  background-color: #D9E4ED;
  font-family: 'RedHat-semiBold';
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 37px;
  margin-right: 20px;
  margin-left: 20px;
}

.view-btn,
.view-btn:focus,
.view-btn:hover {
  font-size: 16px !important;
  color: var(--app-FFFFFF);
  background-color: var(--app-004986);
  font-family: 'RedHat-semiBold';
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 37px;
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.follow-btn {
  font-size: 16px !important;
  background: #C7E3E9;
  border: 1px solid var(--app-004986);
  color: var(--app-004986);
  font-family: 'RedHat-semiBold';
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px !important;
  margin-right: 20px;
}

.create-btn {
  font-size: 16px !important;
  color: var(--app-FFFFFF);
  background-color: var(--app-004986);
  font-family: 'RedHat-semiBold';
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  margin-right: 20px;

}

.create-btn:focus {
  font-size: 16px !important;
  color: var(--app-FFFFFF);
  background-color: var(--app-004986);
  font-family: 'RedHat-semiBold';
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  margin-right: 20px;
}

.create-btn:hover {
  font-size: 16px !important;
  color: var(--app-FFFFFF);
  background-color: var(--app-004986);
  font-family: 'RedHat-semiBold';
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  margin-right: 20px;
}

.contracttype-selection {
  font-family: 'RedHat-Regular';


  .ant-radio-wrapper {
    font-size: 16px;
    color: var(--app-2D2D2D);

    span {
      position: relative;
      top: 1px;

    }

  }
}

.select-field {
  .ant-select-selector {
    border: 2px solid #d9d9d9 !important;
    height: 40px !important;
    border-radius: 5px !important;
    align-items: center;
  }

}

.input-field {
  border: 2px solid #d9d9d9 !important;
  height: 37px !important;
  border-radius: 5px !important;
}


.text-field {
  border: 2px solid #d9d9d9 !important;
  border-radius: 5px !important;
}

.ant-drawer-content {
  .btn-container {
    display: flex;
    align-items: center;

    .close-btn {
      margin: 0px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      height: 37px;
      padding: 10px;
      color: #DD3E13 !important;
      background: #FAE2DC !important;
      border: 1px solid #DD3E13 !important;

      svg {
        height: 15px;
        width: 15px;
      }
    }

    .close-btn-add {
      position: absolute;
      top: 20px;
      right: 0;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      color: #DD3E13;
      background: #FAE2DC;
      font-size: 11px;
      height: 37px;
      border-radius: 5px;
    }
  }
}

.ant-checkbox {
  border: 1px solid var(--app-004986);

  .ant-checkbox-inner {
    border: none !important;
  }
}

.ant-checkbox-wrapper {
  font-size: 16px;
  font-family: "RedHat-Bold";
  color: black;
}

.ant-checkbox-checked {
  background-color: var(--app-004986);

  .ant-checkbox-inner {
    background-color: var(--app-004986);
    border: none !important;
  }
}

.ant-modal-confirm-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-btn-primary {
    background: var(--app-004986) !important;
    color: #fff !important;
    border-radius: 5px !important;
    font-family: 'RedHat-SemiBold' !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn-default {
    border: 1px solid var(--app-004986) !important;
    background-color: #FFFFFF;
    color: var(--app-004986) !important;
    border-radius: 5px !important;
    font-family: 'RedHat-SemiBold' !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}




// .warehouse-container {
//   background-size: cover;
//   height: 100vh;
//   .contract-type {
//     padding: 25px;
//     background: var(--app-FAFAFD);
//     box-shadow: 2px 2px 4px rgba(130, 169, 177, 0.13);
//     .title {
//       font-family: 'RedHat-SemiBold';
//       font-weight: 500;
//       font-size: 20px;
//       color: var(--app-2D2D2D);
//     }
//     .contracttype-selection {
//       font-family: 'RedHat-Regular';
//       margin-top: 20px;
//       margin-bottom: 10px;
//       .ant-radio-wrapper {
//         font-size: 20px;
//         color: var(--app-2D2D2D);
//         span{
//           position: relative;
//         top: 1px;

//         }

//       }
//     }
//   }
//   .select-warehouse {
//     padding: 25px;
//     background: var(--app-FAFAFD);
//     box-shadow: 2px 2px 4px rgba(130, 169, 177, 0.13);
//     margin-top: 10px;
//     .select-container {
//       display: flex;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       .title {
//         font-weight: 500;
//         font-size: 20px;
//         color: var(--app-2D2D2D);
//         font-family: 'RedHat-Medium';
//         width: 200px;
//       }
//       .ant-select-multiple .ant-select-selection-item {
//         height: 20px !important;
//         line-height: 15px !important;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding: 10px 15px !important
//       }
//       .ant-select {
//         width: 100%;
//         margin-left: 25px;

//         .ant-select-selector {
//           background: var(--app-fbfbfb);
//           border: 1px solid var(--app-e3e2e3);
//           box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.16);
//           border-radius: 5px;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;

//           span {
//             font-family: 'RedHat-Regular';
//             font-weight: 400;
//             font-size: 16px;
//             color: var(--app-262626);
//           }
//         }
//         .ant-select-arrow .anticon > svg {
//           height: 15px !important;
//           margin-top: 3px !important;
//           color: var(--app-262626);
//         }
//       }
//     }
//     .warehouseList {
//       margin-top: 25px;
//       .title {
//         font-weight: 500;
//         font-size: 19px;
//         color: var(--app-2D2D2D);
//         font-family: 'RedHat-Medium';
//       }
//       .list {
//         margin-top: 10px;
//         .warehouse-name-card {
//           background: var(--app-d5e8ef);
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           padding: 10px;
//           border-radius: 5px;
//           margin-top: 10px;
//           .warehouse-name {
//             color: var(--app-262626);
//             font-size: 16px;
//             font-weight: 400;
//             font-family: 'RedHat-Regular';
//           }
//           .anticon {
//             color: var(--app-b04949);
//             border-radius: 50%;
//             font-size: 25px;
//           }
//         }
//       }
//     }
//   }
// }

.select-right-container {

  // border-left: 1px solid #d1d1d1;
  // height: 100%;
  // background: #F8F8F8;
  .sales-input {
    font-size: 16px;
    font-family: "RedHat-Regular";
    padding: 10px;
    border: none;
    position: sticky;
    z-index: 99;
    /* width: 16%; */
    // top: 50px;

  }

  .input-property {
    font-size: 16px;
    // background: #00819d26;
    font-family: "RedHat-Regular";
    padding: 10px;
    border: none;
    position: fixed;
    z-index: 99;
    width: 284px;
    top: 50px;

    // .ant-input {
    //   background: #00819d26 !important;
    // }
  }

  // .header {
  //   padding: 10px;
  //   background: rgba(0, 129, 157, 0.15);
  //   box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  //   font-family: 'RedHat-Bold';
  //   font-size: 20px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }

  .content-container-po {
    padding-left: 1px;

    .ant-radio-button-wrapper {
      height: 100%;
      margin-bottom: 10px;
    }

    .ant-radio-button-wrapper {
      background: #F8F8F8 !important;
      color: var(--app-646464);
      font-family: 'RedHat-Regular';
      border: none !important;
      width: 100%;

      .card-title-container {
        // margin-top: 5px;
        font-family: 'RedHat-Bold';
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;


        .label {
          font-family: 'RedHat-Bold';
          color: #2D2D2D;
          font-size: 16px;
        }

        .count {
          border-radius: 5px;
          background-color: #FFFFFF !important;
          border: none !important;
          width: 60px;
          font-family: 'RedHat-Bold';
          font-size: 18px;
          color: var(--app-004986);
          display: flex;
          align-items: center;
          justify-content: center;
        }

      }

      .label {
        font-family: 'RedHat-Bold';
        color: #2D2D2D;
        font-size: 16px;
      }

      .count {
        border-radius: 5px;
        background-color: #FFFFFF !important;
        border: none !important;
        width: 60px;
        font-family: 'RedHat-Bold';
        font-size: 25px;
        color: var(--app-004986);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: rgba(254, 174, 63, 0.15) !important;
      color: var(--app-646464);
      font-family: 'RedHat-Regular';

      .card-title {
        font-family: 'RedHat-Bold';
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px !important;
        height: 50px;

      }

      .count {
        border-radius: 5px;
        background-color: #FFFFFF !important;
        border: none !important;
        width: 60px;
        font-family: 'RedHat-Bold';
        font-size: 25px;
        color: var(--app-004986);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-radio-button-checked {
      background: rgba(254, 174, 63, 0.15) !important;
      color: var(--app-646464);
      font-family: 'RedHat-Regular';
      border-left: 5px solid #FEAE3F !important;

      .card-title {
        font-family: 'RedHat-Bold';
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px !important;
      }

      .count {
        border-radius: 5px;
        background-color: #FFFFFF !important;
        border: none !important;
        height: 50px;
        width: 60px;
        font-family: 'RedHat-Bold';
        font-size: 25px;
        color: var(--app-004986);
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

  }

  .content-form-container {
    background: #FFFFFF;
    padding: 20px;

    .btn-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .close-btn {
        font-size: 16px;
        color: var(--app-004986);
        background-color: #D9ECF0;
        border: 2px solid var(--app-004986);
        font-family: 'RedHat-Regular';
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;

      }

      .green-btn {
        margin: 0px 10px;
        font-size: 16px;
        color: #FFFFFF;
        background-color: var(--app-004986);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        height: 40px;
        font-family: 'RedHat-Regular' !important;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .content-container-form {
      background: #F7F7F7;
      border-radius: 10px;
      padding: 10px;
      padding: 10px 20px;

      .label {
        font-size: 14px !important;
        color: var(--app-black) !important;
        font-family: 'RedHat-Bold' !important;
        margin-bottom: 10px;
      }

      .type {
        background: rgba(0, 129, 157, 0.15);
        border-radius: 5px;
        padding: 5px 10px;
        font-family: 'RedHat-Bold' !important;
        width: 300px
      }

      .dropdown {
        svg {
          height: 20px !important;
          width: 20px !important;
        }
      }

      .btn-container {}

      .detail-container {
        background: #FFFFFF;
        border-radius: 5px;
        padding: 5px 20px;
        margin-top: 10px;
      }

      .contact-container {
        background: rgba(0, 129, 157, 0.15);
        border-radius: 5px;
        padding: 10px 20px;
        margin-top: 10px;
      }

      .ant-checkbox {
        border: 1px solid var(--app-004986);

        .ant-checkbox-inner {
          border: none !important;
        }
      }

      .ant-checkbox-wrapper {
        font-size: 16px;
        font-family: "RedHat-Bold";
        color: black;
      }

      .ant-checkbox-checked {
        background-color: var(--app-004986);

        .ant-checkbox-inner {
          background-color: var(--app-004986);
          border: none !important;
        }
      }

      .ant-row {
        .ant-input {
          height: 40px;
          background: #FFFFFF;
          border: 2px solid #d9d9d9 !important;
          border-radius: 4px;
          font-family: 'RedHat-Regular';
        }

        .ant-select.ant-select-in-form-item {
          width: 100% !important;

        }

        .ant-select-selector {
          border: 2px solid #d9d9d9 !important;
          height: 40px !important;
          border-radius: 5px !important;
          align-items: center;
          font-family: 'RedHat-Regular';
        }

        .ant-select-arrow {
          svg {
            path {
              fill: black !important
            }
          }
        }
      }
    }
  }

  .view-part-container {
    margin: 20px;
    padding: 20px;
    background: linear-gradient(86.22deg, var(--app-004986)0%, #02A5C9 100%);
    border-radius: 8px;

    .col-border {
      border-right: 2px solid white;
    }

    .client-name {
      font-family: 'RedHat-Bold';
      font-size: 20px;
      color: #FFFFFF;
    }

    .content {
      margin-top: 15px;

      .title {
        font-family: 'RedHat-SemiBold';
        font-size: 18px;
        color: #FFFFFF;
      }

      .value {
        font-family: 'RedHat-Regular';
        font-size: 18px;
        color: #FFFFFF;
        margin-top: 5px;
      }

      .history-text {
        font-family: 'RedHat-Regular';
        font-size: 18px;
        color: #FEAE3F !important;
        margin-top: 5px;
      }
    }

  }


  .content-view-container {
    // border-left: 1px solid #d1d1d1;
    height: 100%;
    //background: #F8F8F8;


    .view-content-design {
      position: absolute;
      top: 90%;
      left: 53%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      padding-bottom: 60px;

    }

    .view-content {
      padding: 10px !important;

      .content-box-design {
        border: 2px solid #CECECE;
        box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px !important;
      }

      .content-box {
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
      }


      .content-box {
        padding: 10px;
        background-color: #FFFFFF;
        border-radius: 5px;
        margin-bottom: 10px;
        width: 100%;

        .other-warehouse-col {
          border-left: 2px solid #A5A5A5;
          padding-left: 20px !important;
        }

        .content-title {
          font-family: 'RedHat-Bold';
          font-size: 14px;
          color: #2D2D2D;
        }

        .content {
          font-family: 'RedHat-Medium';
          font-size: 14px;
          color: #2D2D2D;
        }

        .view-industry {
          background: rgba(0, 129, 157, 0.15);
          border-radius: 10px;
          padding: 5px 10px;
          font-family: 'RedHat-Bold';
          font-size: 16px;

          svg {
            path {
              fill: var(--app-004986) !important
            }
          }
        }

        .detailed-view {
          background: #F1F1F1;
          border-radius: 10px;
          padding: 10px;

          .role-container {
            background: rgba(0, 129, 157, 0.15);
            border-radius: 10px;
            padding: 5px 10px;

            .name {
              font-family: 'RedHat-Bold';
              font-size: 16px;

              span {
                font-family: 'RedHat-Medium';
                font-size: 16px;
                margin-left: 5px;
              }
            }

            .role {
              font-family: 'RedHat-medium';
              font-size: 16px;
            }

            .dropdown {
              svg {
                height: 25px !important;
                width: 25px !important;
              }
            }
          }

          .contact-box {
            padding: 10px;

            svg {
              height: 16px;
              width: 16px;
            }

            span {
              margin-left: 10px;
              font-family: 'RedHat-Medium';
              font-size: 16px;

            }
          }
        }

        .warehouse-container {
          padding: 5px 10px;
          background: rgba(45, 45, 45, 0.1);
          border-radius: 5px;
          font-family: 'RedHat-Bold';
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .select {
            color: var(--app-004986);
            font-size: 16px;
            text-decoration: underline;
          }

          .create-btn {
            font-size: 16px;
            color: #FFFFFF;
            background-color: var(--app-004986);
            font-family: 'RedHat-Bold';
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 25px;
          }

        }

        .warehouse-create-container {
          padding: 10px;
          background: rgba(45, 45, 45, 0.1);
          border-radius: 5px;
          font-family: 'RedHat-Bold';
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .view-payment {
            margin-right: 10px;

            svg {
              margin-right: 5px;
              width: 20px;
              height: 20px;

              path {
                fill: var(--app-004986) !important;
              }
            }

            .label {
              font-size: 16px;
              color: var(--app-004986);
              font-family: 'RedHat-Regular';
            }
          }

          .create-btn {
            font-size: 16px;
            color: #FFFFFF;
            background-color: var(--app-004986);
            font-family: 'RedHat-Bold';
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 30px;
          }

          .follow-btn {
            font-size: 16px;
            background: #C7E3E9;
            border: 1px solid var(--app-004986);
            color: var(--app-004986);
            font-family: 'RedHat-Bold';
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 40px !important;
          }

        }

        .ant-row {
          padding: 10px 20px;

          .content {
            .title {
              font-family: 'RedHat-Bold';
              font-size: 18px;
            }

            .value {
              font-family: 'RedHat-Medium';
              font-size: 18px;

              .history-text {
                color: var(--app-004986);
                text-decoration: underline;
              }
            }
          }
        }

        .contract-container {
          margin-top: 10px;
          margin-bottom: 10px;
          background: rgba(18, 130, 159, 0.15);
          border-radius: 5px;
          padding: 5px 10px;
          align-items: center;

          .title {
            font-family: "RedHat-Bold";
            font-size: 20px;
          }

          .value {
            font-family: 'RedHat-Medium';
            font-size: 18px;

            span {
              font-family: "RedHat-Bold";
            }
          }

        }

        .history-text {
          color: var(--app-004986);
          font-family: 'RedHat-Medium';
          font-size: 18px;
        }

        .approved-status {
          margin-top: 10px;
          margin-bottom: 10px;
          background: rgba(32, 205, 122, 0.15);
          border-radius: 5px;
          padding: 10px;
          font-family: 'RedHat-Bold';
          font-size: 20px;
          display: flex;
          align-items: center;

          span {
            font-family: 'RedHat-Medium';
            font-size: 18px;
            margin-left: 5px;
          }
        }

      }
    }
  }
}

.radio-background {
  background: #F8F8F8;
  border-radius: 4px;
  width: 100%;
  padding: 8px !important;
}

.date-picker {
  border-radius: 4px !important;
  width: 100% !important;
}

.comment {
  border-radius: 4px !important;
}

.input-for-so {
  border-radius: 4px !important;
}

.readable {
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  background: #F8F8F8;

}