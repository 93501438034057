.inventory-container {
    padding: 20px 20px;

    .piece-box-container {

        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 16px !important;
            text-decoration: underline;
            color: var(--app-004986)!important;
            font-family: "RedHat-Medium";
        }

        .ant-picker {
            border: 2px solid #d9d9d9 !important;
            border-radius: 5px !important;
            height: 40px;
        }
    }

    .date-container {
        font-family: "RedHat-Medium";
        font-size: 16px;
        margin-right: 15px;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #E8F6EF;
        border: 1px solid #1BAB66;
        color: #1BAB66;


    }

    .export-btn {
        border: 1px solid var(--app-004986);
        border-radius: 5px;
        background-color: #D9E4ED;
        font-family: "RedHat-Medium";
        font-size: 16px;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px !important;
            height: 20px !important;

            path {
                fill: var(--app-004986) !important
            }
        }

        div {
            margin-left: 5px;
        }
    }

    .select-container {
        display: flex;
        align-items: center;
        width: 100%;


        .ant-form-item {
            margin-bottom: 0px !important;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 10px;
            cursor: pointer;

            .label {
                font-family: "RedHat-SemiBold";
                font-size: 16px;
                color: #4D4D4D
            }

            .ant-select-in-form-item {
                width: none !important;
            }

            .ant-select-selector {
                border: 2px solid #d9d9d9 !important;
                height: 40px !important;
                border-radius: 10px !important;
                align-items: center;
                font-family: "RedHat-Medium";
                font-size: 16px;

                svg {
                    height: 10px !important;
                    width: 10px !important;
                }
            }

            // .ant-select-selection-search{
            //     cursor: none;
            // }

        }
    }

    .Info {
        font-family: "RedHat-Bold";
        font-size: 20px;
        color: var(--app-004986);
        text-align: center;
        margin-top: 20px;

    }

    .overall-allocated-inventory-container {
        background: #FBFCFC;
        border-radius: 30px;
        margin-top: 20px;
        padding: 20px;
        box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);

        // canvas {
        //     width: 300px !important;
        //     height: 250px !important;
        // }

        .ant-row {
            width: 100%;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-family: "RedHat-SemiBold";
                font-size: 20px;
                color: #4D4D4D
            }

            .view-container {
                display: flex;
                align-items: center;

                svg {
                    width: 20px !important;
                    height: 20px !important;
                }

                span {
                    font-family: "RedHat-Medium";
                    font-size: 16px;
                    color: var(--app-004986)!important;
                    margin-left: 5px;
                }
            }

        }



        .table-container {
            background: #FBFCFC;
            border: 1px solid #EFF0F6;
            box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
            border-radius: 20px;
            margin-top: 10px;
            height: 250px !important;
            padding: 10px;

            .table-screen-container {
                height: 100% !important;
            }
        }



        .bar-container {
            background: #FFFFFF;
            border: 1px solid #EFF0F6;
            border-radius: 20px;
            // box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
            padding: 15px;
            height: 330px;
            margin-top: 24px;

            .total-label {
                font-family: "RedHat-SemiBold";
                font-size: 18px;
                color: #000000;

                span {
                    font-family: "RedHat-Bold";
                    font-size: 20px;
                    color: #000000;
                    margin-left: 5px;
                }
            }

            .content-percentage-container {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .color-box {
                    height: 10px;
                    width: 15px;
                }

                .content {
                    font-family: "RedHat-SemiBold";
                    font-size: 16px;
                    color: #000000;


                }

            }

            .total-Count {
                color: #000000;
                font-size: 18px;
                font-family: "RedHat-Bold";
            }
        }
    }

    .analytics-overall-container {
        background: #FBFCFC;
        border-radius: 30px;
        margin-top: 20px;
        padding: 20px;

        .ant-row {
            width: 100%;
        }

        .analytics-info-container {
            margin-bottom: 10px;

            hr {
                background-color: #4D4D4D;
            }

            .title {
                font-family: "RedHat-SemiBold";
                font-size: 18px;
                color: #000000;
            }

            .view-container {
                display: flex;
                align-items: center;

                svg {
                    width: 20px !important;
                    height: 20px !important;
                }

                span {
                    font-family: "RedHat-Medium";
                    font-size: 16px;
                    color: var(--app-004986)!important;
                    margin-left: 5px;
                }
            }

            .ant-select-selector {
                border: 2px solid #d9d9d9 !important;
                height: 30px !important;
                border-radius: 20px !important;
                align-items: center;
                margin-left: 10px;
                width: 150px;
                font-family: "RedHat-Medium";
                font-size: 16px;

                svg {
                    height: 10px !important;
                    width: 10px !important;
                }
            }

            .info-analytics {
                display: flex;
                align-items: center;



                .content-percentage-container {
                    display: flex;
                    align-items: center;

                    .inward-color-box {
                        height: 10px;
                        width: 15px;
                        background-color: #2A64F8;
                    }

                    .outward-color-box {
                        height: 10px;
                        width: 15px;
                        background-color: #4FE3ED;
                    }

                    .content {
                        font-family: "RedHat-SemiBold";
                        font-size: 18px;
                        color: rgba(0, 0, 0, 0.7);
                        margin-left: 10px;


                    }

                }

            }


        }


        canvas {
            width: 100% !important;
            height: 200px !important;
        }
    }

    .inventory-barchart-container {
        background: #FBFCFC;
        border-radius: 30px;
        margin-top: 20px;
        padding: 20px;
        box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);

        // canvas {
        //     width: 200px !important;
        //     height: 200px !important;
        // }

        .ant-row {
            width: 100%;
        }

        .inventory-info {
            text-align: center;

            .inventory-header {
                font-family: "RedHat-SemiBold";
                font-size: 20px;
                color: #4D4D4D
            }

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                div {
                    font-family: "RedHat-SemiBold";
                    font-size: 20px;
                    color: #4D4D4D
                }

                .view-container {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 20px !important;
                        height: 20px !important;
                    }

                    span {
                        font-family: "RedHat-Medium";
                        font-size: 16px;
                        color: var(--app-004986)!important;
                    }
                }

            }

            .info {
                font-family: "RedHat-Regular";
                font-size: 18px;
                color: #4D4D4D
            }
        }

        .bar-container {
            background: #FFFFFF;
            border: 1px solid #EFF0F6;
            border-radius: 20px;
            // box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
            padding: 15px;
            height: 100%;

            .total-label {
                font-family: "RedHat-SemiBold";
                font-size: 18px;
                color: #000000;

                span {
                    font-family: "RedHat-Bold";
                    font-size: 20px;
                    color: #000000;
                    margin-left: 5px;
                }
            }

            .total-Count {
                color: #000000;
                font-size: 18px;
                font-family: "RedHat-Bold";
            }

            .content-percentage-container {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .color-box {
                    height: 10px;
                    width: 15px;
                }

                .content {
                    font-family: "RedHat-SemiBold";
                    font-size: 16px;
                    color: #000000;


                }

            }

        }

        .main-container {
            position: relative;
            height: 100%;

            .sellable-container {
                background: #FFFFFF;
                border: 1px solid #EFF0F6;
                border-radius: 20px;
                box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
                padding: 20px;
                height: 165px;

                .label {
                    font-family: "RedHat-SemiBold";
                    font-size: 18px;
                    color: #000000;
                    margin-top: 40px;
                }

                .info-container {
                    height: 150px;
                    overflow: scroll;
                }

                .info {
                    font-family: "RedHat-Medium";
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.7);
                }

                .good-Count {
                    color: #57BFAA;
                    font-size: 20px;
                    font-family: "RedHat-Bold";

                }

                .bad-Count {
                    color: #CC3333;
                    font-size: 20px;
                    font-family: "RedHat-Bold";

                }

            }

            .sellable-icon-container {

                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background: #DDF2EE;
                position: absolute;
                top: 8%;
                left: 18%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                width: 70px;
                height: 60px;


                svg {
                    height: 40px !important;
                    width: 40px !important;
                }
            }

            .non-sellable-icon-container {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background: #F5D6D6;
                position: absolute;
                top: 8%;
                left: 18%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                width: 70px;
                height: 60px;


                svg {
                    height: 40px !important;
                    width: 40px !important;
                }

            }

        }
    }
}

.inventory-Info {
    color: #CC3333;
    font-size: 20px;
    font-family: "RedHat-Bold";
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: #FFFFFF;
    border: 1px solid #EFF0F6;
    border-radius: 20px;
    box-shadow: 1.95px 1.95px 2.6px rgba(33, 59, 109, 0.06);
    padding: 20px;

}

.chart-container {
    width: 250px !important;
    height: 250px !important;
}

.char-container {
    width: 150px !important;
    height: 150px !important;
}