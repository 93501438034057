.wareHouse-title-header{
    padding: 10px;
    background-color: #D9ECF0;
    align-items: center;
    position: fixed;
    z-index: 99;
    // width: 90vw;
    right: 0px;
    left:0%;
    .title {
        font-size: 16px;
        font-family: 'RedHat-Bold';

        span {
            font-size: 16px;
            font-family: 'RedHat-Medium';
        }

        .wms-client-name {
            font-size: 14px !important;
            font-family: 'RedHat-Regular' !important;

            .client-name {
                font-size: 14px !important;
                font-family: 'RedHat-SemiBold' !important;
            }
        }

        .piece-box-container {

            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                font-size: 16px !important;
                text-decoration: underline;
                // color: var(--app-004986);
            }

            .ant-picker {
                border: 2px solid #d9d9d9 !important;
                border-radius: 5px !important;
                height: 40px;
            }
        }
    }

    .batch-box {
        background: #7ABDCB;
        border-radius: 5px;
        padding: 6px;
        font-size: 14px;
        font-family: 'RedHat-SemiBold';
        margin-right: 10px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .generate-box {
        background: #C9C9C9;
        border-radius: 5px;
        padding: 6px;
        font-size: 14px;
        font-family: 'RedHat-SemiBold';
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
    }

    .reQc{
        background: #FFFFFF;
        border: 1px solid var(--app-004986);
        border-radius: 5px;
        padding: 3px 7px;
        font-size: 16px;
        font-family: 'RedHat-SemiBold';
        color: var(--app-004986);
        align-items: center;
        display: flex;
        justify-content: center;
        height: 40px;
        cursor: pointer;
    }
    .expense{
        background: #FFFFFF;
        border: 1px solid var(--app-004986);
        border-radius: 5px;
        padding: 3px 7px;
        font-size: 16px;
        font-family: 'RedHat-SemiBold';
        color: var(--app-004986);
        align-items: center;
        height: 40px;
        width:190px;
        cursor: pointer;

        svg {
            height: 20px !important;
            width: 25px !important;
        }
    }
    .date-select{
        width:190 !important;
        border: 1px solid  var(--app-004986)!important;
        height: 40px !important;
        border-radius: 5px !important;
        font-family: "RedHat-SemiBold";
        cursor: pointer;
    }

    .csv-box {
        background: #FFFFFF;
        border: 1px solid var(--app-004986);
        border-radius: 5px;
        padding: 0px 6px;
        font-size: 12px;
        font-family: 'RedHat-Bold';
        color: var(--app-004986);
        align-items: center;

        div {
            margin-top: -5px;
        }

        svg {
            height: 15px !important;
            width: 25px !important;
        }
    }

    .input {
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-radius: 5px;
        margin-right: 10px;
        width: 340px;
        height: 35px;
    }

    .user-details {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 5px;

        .manager-type {
            font-size: 14px;
            font-family: 'RedHat-Bold';
        }

        .user-img {
            width: 30px !important;
            height: 30px !important;
            border-radius: 50%;
        }

        .manager-name {
            font-size: 14px;
            font-family: 'RedHat-Bold';
        }
    }

    .search-help {
        display: flex;
        align-items: center;
        border-radius: 5px;

        .search-input {
            border-radius: 5px;

            .ant-input {
                height: 30px;
            }
        }

        .help-icon {
            margin-left: 5px;
        }
    }

    .header-pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .ant-pagination-item {
            border: none !important;
            background-color: #D9ECF0;
        }

        .ant-pagination-item-active {
            border-radius: 50%;
            background-color: #27253D;
        }

        .ant-pagination-item-link {
            border: none !important;
            background-color: #D9ECF0;

            .anticon {
                font-size: 20px;
                margin-top: 5px;
            }
        }


    }

    .anticon {
        background: none !important;
    }

    .ant-input-affix-wrapper {
        width: 100% !important;
        border: 2px solid #d9d9d9 !important;
        height: 40px;
        border-radius: 5px !important;
    }

    .btn-container {
        display: flex;
        align-items: center;
        align-items: center !important;

        .complete-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
        }

        .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
            stroke: var(--app-004986) !important;
        }

        .ant-progress-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
        }

        .ant-btn {
            padding: 10px !important;
        }

        .green-btn {
            // margin-right: 15px;
            font-size: 25px;
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
        }

        .green-btn-1 {
            // margin-right: 15px;
            font-size: 25px;
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
            cursor: not-allowed;
        }

        .sendrip {
            // margin-left: 5px;
            font-size: 18px;
            font-family: "RedHat-Regular";
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
            margin-right: 15px;
        }


        .close-btn {
            margin-left: 15px;
            font-size: 20px;
            color: var(--app-f34436);
            background-color: #FAE2DC;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
            border: 1px solid var(--app-f34436);
        }

        .close-btn-3 {
            margin-left: 15px;
            font-size: 20px;
            color: var(--app-f34436);
            background-color: #FAE2DC;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
            border: 1px solid var(--app-f34436);
        }
    }
}

.title-header {
    padding: 15px;
    .title {
        font-size: 16px;
        font-family: 'RedHat-Bold';

        span {
            font-size: 16px;
            font-family: 'RedHat-Medium';
        }

        .wms-client-name {
            font-size: 14px !important;
            font-family: 'RedHat-Regular' !important;

            .client-name {
                font-size: 14px !important;
                font-family: 'RedHat-SemiBold' !important;
            }
        }

        .piece-box-container {

            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                font-size: 16px !important;
                text-decoration: underline;
                // color: var(--app-004986);
            }

            .ant-picker {
                border: 2px solid #d9d9d9 !important;
                border-radius: 5px !important;
                height: 40px;
            }
        }
    }

    .batch-box {
        background: #7ABDCB;
        border-radius: 5px;
        padding: 6px;
        font-size: 14px;
        font-family: 'RedHat-SemiBold';
        margin-right: 10px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .generate-box {
        background: #C9C9C9;
        border-radius: 5px;
        padding: 6px;
        font-size: 14px;
        font-family: 'RedHat-SemiBold';
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
    }

    .reQc{
        background: #FFFFFF;
        border: 1px solid var(--app-004986);
        border-radius: 5px;
        padding: 3px 7px;
        font-size: 16px;
        font-family: 'RedHat-SemiBold';
        color: var(--app-004986);
        align-items: center;
        display: flex;
        justify-content: center;
        height: 40px;
        cursor: pointer;
    }

    .expense{
        background: #FFFFFF;
        border: 1px solid var(--app-004986);
        border-radius: 5px;
        padding: 3px 7px;
        font-size: 16px;
        font-family: 'RedHat-SemiBold';
        color: var(--app-004986);
        align-items: center;
        height: 40px;
        width:190px;
        cursor: pointer;

        svg {
            height: 20px !important;
            width: 25px !important;
        }
    }
    .date-select{
        width:190 !important;
        border: 1px solid  var(--app-004986)!important;
        height: 40px !important;
        border-radius: 5px !important;
        font-family: "RedHat-SemiBold";
        cursor: pointer;
    }

    .csv-box {
        // background: #FFFFFF;
        background: var(--app-D9E4ED);
        border: 1px solid  var( --app-004986);
        border-radius: 5px;
        padding: 0px 6px;
        font-size: 12px;
        font-family: 'RedHat-Bold';
        color: var(--app-004986);
        align-items: center;

        div {
            margin-top: -5px;
        }

        svg {
            height: 15px !important;
            width: 25px !important;
            color: var(--app-D9E4ED);
        }
    }

    .input {
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-radius: 5px;
        margin-right: 10px;
        width: 340px;
        height: 35px;
    }

    .user-details {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 5px;

        .manager-type {
            font-size: 14px;
            font-family: 'RedHat-Bold';
        }

        .user-img {
            width: 30px !important;
            height: 30px !important;
            border-radius: 50%;
        }

        .manager-name {
            font-size: 14px;
            font-family: 'RedHat-Bold';
        }
    }

    .search-help {
        display: flex;
        align-items: center;
        border-radius: 5px;

        .search-input {
            border-radius: 5px;

            .ant-input {
                height: 30px;
            }
        }

        .help-icon {
            margin-left: 5px;
        }
    }

    .header-pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .ant-pagination-item {
            border: none !important;
            background-color: #D9ECF0;
        }

        .ant-pagination-item-active {
            border-radius: 50%;
            background-color: #27253D;
        }

        .ant-pagination-item-link {
            border: none !important;
            background-color: #D9ECF0;

            .anticon {
                font-size: 20px;
                margin-top: 5px;
            }
        }


    }

    .anticon {
        background: none !important;
    }

    .ant-input-affix-wrapper {
        width: 100% !important;
        border: 2px solid #d9d9d9 !important;
        height: 40px;
        border-radius: 5px !important;
    }

    .btn-container {
        display: flex;
        align-items: center;
        align-items: center !important;

        .complete-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
        }

        .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
            stroke: var(--app-004986) !important;
        }

        .ant-progress-text {
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
            color: var(--app-004986);
        }

        .ant-btn {
            // padding: 10px !important;
        }

        .green-btn {
            // margin-right: 15px;
            font-size: 25px;
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
        }

        .green-btn-1 {
            // margin-right: 15px;
            font-size: 25px;
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
            cursor: not-allowed;
        }

        .sendrip {
            // margin-left: 5px;
            font-size: 18px;
            font-family: "RedHat-Regular";
            color: var(--app-FFFFFF);
            background-color: var(--app-004986);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
            margin-right: 15px;
        }


        .close-btn {
            margin-left: 15px;
            font-size: 20px;
            color: var(--app-f34436);
            background-color: #FAE2DC;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
            border: 1px solid var(--app-f34436);
        }

        .close-btn-3 {
            margin-left: 15px;
            font-size: 20px;
            color: var(--app-f34436);
            background-color: #FAE2DC;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            height: 37px;
            border: 1px solid var(--app-f34436);
        }
    }
}
.preview-button-container {
    background: white;
    color: var(--app-004986)!important;
    background-color: var( --app-D9E4ED);
    border: 1px solid var(--app-004986);
    border-radius: 6px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 50px;
    height: 40px;
    margin-right: 10px;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);
    padding: 10px;
    font-size: 9px;
    font-family: "RedHat-SemiBold";
    .ant-dropdown-trigger{
        margin-top: 10px;
    }

    svg{
        width: 20px !important;
        height: 20px !important;
        path{
            fill: var(--app-004986) !important;
        }
    }

    a {
        color: var(--app-004986);
        font-family: 'RedHat-SemiBold';
    }
}

.ant-picker-suffix{
    svg{
        path{
            fill: var(--app-004986) !important;
        }
    }
}