@import "../../Globals/ColorVariables.css";

.pending-san-view-container {
    padding: 5px;
    background-color: var(--app-FFFFFF);
    border-radius: 10px;

    .dropdown {
        color: var(--app-004986) !important;
        font-size: 16px !important;
        background-color: var(--app-D9E4ED);
        border: 1px solid var(--app-004986);
        font-family: 'RedHat-Regular';
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 37px;
        width: 37px;
        padding: 10px !important;
        margin-left: 43%;

    }

    .ant-table {
        .unloadiing-txt {
            font-size: 14px;
            color: var(--app-FEAD3F);
            font-family: 'RedHat-Medium';
        }

        .stickering-txt {
            font-size: 14px;
            color: var(--app-004986);
            font-family: 'RedHat-Medium';
        }
    }

    .ant-table-pagination.ant-pagination {
        display: none !important;
    }

    .ant-table-thead>tr>th {
        background-color: #D9ECF0 !important;
        padding: 5px 15px !important;
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;
        // border-bottom: 2px solid var(--app-004986) !important;
    }

    .ant-table-tbody>tr>td {
        padding: 5px 15px;
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-Regular';
    }

    .ant-table {
        background: none !important;
    }

    .ant-table table {
        border-spacing: 0 10px !important;
    }

    .ant-table-thead>tr>th {

        background: var(--app-505050) !important;
        padding: 10px 10px !important;
        font-size: 14px !important;
        color: var(--app-FFFFFF) !important;
        font-family: 'RedHat-regular' !important;
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;
    }

    .ant-table-tbody>tr>td {
        background: #FCFCFC !important;
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;
        padding: 5px 10px;
        box-shadow: 1px 1px 1px #DADADA;
    }

    .ant-table-container table>thead>tr:first-child th:first-child {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        border-left: 1px solid #EBEBEB !important;
    }

    .ant-table-container table>thead>tr:first-child th:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        border-right: 1px solid #EBEBEB !important;
    }


    .ant-table-tbody>tr>td:first-child {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        border-left: 1px solid #EBEBEB !important;

    }

    .ant-table-tbody>tr>td:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        border-right: 1px solid #EBEBEB !important;

    }


    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-SemiBold';
    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
        border-right: 0px !important;
        font-size: 14px;
        color: var(--app-black);
        font-family: 'RedHat-Medium';
    }

    .ant-table.ant-table-bordered>.ant-table-container {
        border-left: 0px !important;
    }


}

.capture-invoice-container {
    .capture-invoice-table-container {
        // .Assignee-content {
        //     font-size: 14px;
        //     color: var(--app-004986);
        //     font-family: 'RedHat-Bold';

        //     img {
        //         width: 30px !important;
        //         height: 30px !important;
        //         border-radius: 50%;
        //     }

        //     span {
        //         margin-left: 5px;
        //         font-size: 14px !important;
        //         color: var(--app-004986) !important;
        //         font-family: 'RedHat-Medium' !important;
        //     }
        // }
        .dropdown {
            color: var(--app-004986) !important;
            font-size: 20px !important;

            .anticon {
                color: var(--app-004986) !important;

            }

        }

        .ant-table {
            background: none !important;
        }

        .ant-table-thead>tr>th {
            background-color: rgba(45, 45, 45, 0.15) !important;
            padding: 5px 15px !important;
            // border-bottom: 10px solid #ffffff;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
        }

        .ant-table-tbody>tr>td {
            border-bottom: 10px solid #ffffff;
            padding: 0px 15px;
        }

        // .ant-table-tbody > tr:first-child td:first-child{
        //     border-top-left-radius: 10px !important;

        //     border-bottom-left-radius: 10px !important;
        // }
        // .ant-table-tbody > tr:first-child  td:last-child{
        //     border-top-right-radius: 10px !important;
        //     border-bottom-right-radius: 10px !important;

        // }

        // .ant-table-container table>thead>tr:first-child th:first-child {
        //     border-top-left-radius: 10px !important;

        //     border-bottom-left-radius: 10px !important;
        // }

        // .ant-table-container table>thead>tr:first-child th:last-child {
        //     border-top-right-radius: 10px !important;
        //     border-bottom-right-radius: 10px !important;
        // }


        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Bold';
            border-right: 0px !important;
        }

        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
            border-right: 0px !important;
            background-color: #f0f0f0 !important;
            font-size: 14px;
            font-family: 'RedHat-SemiBold';
        }

        .ant-table.ant-table-bordered>.ant-table-container {
            border-left: 0px !important;
        }

        .ant-table-tbody>tr.ant-table-row:hover>td,
        .ant-table-tbody>tr>td.ant-table-cell-row-hover {
            background: none !important;
        }

        .ant-table-pagination.ant-pagination {
            display: none !important;
        }
    }

    .invoice-table {
        padding: 20px;

        .invoice-header {
            background: var(--app-A0BCD2);
            font-family: 'RedHat-Bold' !important;
            padding: 10px;
            color: var(--app-151515);
            border-radius: 5px;
        }

        .selected-row {
            background: var(--app-EFFCFF);
            font-family: 'RedHat-Medium' !important;
            padding: 8px;
            color: var(--app-black);
            border-radius: 5px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            border: 1px solid #EBEBEB !important;
            box-shadow: 1px 1px 1px #dadada;

            .open-icon {
                display: flex;
                align-items: center;

                .arrow {
                    color: var(--app-004986);
                    cursor: pointer;
                    font-size: 20px;
                    margin-right: 5px;
                }

                .arrow-right {
                    transform: rotate(0deg);
                    transition: all 0.25s ease-in-out;
                }

                .arrow-down {
                    transform: rotate(90deg);
                    transition: all 0.25s ease;
                }
            }
        }

        .invoice-body {
            // background: rgba(0, 129, 157, 0.1);
            background: #FCFCFC;
            font-family: 'RedHat-Medium' !important;
            padding: 8px;
            color: var(--app-black);
            border-radius: 5px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            border: 1px solid #EBEBEB !important;
            box-shadow: 1px 1px 1px #dadada;

            .open-icon {
                display: flex;
                align-items: center;

                .arrow {
                    color: var(--app-004986);
                    cursor: pointer;
                    font-size: 20px;
                    margin-right: 5px;
                }

                .arrow-right {
                    transform: rotate(0deg);
                    transition: all 0.25s ease-in-out;
                }

                .arrow-down {
                    transform: rotate(90deg);
                    transition: all 0.25s ease;
                }
            }
        }

        .mt-10 {
            margin-top: 10px;
        }
    }

}

.sendsan-main-container {
    .w-100 {
        .t-head {
            border-bottom: 1px solid var(--app-004986);
        }
    }
}

.drawer-view {
    padding: 20px;

    .green-btn,
    .green-btn:hover {
        margin-left: 5px;
        font-size: 25px;
        color: var(--app-FFFFFF) !important;
        background: var(--app-004986) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 3px;
    }

    .ant-select-selector {
        border-radius: 4px !important;
        height: 35px;
    }

    .ant-drawer-right>.ant-drawer-content-wrapper {
        margin-top: 0 !important;
    }

    .fields {
        padding: 10px;
        margin-bottom: 10px;

        .title-field {
            color: var(--app-505050);
            font-family: 'RedHat-Bold';
            font-size: 15px;
        }

        .value-field {
            color: var(--app-505050);
            font-family: 'RedHat-medium';
        }
    }

}

.drawer-view-verified {
    .ant-drawer-title {
        background: var(--app-A0BCD2) !important;
    }

    .icon-container {
        background: #FDFFFF;
        border: 1px solid #EEEEEE;
        padding: 8px;
    }

    .sku-desc {
        font-family: 'Redhat-Medium';
    }

    .order-id {
        font-family: 'Redhat-Medium';
    }

    .brand-icon {
        background: #FFFFFF;
        border: 1px solid #ECECEC;
        border-radius: 50px;
        width: fit-content;
        padding-left: 8px;
        padding-right: 8px;
    }

    .time {
        background: #BBE6D1;
        color: #0A3E25;
        border-radius: 50px;
        padding-left: 15px;
        padding-right: 15px;
        font-family: 'Redhat-Medium';
        width: fit-content;
        margin-left: 15px;
    }
}

.table-view {
    padding: 10px;

    .table-header {
        background: var(--app-A0BCD2);
        font-family: 'RedHat-Bold' !important;
        padding: 10px;
        color: var(--app-151515);
        border-radius: 5px;
    }

    .table-body-blue {
        background: var(--app-EFFCFF);
        border: 1px solid #F2F8FA;
        box-shadow: 1px 1px 1px #DADADA;
        font-family: 'RedHat-Medium' !important;
        padding: 8px;
        color: var(--app-black);
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
        align-items: center;

        .gateinid {
            color: var(--app-004986);
        }

        .open-icon {
            display: flex;
            align-items: center;

            .arrow {
                color: var(--app-004986);
                cursor: pointer;
                font-size: 20px;
                margin-right: 5px;
            }

            .arrow-right {
                transform: rotate(0deg);
                transition: all 0.25s ease-in-out;
            }

            .arrow-down {
                transform: rotate(90deg);
                transition: all 0.25s ease;
            }
        }

        .img-comtainer {
            background: var(--app-E9E9E9);
            font-family: "RedHat-Regular";
            padding: 2px 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            margin: 3px;
            color: var(--app-004986);
            text-decoration: underline;
            width: 150px;
            height: 100px;

            .ant-btn {
                border-radius: 50%;
                width: 20px !important;
                height: 20px !important;
                padding: 0px !important;
                justify-content: center;
                align-items: center;
            }

            img {
                max-height: 100%;
                width: 100%;
            }

            .delete-btn {
                position: absolute;
                top: 20px;
                left: 90%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                color: var(--app-FFFFFF);
                background-color: var(--app-004986);
                font-size: 12px;
                border: none !important;
            }
        }
    }

    .table-body {
        background: #FCFCFC;
        font-family: 'RedHat-Medium' !important;
        padding: 8px;
        color: var(--app-black);
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        box-shadow: 1px 1px 1px #DADADA;
        border: 1px solid #EBEBEB !important;

        .gateinid {
            color: var(--app-004986);
        }

        .open-icon {
            display: flex;
            align-items: center;

            .arrow {
                color: var(--app-004986);
                cursor: pointer;
                font-size: 20px;
                margin-right: 5px;
            }

            .arrow-right {
                transform: rotate(0deg);
                transition: all 0.25s ease-in-out;
            }

            .arrow-down {
                transform: rotate(90deg);
                transition: all 0.25s ease;
            }
        }
    }

    .mt-10 {
        margin-top: 10px;
    }

}

.table-theme-1 {
    padding: 10px;
    background: white;
    margin-top: 5px;
    border-radius: 10px;

    .table-header {
        background: var(--app-505050);
        font-family: 'RedHat-regular' !important;
        padding: 10px;
        color: var(--app-FFFFFF);
        border-radius: 5px;
    }

    .table-body {
        background: #FCFCFC;
        font-family: 'RedHat-Medium' !important;
        padding: 8px;
        color: var(--app-black);
        border-radius: 5px;
        margin: 10px 0 0 0 !important;
        display: flex;
        align-items: center;
        box-shadow: 1px 1px 1px #DADADA;
        border: 1px solid #EBEBEB !important;

        .ant-form-item {
            margin-bottom: 0 !important;
        }

        .ant-form-item-control-input {
            width: 95% !important;
        }

        .gateinid {
            color: var(--app-004986);
        }

        .open-icon {
            display: flex;
            align-items: center;

            .arrow {
                color: var(--app-004986);
                cursor: pointer;
                font-size: 20px;
                margin-right: 5px;
            }

            .arrow-right {
                transform: rotate(0deg);
                transition: all 0.25s ease-in-out;
            }

            .arrow-down {
                transform: rotate(90deg);
                transition: all 0.25s ease;
            }
        }
    }

    .mt-10 {
        margin-top: 10px;
    }

}

.send-san-view-screen-container {
    padding: 10px;
    border-radius: 6px;
    background-color: var(--app-FFFFFF);

    .btn-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
    }

    .send-san-container {
        border: 1px solid var(--app-004986);
        background-color: var(--app-FFFFFF);

        .wms360-logo-container {
            background: linear-gradient(89.98deg, #004986 -1.05%, #3A73A2 99.98%);
            padding: 5px 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .template-view {
            padding: 15px;

            .content {
                .notes-sub-title {
                    color: var(--app-505050);
                    font-size: 18px;
                    margin-bottom: 10px;
                }

                .notes-sub1-title {
                    color: var(--app-646464);
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            }

            .info-text {
                color: var(--app-646464);
                font-size: 18px;
            }

            .ant-row {
                margin-bottom: 10px;
                padding: 15px;
                background: #F0F5F8;
                border-radius: 10px;

                .san-details {
                    display: flex;
                    align-items: center;

                    .label {
                        color: #5F5F5F;
                        font-family: 'RedHat-Bold';
                        font-size: 16px;
                    }

                    .value {
                        color: var(--app-black);
                        font-family: 'RedHat-Regular';
                        font-size: 14px;
                        margin-left: 5px;
                    }
                }
            }

            .ant-input {
                margin-top: 10px;
                background-color: #eaeaea7c;
                border-radius: 5px;
            }

            .ant-input:hover {
                border-color: #eaeaea7c !important;
            }

            .regard-container {
                .regard-txt {
                    color: #5F5F5F;
                    font-family: 'RedHat-SemiBold';
                    font-size: 18px;
                }

                .sign {
                    color: #5F5F5F;
                    font-family: 'RedHat-Regular';
                    font-size: 16px;
                }
            }
        }




    }
}

.unloading-screen-container {
    .titlebar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #0000001a;
        border-radius: 5px;
        margin: 15px;
        padding: 5px;

        .title {
            font-size: 18px;
            margin-left: 10px;
            color: var(--app-black) !important;
            font-family: 'RedHat-Bold' !important;
        }

        .user-details {
            display: flex;
            margin-right: 10px;
            align-items: center;

            .manager-type {
                font-size: 18px;
            }

            .manager-name {
                font-size: 18px;
            }
        }
    }

    .table-view {
        padding: 10px;

        .ant-table {
            background: none !important;
        }

        .ant-table table {
            border-spacing: 0 10px !important;
        }

        .ant-table-thead>tr>th {
            background: rgba(45, 45, 45, 0.15) !important;
            padding: 10px 10px !important;
            font-size: 14px !important;
            color: var(--app-black);
            font-family: 'RedHat-Bold' !important;
            border-top: 1px solid #EBEBEB !important;
            border-bottom: 1px solid #EBEBEB !important;
        }

        .ant-table-tbody>tr>td {
            background: rgba(0, 129, 157, 0.1) !important;
            padding: 5px 10px;
        }

        .ant-table-container table>thead>tr:first-child th:first-child {
            border-top-left-radius: 6px !important;

            border-bottom-left-radius: 6px !important;
        }

        .ant-table-container table>thead>tr:first-child th:last-child {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
        }


        .ant-table-tbody>tr>td:first-child {
            border-top-left-radius: 6px !important;

            border-bottom-left-radius: 6px !important;
        }

        .ant-table-tbody>tr>td:last-child {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;

        }


        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-SemiBold';
        }

        .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
            border-right: 0px !important;
            font-size: 14px;
            color: var(--app-black);
            font-family: 'RedHat-Medium';
        }

        .ant-table.ant-table-bordered>.ant-table-container {
            border-left: 0px !important;
        }

        .ant-table-tbody>tr.ant-table-row:hover>td,
        .ant-table-tbody>tr>td.ant-table-cell-row-hover {
            background: rgba(0, 129, 157, 0.1) !important;
        }

        .box-input {
            width: 300px;
            height: 40px;
            border-radius: 5px;
        }

        .assignee {
            display: flex;
            align-items: center;
            justify-content: left;
            background-color: #F4F4F4;
            border-radius: 5px;
            color: var(--app-004986);
            font-size: 14px;
            width: 350px;

            .user-img {
                height: 30px;
                width: 30px;

                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            }
        }

    }

    .send-btn {
        display: flex;
        justify-content: flex-end;
        padding: 15px 0px;

        .cancel {
            background: var(--app-004986);
            font-size: 18px;
            color: white;
            border: 1px solid var(--app-004986);
            border-radius: 5px;
            padding: 5px 20px;

            a {
                color: white;
            }
        }

        .sticker-btn {
            background: var(--app-004986);
            font-size: 18px;
            color: white;
            border: 1px solid var(--app-004986);
            border-radius: 5px;
            margin-left: 15px;
            padding: 10px 20px;

            a {
                color: white;
            }
        }

        .cancel:hover {
            background: rgba(0, 129, 157, 0.15);
            border: 1px solid var(--app-004986);
            border-radius: 5px;
            color: var(--app-004986);
        }

        .sticker-btn:hover {
            background: rgba(0, 129, 157, 0.15);
            border: 1px solid var(--app-004986);
            border-radius: 5px;
            color: var(--app-004986);
        }
    }

    .ant-pagination {
        display: none !important;
    }
}

.stickering-view-screen-container {
    background-color: var(--app-FFFFFF);
    padding: 10px 20px;

    .content-view-container {
        background: rgba(45, 45, 45, 0.15);
        border-radius: 5px;
        padding: 10px;
    }

    .ant-row {
        align-items: center;
    }

    .stickering-info-title {
        font-family: "RedHat-Bold";
        font-size: 14px;
    }

    .stickering-info {
        font-family: "RedHat-Medium";
        font-size: 14px;
    }

    hr {
        background-color: var(--app-004986);
        border: 1px solid var(--app-004986) !important;
    }

    .stickering-info-box1 {
        display: flex;
        justify-content: space-between;

        .img-container {
            display: flex;
            align-items: center;
            color: black;
            font-size: 14px;
            font-family: "RedHat-Bold";

            .user-Img {
                svg {
                    width: 40px;
                    height: 30px;
                    border-radius: 50%;
                }


            }
        }
    }

    .stickering-view-container {
        .blue-box {
            background-color: var(--app-004986);
            border-radius: 10px;
            height: 15px;
            margin-top: 10px;
        }

        .sticker-list-container {
            padding: 10px;
            background-color: var(--app-F5F5F5);
        }

        .sticker-box {
            border: 2px solid var(--app-C7C7C7);
            padding: 5px;
            margin: 10px;
            background-color: #ffffff;
            cursor: s;

            .stickering-code {
                font-size: 14px;
                font-family: "RedHat-Bold";
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .invoice-code {
                font-family: "RedHat-Bold";
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                margin-top: 10px;
            }

            .emiza-stickering-count {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;

                .emiza-logo {
                    img {
                        width: 60px !important;
                    }
                    svg{
                        width: 75px !important;
                    }
                }

                .count {
                    font-size: 25px;
                    font-family: "RedHat-Bold";
                }

            }

            .bar-code-img {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 100%;
                }
            }
        }

        .selected {
            position: relative;
            border: 3px solid #46C76A;
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
        }

        .select-icon {
            font-size: 24px;
            // height: 24px;
            background: white;
            color: #46C76A;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -7px;
            right: -7px;
            position: absolute;
        }

    }

    .btn-container {
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
    }

    .stickerOuterDiv {
        height: 50mm;
        width: 70mm;
        margin: auto;
        display: table;

    }


    .btnPrint {
        display: none;
    }

}

.mover-select {
    width: 100%;

    .ant-select-arrow {
        .ant-select-arrow {
            svg {
                height: 10px !important;
                width: 10px !important;
            }
        }

    }

    .ant-select-selector {
        border: 2px solid #d9d9d9 !important;
        height: 35px !important;
        border-radius: 5px !important;
        align-items: center;

        svg {
            height: 10px !important;
            width: 10px !important;
        }
    }

    .user-container {
        background-color: rgba(45, 45, 45, 0.15) !important;
        border-radius: 10px;


        .manager-name {
            color: var(--app-004986);
            font-size: 14px;
            font-family: "RedHat-SemiBold";
            margin-left: 14px;
        }
    }

    .user-img {
        img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }
    }
}

.select-field {
    width: 100%;

    .ant-select-selector {
        border: 2px solid #d9d9d9 !important;
        height: 40px !important;
        border-radius: 5px !important;
        align-items: center;

        svg {
            height: 10px !important;
            width: 10px !important;
        }
    }

    .user-container {
        background-color: rgba(45, 45, 45, 0.15) !important;
        ;
        border-radius: 10px;


        .manager-name {
            color: var(--app-004986);
            font-size: 14px;
            font-family: "RedHat-SemiBold";
            margin-left: 14px;
        }
    }

    .user-img {
        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }

}

.start-receiving-table {
    .ant-table-thead {
        background: rgba(0, 0, 0, 0.1) !important;
    }

    table>thead>tr:first-child th:first-child {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
    }

    table>thead>tr:first-child th:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
    }

    .ant-table-thead>tr>th {
        padding: 10px 10px !important;
        font-family: 'RedHat-SemiBold';
        border-top: 1px solid #EBEBEB !important;
        border-bottom: 1px solid #EBEBEB !important;

    }

    .ant-table-tbody {
        background: hsl(210, 8%, 97.5%) !important
    }

    .ant-table-tbody>tr>td {
        color: var(--app-black);
        font-family: 'RedHat-Regular';
        font-size: 14px;
        padding: 10px 16px !important;
    }

    .ant-table-tbody>tr>td:first-child {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
    }

    .ant-table-tbody>tr>td:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;

    }

    .ant-table table {
        border-spacing: 0 10px !important;
    }
}